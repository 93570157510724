//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    components:     PropTypes.shape({
        content:     PropTypes.node,
        headerRight: PropTypes.node,
        subtitle:    PropTypes.node,
        title:       PropTypes.node,
    }),
    isLoading:      PropTypes.bool,
    styleOverrides: PropTypes.shape({
        content:    PropTypes.string,
        header:     PropTypes.string,
        listItem:   PropTypes.string,
        titleGroup: PropTypes.string,
    }),
};

const ListItem = ({
    components = {},
    styleOverrides = {},
    isLoading = false,
}) => {
    const {
        title:          titleComponent,
        subtitle:       subtitleComponent,
        headerRight:    headerRightComponent,
        content:        contentView,
        contentButtons,
    }              = components;
    let contentContainer = null;

    if (contentView) {
        contentContainer = (
            <div
                className={classNames([
                    styles.content,
                    styleOverrides.content,
                ])}
            >
                {contentView}
                {contentButtons}
            </div>
        );
    }

    const renderHeader = () => {
        if (!isLoading) {
            return (
                <div
                    className={classNames([
                        styles.header,
                        styleOverrides.header,
                    ])}
                >
                    <div
                        className={classNames([
                            styles.titleGroup,
                            styleOverrides.titleGroup,
                        ])}
                    >
                        {titleComponent}
                        {subtitleComponent}
                    </div>
                    {headerRightComponent}
                </div>
            );
        }

        return (
            <div
                className={classNames([
                    styles.header,
                    styleOverrides.header,
                ])}
            >
                <div className={styles.loadingHeader} />
            </div>
        );
    };

    return (
        <div
            className={classNames([
                styles.listItem,
                styleOverrides.listItem,
            ])}
        >
            {renderHeader()}
            {contentContainer}
        </div>
    );
};

ListItem.propTypes = propTypes;

export default ListItem;
