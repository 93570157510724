//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import * as Sentry      from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Environment from '@helper/Environment';

function getTracesSampleRate() {
    if (Environment.isProduction()) {
        return 0.25;
    }

    return 1;
}

Sentry.init({
    dsn:          'https://06672657d1fe4294aa8607129ce1a0b5@o683776.ingest.sentry.io/4505526577725440',
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    environment:  Environment.getEnvironment(),
    release:      Environment.getVersion(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: getTracesSampleRate(),
});

const onBrokenMethodWhichThrowsAnErrorClicked = () => {
    const foo = 'foo';

    console.log('Sentry:', foo.this.does.not.exist);
};

export default function renderCrashButton() {
    return (
        <button
            onClick={onBrokenMethodWhichThrowsAnErrorClicked}
        >
            Crash
        </button>
    );
}
