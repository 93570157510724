//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import { Helmet }      from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory }  from 'react-router';

import Routes                from '@constants/Routes';
import Cast                  from '@helper/Cast';
import ImageWithFallback     from '@stateless/atomic/ImageWithFallback';
import ColorButton           from '@stateless/composed/ColorButton';
import ColorButtonColorTheme from '@stateless/composed/ColorButton/ColorButtonColorTheme';
import ColorButtonFontTheme  from '@stateless/composed/ColorButton/ColorButtonFontTheme';
import SideMenu              from '@stateless/composed/SideMenu';

import styles from './styles.module.scss';

const NotFound = () => {
    const history    = useHistory();
    const isLoggedIn = Cast.bool(useSelector((state) => state.user.token));

    function navigateToHomeClicked() {
        history.push(Routes.home);
    }

    function renderSideMenu() {
        if (isLoggedIn) {
            return (
                <SideMenu />
            );
        }

        return null;
    }

    return (
        <div className={styles.notFoundScreen}>
            {renderSideMenu()}
            <Helmet title={I18n.t('screens.notFound.pageTitle')} />
            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <h1>
                        {I18n.t('screens.notFound.firstHeadline')}
                    </h1>
                    <h2>
                        {I18n.t('screens.notFound.secondHeadline')}
                    </h2>
                    <div className={styles.buttonContainer}>
                        <ColorButton
                            text={I18n.t('screens.notFound.buttonText')}
                            colorTheme={ColorButtonColorTheme.green}
                            fontTheme={ColorButtonFontTheme.large}
                            fullWidth={true}
                            onClick={navigateToHomeClicked}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.firefighterBackground}>
                <ImageWithFallback
                    alt={I18n.t('screens.notFound.alternativeImageText')}
                />
            </div>
        </div>
    );
};

export default NotFound;
