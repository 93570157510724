//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _          from 'lodash';
import { put }    from 'redux-saga/effects';
import { call }   from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import * as Api                      from '@api/index';
import MissionDetailField            from '@constants/MissionDetailField';
import Overlay                       from '@constants/Overlay';
import Hydra                         from '@helper/Hydra';
import Notification                  from '@helper/Notification';
import { AlertBoxActions }           from '@slices/alertBox';
import { CreatePressArticleActions } from '@slices/createPressArticle';
import { OverlayActions }            from '@slices/overlay';
import { ParticularitiesActions }    from '@slices/particularities';
import IconType                      from '@stateless/atomic/Icon/IconType';
import { selectMissionDetail }       from '@store/selectors/createPressArticle';
import { selectPreviousOverlays }    from '@store/selectors/overlay';

function* deleteParticularity(action) {
    const particularity = _.get(action, 'payload');
    const iri           = _.get(particularity, 'id');
    const response      = yield call(Api.context.particularities.delete, iri);

    if (response.ok) {
        yield put(ParticularitiesActions.deleteParticularitySucceeded(particularity));
    } else {
        yield put(ParticularitiesActions.deleteParticularityFailed());
    }
}

function* deleteParticularityFailed() {
    Notification.error('deleteParticularityFailed');
}

function* deleteParticularitySucceeded() {
    Notification.success('deleteParticularitySucceeded');
    yield put(OverlayActions.closeOverlay());
}

function* fetchParticularities() {
    const response = yield call(Api.context.particularities.fetch);

    if (response.ok) {
        const particularities = Hydra.getMembersFromResponse(response.data);

        _.map(particularities, (particularity) => {
            particularity.type = 'particularity';
        });

        yield put(ParticularitiesActions.fetchParticularitiesSucceeded({
            particularities,
        }));
    } else {
        yield put(ParticularitiesActions.fetchParticularitiesFailed());
    }
}

function* updateParticularity(action) {
    const particularity = _.get(action, 'payload');
    const iri           = _.get(particularity, 'id');
    const isNew         = iri === undefined;
    const response      = (
        !isNew ?
            yield call(Api.context.particularities.update, iri, particularity) :
            yield call(Api.context.particularities.create, particularity)
    );

    if (response.ok) {
        yield put(ParticularitiesActions.updateParticularitySucceeded({
            particularity: response.data,
            isNew,
        }));
        yield put(OverlayActions.closeOverlay());
    } else {
        const errorKey =
                  _.get(response, 'data.violations[0].message', null) ||
                  _.get(response, 'data.error', null);

        yield put(ParticularitiesActions.updateParticularityFailed({
            particularity,
            isNew,
            errorKey,
        }));
    }
}

function* updateParticularityFailed(action) {
    const { payload: { isNew, errorKey } } = action;

    let errorMessageKey;

    if (Notification.translationExistsForKey(errorKey)) {
        errorMessageKey = errorKey;
    } else {
        errorMessageKey = (
            isNew
                ? 'createParticularityFailed'
                : 'updateParticularityFailed'
        );
    }

    Notification.error(errorMessageKey);
    yield put(AlertBoxActions.showErrorAlertTranslated({
        textKey:  `notifications.${errorMessageKey}`,
        iconLeft: IconType.danger,
    }));
}

function* updateParticularitySucceeded(action) {
    const { payload: { isNew, particularity } } = action;
    const errorMessageKey                       = (
        isNew
            ? 'createParticularitySucceeded'
            : 'updateParticularitySucceeded'
    );

    Notification.success(errorMessageKey);

    const previousOverlays = yield select(selectPreviousOverlays);
    const previousOverlay  = _.last(previousOverlays);

    if (previousOverlay === Overlay.createPressArticle) {
        yield put(CreatePressArticleActions.addParticularity({
            particularity: [particularity],
        }));

        const missionDetail = yield select(selectMissionDetail);

        yield put(CreatePressArticleActions.setMissionDetail({
            missionDetail: {
                ...missionDetail,
                [MissionDetailField.particularities]: '',
            },
        }));
    }
}

export default {
    deleteParticularity,
    deleteParticularityFailed,
    deleteParticularitySucceeded,
    fetchParticularities,
    updateParticularity,
    updateParticularityFailed,
    updateParticularitySucceeded,
};
