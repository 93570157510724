//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';
import _          from 'lodash';

import PropTypes     from '@components/PropTypes';
import Api           from '@constants/Api';
import PaymentMethod from '@constants/PaymentMethod';
import Environment   from '@helper/Environment';
import Translator    from '@helper/Translator';
import PlanFeature   from '@stateless/atomic/PlanFeature';

import styles from './styles.module.scss';

const propTypes = {
    onPlanTerminationClicked: PropTypes.func,
    paymentMethod:            PropTypes.oneOfObjectValues(PaymentMethod),
    planItem:                 PropTypes.object,
    userToken:                PropTypes.string,
};

const PlanDisplayItem = ({
    onPlanTerminationClicked = _.noop,
    planItem = null,
    paymentMethod = PaymentMethod.MONTHLY,
    userToken = null,
}) => {
    const translator = new Translator('components.planDisplayItem');
    const labelText  = _.get(planItem, 'label');

    function renderPriceLabel() {
        if (
            planItem.yearlyMethodPrice === 0 &&
            planItem.monthlyMethodPrice === 0
        ) {
            return (
                <div className={styles.price}>
                    {translator.t('freePrice')}
                </div>
            );
        }

        const price = paymentMethod === PaymentMethod.YEARLY
            ? planItem.yearlyMethodPrice
            : planItem.monthlyMethodPrice;

        return (
            <div className={styles.price}>
                {translator.t('planPriceText', {
                    price,
                })}
                <span>
                    {translator.t('perMonthPriceSuffix')}
                </span>
            </div>
        );
    }

    function renderWordsOrPressArticles() {
        let text;

        if (planItem.pressArticlePerMonth > 0) {
            text = translator.t('numberOfPressArticlesPerMonth', {
                number: planItem.pressArticlePerMonth,
            });
        } else {
            text = translator.t('numberOfWordsPerMonth', {
                number: planItem.wordsPerMonth,
            });
        }

        return (
            <div className={styles.numberOfWords}>
                {text}
            </div>
        );
    }

    function getFeatureSubText(numberOfUnits) {
        if (numberOfUnits === 'infinite') {
            return translator.t('infiniteEntries');
        }

        if (numberOfUnits > 0) {
            return translator.t('numberOfEntries', {
                number: numberOfUnits,
            });
        }
        return null;
    }

    function renderPlanFeatures() {
        return (
            <div className={styles.planFeatures}>
                <PlanFeature
                    active={planItem.externalUnits > 0 || planItem.externalUnits === 'infinite'}
                    text={translator.t('listExternalUnits')}
                    subText={getFeatureSubText(planItem.externalUnits)}
                />
                <PlanFeature
                    active={planItem.units > 0 || planItem.units === 'infinite'}
                    text={translator.t('listUnits')}
                    subText={getFeatureSubText(planItem.units)}
                />
                <PlanFeature
                    active={planItem.vehicles > 0 || planItem.vehicles === 'infinite'}
                    text={translator.t('listVehicles')}
                    subText={getFeatureSubText(planItem.vehicles)}
                />
                <PlanFeature
                    active={planItem.adFree}
                    text={translator.t('adFree')}
                />
                <PlanFeature
                    active={planItem.support}
                    text={translator.t('support')}
                />
            </div>
        );
    }

    function onPlanClicked() {
        if (planItem.termination === true) {
            onPlanTerminationClicked();

            return;
        }

        const planName = planItem.name;
        const planUrl  = paymentMethod === PaymentMethod.MONTHLY
            ? translator.t(`${planName}UrlMonthly`)
            : translator.t(`${planName}UrlYearly`);
        let url;

        if (Environment.isProduction()) {
            const backendUrl = Api.getBackendUrl();
            url              = `${backendUrl}/connector?token=${userToken}&redirect=${planUrl}`;
        } else {
            url = `${I18n.t('wordpressUrl')}${planUrl}`;
        }

        window.open(url, '_blank', 'noopener,noreferrer');
    }

    function renderLabel() {
        if (!labelText) {
            return null;
        }

        return (
            <div className={styles.planItemLabel}>
                <span className={styles.planItemLabelContent}>
                    {labelText}
                </span>
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.planDisplayItem, {
                [styles.highlight]: labelText,
            })}
        >
            {renderLabel()}
            <div className={styles.planItemContent}>
                <div className={styles.planName}>
                    {translator.t(planItem.name)}
                </div>
                {renderPriceLabel()}
                <div className={styles.planDescription}>
                    {translator.t(`${planItem.name}Text`)}
                </div>
                <div className={styles.separator} />
                {renderWordsOrPressArticles()}
                {renderPlanFeatures()}
            </div>
            <button
                onClick={onPlanClicked}
                className={styles.planItemButton}
            >
                {translator.t('selectNow')}
            </button>
        </div>
    );
};

PlanDisplayItem.propTypes = propTypes;

export default PlanDisplayItem;
