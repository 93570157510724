//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';

import styles from './styles.module.scss';

const propTypes = {
    children:   PropTypes.children,
    setVisible: PropTypes.func,
    title:      PropTypes.string,
    visible:    PropTypes.bool,
};

const Collapsable = ({
    children = null,
    title = '',
    visible = false,
    setVisible = DefaultTypes.func,
}) => {
    function onTitleClicked() {
        setVisible(!visible);
    }

    return (
        <div className={styles.collapsable}>
            <div
                className={styles.collapsableTitle}
                onClick={onTitleClicked}
            >
                <Headline
                    title={title}
                    type={HeadlineType.headline2}
                />
            </div>
            <div
                className={classNames(
                    styles.collapsableContentContainer,
                    {
                        [styles.collapsableContentVisible]: visible,
                    },
                )}
            >
                <div className={styles.collapsableContent}>
                    {children}
                </div>
            </div>
        </div>
    );
};

Collapsable.propTypes = propTypes;

export default Collapsable;
