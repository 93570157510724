//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useDispatch } from 'react-redux';

import PropTypes       from '@components/PropTypes';
import Translator      from '@helper/Translator';
import { UserActions } from '@slices/user';
import ConfirmOverlay  from '@stateless/composed/ConfirmOverlay';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const ConfirmPaidPlanTerminationOverlay = ({
    open = false,
    onCloseClick = _.noop,
}) => {
    const dispatch   = useDispatch();
    const translator = new Translator('components.terminatePaidPlanOverlay');

    function onConfirmTerminatePlanClicked() {
        dispatch(UserActions.terminatePaidPlanConfirmed());
    }

    return (
        <ConfirmOverlay
            open={open}
            onDenyClick={onCloseClick}
            onCloseClick={onCloseClick}
            onConfirmClick={onConfirmTerminatePlanClicked}
            buttonConfirmText={translator.t('confirmButtonText')}
            buttonDenyText={translator.t('denyButtonText')}
            confirmText={translator.t('confirmText')}
        />
    );
};

ConfirmPaidPlanTerminationOverlay.propTypes = propTypes;

export default ConfirmPaidPlanTerminationOverlay;
