//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';

import PropTypes                   from '@components/PropTypes';
import { EditPressArticleActions } from '@slices/editPressArticle';
import StatelessConfirmOverlay     from '@stateless/composed/ConfirmOverlay';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const DeleteDraftOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch            = useDispatch();
    const baseTranslationPath = 'components.deleteDraftOverlay.';

    function onConfirmClick() {
        dispatch(EditPressArticleActions.delete());
    }

    function onDenyClick() {
        dispatch(EditPressArticleActions.editPressArticle({
            pressArticle: null,
        }));
        onCloseClick();
    }

    return (
        <StatelessConfirmOverlay
            onCloseClick={onCloseClick}
            open={open}
            confirmText={I18n.t(`${baseTranslationPath}deleteDraftText`)}
            buttonConfirmText={I18n.t(`${baseTranslationPath}deleteDraftConfirmButtonText`)}
            buttonDenyText={I18n.t(`${baseTranslationPath}deleteDraftDenyButtonText`)}
            onConfirmClick={onConfirmClick}
            onDenyClick={onDenyClick}
        />
    );
};

DeleteDraftOverlay.propTypes = propTypes;

export default DeleteDraftOverlay;
