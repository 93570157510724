//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';
import ButtonColor  from '@stateless/atomic/Button/ButtonColor';
import ButtonType   from '@stateless/atomic/Button/ButtonType';
import Icon         from '@stateless/atomic/Icon';

import styles from './styles.module.scss';

const propTypes = {
    color:     PropTypes.buttonColor,
    disabled:  PropTypes.bool,
    iconLeft:  PropTypes.iconType,
    iconRight: PropTypes.iconType,
    onClick:   PropTypes.func,
    text:      PropTypes.string.isRequired,
    type:      PropTypes.buttonType,
};

const Button = ({
    color = ButtonColor.default,
    disabled = false,
    iconLeft = null,
    iconRight = null,
    onClick = DefaultTypes.noop,
    text = null,
    type = ButtonType.default,
}) => {
    function renderIconLeft() {
        if (iconLeft) {
            return (
                <div
                    className={classNames(
                        styles.iconContainer,
                        styles.iconContainerLeft,
                    )}
                >
                    <Icon iconType={iconLeft} />
                </div>
            );
        }

        return null;
    }

    function renderIconRight() {
        if (iconRight) {
            return (
                <div
                    className={classNames(
                        styles.iconContainer,
                        styles.iconContainerRight,
                    )}
                >
                    <Icon iconType={iconRight} />
                </div>
            );
        }

        return null;
    }

    return (
        <button
            className={classNames(
                styles.button,
                {
                    [styles.iconOnly]:    !text,
                    [styles.outline]:     type === ButtonType.outline,
                    [styles.outlineIcon]: type === ButtonType.outlineIcon,
                    [styles.link]:        type === ButtonType.link,
                    [styles.white]:       color === ButtonColor.white,
                    [styles.grey]:        color === ButtonColor.grey,
                },
            )}
            onClick={onClick}
            disabled={disabled}
        >
            {renderIconLeft()}
            <p>
                {text}
            </p>
            {renderIconRight()}
        </button>
    );
};

Button.propTypes = propTypes;

export default Button;
