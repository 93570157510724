//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import preval from 'preval.macro';

import packageJson from '../../package.json';

class Environment {
    static buildTimestamp = preval`module.exports = new Date().getTime();`;

    static getEnvironment = () => {
        return process.env.NODE_ENV;
    };

    static isDevelopment = () => {
        const environment = Environment.getEnvironment();

        return environment === 'development';
    };

    static isProduction = () => {
        const environment = Environment.getEnvironment();

        return environment === 'production';
    };

    static isStaging = () => {
        const { href } = window.location;

        return (
            href.indexOf('.test') > -1 ||
            href.indexOf('staging') > -1 ||
            href.indexOf('localhost') > -1
        );
    };

    static isTest = () => {
        const environment = Environment.getEnvironment();

        return (
            environment.indexOf('jsdom') > -1 ||
            environment === 'test'
        );
    };

    static getVersion = () => {
        return `${packageJson.version}.${Environment.buildTimestamp}`;
    };
}

export default Environment;
