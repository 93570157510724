//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import AlertBoxManager           from '@connected/AlertBoxManager';
import RouteHelper               from '@helper/Route';
import { useAlertBox }           from '@slices/alertBox';
import { useUser }               from '@slices/user';
import Logo                      from '@stateless/atomic/Logo';
import SimpleButton              from '@stateless/atomic/SimpleButton';
import TextInputType             from '@stateless/atomic/TextInput/TextInputType';
import LandingpageFooter         from '@stateless/composed/LandingpageFooter';
import { selectPassword }        from '@store/selectors/user';
import { selectConfirmPassword } from '@store/selectors/user';

import styles from './styles.module.scss';

const ActivateTestAccount = () => {
    const dispatch              = useDispatch();
    const userActions           = useUser(dispatch);
    const alertBoxActions       = useAlertBox(dispatch);
    const password              = useSelector(selectPassword);
    const confirmPassword       = useSelector(selectConfirmPassword);
    const welcomeTextParagraphs = I18n.t('activateTestAccountText', {
        returnObjects: true,
    });

    function onFormSubmit(event) {
        event.preventDefault();
        console.log('ActivateTestAccountScreen: activateTestAccountFormSubmitted');
        alertBoxActions.clearAlerts();
        userActions.activateTestAccount();
    }

    function passwordChanged(event) {
        userActions.setPassword({
            password: event.target.value,
        });
    }

    function confirmPasswordChanged(event) {
        userActions.setConfirmPassword({
            confirmPassword: event.target.value,
        });
    }

    function renderGreeting() {
        const { username } = RouteHelper.getQueryParametersFromCurrentRoute('username');

        return (
            <p className={styles.activateTestAccountScreenFormGreeting}>
                {`${I18n.t('hello')} ${username},`}
            </p>
        );
    }

    return (
        <div className={styles.activateTestAccountScreen}>
            <div className={styles.activateTestAccountScreenContent}>
                <div className={styles.activateTestAccountScreenForm}>
                    <Logo showFullLogo={true} />
                    <div>
                        {renderGreeting()}
                        <p className={styles.activateTestAccountScreenFormText}>
                            {
                                Object.keys(welcomeTextParagraphs).map((key) => {
                                    return (
                                        <p key={key}>
                                            {welcomeTextParagraphs[key]}
                                        </p>
                                    );
                                })
                            }
                        </p>
                    </div>
                    <div className={styles.alertBoxWrapper}>
                        <AlertBoxManager />
                    </div>
                    <form onSubmit={onFormSubmit}>
                        <label>
                            <span>
                                {I18n.t('password')}
                            </span>
                            <input
                                type={TextInputType.password}
                                onChange={passwordChanged}
                                value={password}
                                placeholder={I18n.t('password')}
                            />
                        </label>
                        <label>
                            <span>
                                {I18n.t('confirmPassword')}
                            </span>
                            <input
                                type={TextInputType.password}
                                onChange={confirmPasswordChanged}
                                value={confirmPassword}
                                placeholder={I18n.t('passwordPlaceholder')}
                            />
                        </label>
                        <SimpleButton text={I18n.t('activateTestAccount')} />
                    </form>
                    <LandingpageFooter />
                </div>
            </div>
            <div className={styles.activateTestAccountScreenStyle} />
        </div>
    );
};

export default ActivateTestAccount;
