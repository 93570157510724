//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { PropTypes as BasePropTypes } from 'prop-types';

import AssistantType      from '@constants/AssistantType';
import { TestIdPrefixes } from '@constants/TestIds';
import { TestIds }        from '@constants/TestIds';
import ButtonColor        from '@stateless/atomic/Button/ButtonColor';
import ButtonType         from '@stateless/atomic/Button/ButtonType';
import HeadlineColor      from '@stateless/atomic/Headline/HeadlineColor';
import HeadlineType       from '@stateless/atomic/Headline/HeadlineType';
import IconType           from '@stateless/atomic/Icon/IconType';
import SliderTheme        from '@stateless/atomic/SliderWrapper/SliderTheme';
import TextAlignment      from '@stateless/atomic/Text/TextAlignment';
import TextColor          from '@stateless/atomic/Text/TextColor';
import TextSize           from '@stateless/atomic/Text/TextSize';

const createEntityShape = (entityName, propTypes) => {
    return BasePropTypes.shape({
        ...propTypes,
    });
};

class PropTypes {
    static cssUnits = [
        BasePropTypes.number,
        BasePropTypes.string,
    ];

    static children = BasePropTypes.oneOfType([
        BasePropTypes.arrayOf(BasePropTypes.node),
        BasePropTypes.node,
    ]);

    static cssHeight = BasePropTypes.oneOfType(PropTypes.cssUnits);

    static cssWidth = BasePropTypes.oneOfType(PropTypes.cssUnits);

    static date = BasePropTypes.oneOfType([
        BasePropTypes.string,
        BasePropTypes.instanceOf(Date),
        BasePropTypes.number,
    ]);

    static time = BasePropTypes.shape({
        hours:   BasePropTypes.number,
        minutes: BasePropTypes.number,
    });

    static image = BasePropTypes.oneOfType([
        BasePropTypes.string,
        BasePropTypes.object,
    ]);

    static numberOrString = BasePropTypes.oneOfType([
        BasePropTypes.string,
        BasePropTypes.number,
    ]);

    static oneOfObjectKeys = (object) => {
        return BasePropTypes.oneOf(Object.keys(object));
    };

    static oneOfObjectValues = (object) => {
        return BasePropTypes.oneOf(Object.values(object));
    };

    static oneOfTestIds = this.oneOfObjectValues(TestIds);

    static oneOfTestIdPrefix = this.oneOfObjectValues(TestIdPrefixes);

    static buttonType = this.oneOfObjectValues(ButtonType);

    static buttonColor = this.oneOfObjectValues(ButtonColor);

    static headlineType = this.oneOfObjectValues(HeadlineType);

    static headlineColor = this.oneOfObjectValues(HeadlineColor);

    static sliderTheme = this.oneOfObjectValues(SliderTheme);

    static assistantType = this.oneOfObjectValues(AssistantType);

    static textAlignment = this.oneOfObjectValues(TextAlignment);

    static textColor = this.oneOfObjectValues(TextColor);

    static textSize = this.oneOfObjectValues(TextSize);

    static orNull = (type) => {
        return BasePropTypes.oneOfType([
            type,
            BasePropTypes.null,
        ]);
    };

    static multipleChoiceValue = BasePropTypes.oneOfType([
        BasePropTypes.shape({
            name:     BasePropTypes.string,
            selected: BasePropTypes.bool,
        }),
        BasePropTypes.shape({
            name: BasePropTypes.string,
        }),
    ]);

    static iconType = this.oneOfObjectKeys(IconType);

    static vehicle = createEntityShape(
        'Vehicle',
        {
            id:           BasePropTypes.string,
            description:  BasePropTypes.string,
            name:         BasePropTypes.string,
            organisation: BasePropTypes.string,
        },
    );
}

export default Object.assign(PropTypes, BasePropTypes);
