//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    disabled: PropTypes.bool,
    icon:     PropTypes.any,
    onClick:  PropTypes.func,
    text:     PropTypes.string,
};

const SimpleButton = ({
    disabled = false,
    icon = null,
    onClick = DefaultTypes.func,
    text = '',
}) => {
    function renderIcon() {
        if (icon) {
            return (
                <FontAwesomeIcon icon={icon} />
            );
        }

        return null;
    }

    return (
        <button
            className={styles.simpleButton}
            onClick={onClick}
            disabled={disabled}
        >
            {renderIcon()}
            {text}
        </button>
    );
};

SimpleButton.propTypes = propTypes;

export default SimpleButton;
