//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

class ErrorCodeTranslator {
    static getTranslatedError(errorCode, fallbackKey = 'default') {
        const translationKey = `errorCodes.${errorCode}`;

        if (I18n.exists(translationKey)) {
            return I18n.t(translationKey);
        }

        return I18n.t(`errorCodes.${fallbackKey}`);
    }
}

export default ErrorCodeTranslator;
