//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import I18n            from 'i18next';
import update          from 'immutability-helper';
import _               from 'lodash';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    particularities:     [],
    particularityInEdit: null,
});

const particularitiesSlice = createSlice({
    name:     'particularities',
    initialState,
    reducers: {
        resetParticularities:          () => {
            return initialState;
        },
        fetchParticularities:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loadingParticularities')),
        fetchParticularitiesFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchParticularitiesSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                particularities: {
                    $push: _.get(action, 'payload.particularities'),
                },
            });
        }),
        setParticularityInEdit:        (state, action) => {
            const particularity = _.get(action, 'payload');

            return update(state, {
                particularityInEdit: {
                    $set: particularity,
                },
            });
        },
        updateParticularity:           LoadingLevelHelper.increaseLoadingEmptyReducer((state) => {
            const iri = _.get(state, 'particularities.particularityInEdit.id', null);

            return (
                iri ?
                    I18n.t('updatingParticularity') :
                    I18n.t('creatingParticularity')
            );
        }),
        updateParticularityFailed:     LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        updateParticularitySucceeded:  LoadingLevelHelper.decreaseLoading((state, action) => {
            const newParticularity = _.get(action, 'payload.particularity');
            const exisingIndex     = _.findIndex(state.particularities, {
                id: newParticularity.id,
            });

            if (exisingIndex === -1) {
                return update(state, {
                    particularities: {
                        $push: [newParticularity],
                    },
                });
            }

            return update(state, {
                particularities: {
                    [exisingIndex]: {
                        $set: newParticularity,
                    },
                },
            });
        }),
        deleteParticularity:           LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('deletingParticularity')),
        deleteParticularityFailed:     LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        deleteParticularitySucceeded:  LoadingLevelHelper.decreaseLoading((state, action) => {
            const particularity      = _.get(action, 'payload');
            const id                 = _.get(particularity, 'id');
            const newParticularities = state.particularities.filter((oldParticularity) => oldParticularity.id !== id);

            return update(state, {
                particularities: {
                    $set: newParticularities,
                },
            });
        }),
    },
});

export const ParticularitiesActions = particularitiesSlice.actions;

export const ParticularitiesReducer = particularitiesSlice.reducer;

export default particularitiesSlice;
