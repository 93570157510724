//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import DefaultTypes         from '@components/DefaultTypes';
import PropTypes            from '@components/PropTypes';
import StepIndicatorToolTip from '@stateless/atomic/StepIndicatorToolTip';

import styles from './styles.module.scss';

const propTypes = {
    currentStep: PropTypes.number,
    onClick:     PropTypes.func,
    steps:       PropTypes.number,
};

const StepIndicator = ({
    currentStep = 1,
    onClick = DefaultTypes.func,
    steps = 1,
}) => {
    const percentage            = (
        currentStep / steps
    ) * 100;
    const width                 = {
        width: `${percentage}%`,
    };
    const [maxStep, setMaxStep] = React.useState(1);

    useEffect(() => {
        if (currentStep > maxStep) {
            setMaxStep(currentStep);
        }
    }, [currentStep]);

    function renderDot(dot, index) {
        return (
            <button
                className={classNames(
                    styles.stepIndicatorDot,
                    [
                        {
                            [styles.stepIndicatorDotActive]:  currentStep >= index,
                            [styles.stepIndicatorDotVisited]: maxStep >= index,
                        },
                    ],
                )}
                key={_.uniqueId('step-indicator-dot-')}
                disabled={maxStep < index}
                onClick={onClick(index)}
            >
            </button>
        );
    }

    return (
        <div className={styles.stepIndicator}>
            <div className={styles.stepIndicatorFullBar}>
                <div
                    className={styles.stepIndicatorFullBarActive}
                    style={width}
                >
                    <div
                        className={styles.tooltipWrapper}
                        key={_.uniqueId('step-indicator-tooltip-')}
                    >
                        <StepIndicatorToolTip
                            currentStep={currentStep}
                            steps={steps}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.stepIndicatorDotContainer}>
                {_.map(Array(steps + 1), renderDot)}
            </div>
        </div>
    );
};

StepIndicator.propTypes = propTypes;

export default StepIndicator;
