//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                 from '@components/PropTypes';
import OverlayConstants          from '@constants/Overlay';
import { ExternalUnitsActions }  from '@slices/externalUnits';
import { OverlayActions }        from '@slices/overlay';
import StateLessEditUnitsOverlay from '@stateless/composed/EditUnitsOverlay';
import VehicleListItem           from '@stateless/composed/VehicleListItem';
import selectExternalUnits       from '@store/selectors/externalUnits';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const EditExternalUnitsOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch      = useDispatch();
    const units         = useSelector(selectExternalUnits);
    const numberOfUnits = units.length;
    const showHint      = units.length === 0;

    function setUnitInEdit(unit) {
        dispatch(ExternalUnitsActions.setExternalUnitInEdit(unit));
    }

    function onAddExternalUnitClicked() {
        dispatch(OverlayActions.openOverlay({
            overlay: OverlayConstants.editExternalUnit,
        }));
    }

    function onEditUnitClicked(unit) {
        return () => {
            dispatch(ExternalUnitsActions.setExternalUnitInEdit(unit));
            onAddExternalUnitClicked();
        };
    }

    function onDeleteExternalUnitClicked(id) {
        return () => {
            dispatch(ExternalUnitsActions.setExternalUnitInEdit({
                id,
            }));
            dispatch(OverlayActions.openOverlay({
                overlay: OverlayConstants.deleteExternalUnit,
            }));
        };
    }

    function renderUnits() {
        return units.map((unit) => {
            const { id, name, description } = unit;

            return (
                <VehicleListItem
                    key={`own-unit-${id}`}
                    title={name}
                    onDeleteClick={onDeleteExternalUnitClicked(id)}
                    onEditClick={onEditUnitClicked({
                        id,
                        name,
                        description,
                    })}
                />
            );
        });
    }

    return (
        <StateLessEditUnitsOverlay
            onCloseClick={onCloseClick}
            open={open}
            renderUnits={renderUnits}
            numberOfUnits={numberOfUnits}
            onAddUnitClicked={onAddExternalUnitClicked}
            setUnitInEdit={setUnitInEdit}
            buttonText={I18n.t('components.editExternalUnitsOverlay.addExternalUnitTitle')}
            showHint={showHint}
            hintText={I18n.t('components.editExternalUnitsOverlay.addExternalUnitHint')}
        />
    );
};

EditExternalUnitsOverlay.propTypes = propTypes;

export default EditExternalUnitsOverlay;
