//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from 'prop-types';

import Overlay      from '@stateless/atomic/Overlay';
import SimpleButton from '@stateless/atomic/SimpleButton';

import styles from './styles.module.scss';

const propTypes = {
    buttonConfirmText: PropTypes.string,
    buttonDenyText:    PropTypes.string,
    confirmText:       PropTypes.string,
    onCloseClick:      PropTypes.func,
    onConfirmClick:    PropTypes.func,
    onDenyClick:       PropTypes.func,
    open:              PropTypes.bool,
};

const ConfirmOverlay = ({
    onCloseClick,
    open,
    confirmText,
    buttonConfirmText,
    buttonDenyText,
    onConfirmClick,
    onDenyClick,
}) => {
    return (
        <Overlay
            onCloseClick={onCloseClick}
            open={open}
            small={true}
            mobileTransparent={true}
        >
            <div className={styles.confirmOverlayContent}>
                <p>
                    {confirmText}
                </p>
                <div className={styles.confirmOverlayButtonWrapper}>
                    <SimpleButton
                        text={buttonDenyText}
                        onClick={onDenyClick}
                        className={styles.confirmOverlayDenyButton}
                    />
                    <SimpleButton
                        text={buttonConfirmText}
                        onClick={onConfirmClick}
                        className={styles.confirmOverlayConfirmButton}
                    />
                </div>
            </div>
        </Overlay>
    );
};

ConfirmOverlay.propTypes = propTypes;

export default ConfirmOverlay;
