//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiUrls from '@constants/ApiUrls';

export default (api) => {
    return {
        fetch:  () => {
            return api.get(ApiUrls.API_PARTICULARITIES_URL);
        },
        create: (particularity) => {
            return api.post(
                ApiUrls.API_PARTICULARITIES_URL,
                particularity,
            );
        },
        update: (iri, particularity) => {
            return api.put(
                iri,
                particularity,
            );
        },
        delete: (iri) => {
            return api.delete(iri);
        },
    };
};
