//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useRef }    from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import _ from 'lodash';

const withScrolled = (WrappedComponent, getScrollOffset = () => 0) => {
    const Component = (props) => {
        const referenceScrollElement                  = useRef();
        const [initialTopOffset, setInitialTopOffset] = useState(0);
        const [scrolled, setScrolled]                 = useState(false);

        function getElementTopOffset() {
            const element = _.get(referenceScrollElement, 'current');

            if (!element) {
                return null;
            }

            const topOffset = _.get(element.getBoundingClientRect(), 'top');

            return topOffset;
        }

        function updateScrollState(updatedScrolled) {
            if (scrolled !== updatedScrolled) {
                setScrolled(updatedScrolled);
            }
        }

        function handleScroll() {
            const { scrollY }       = window;
            const scrollYWithOffset = scrollY + getScrollOffset();

            if (scrollYWithOffset <= initialTopOffset) {
                updateScrollState(false);

                return;
            }

            updateScrollState(true);
        }

        function handleResize() {
            const scrollTop        = document.documentElement.scrollTop;
            const elementTopOffset = getElementTopOffset();

            if (!elementTopOffset) {
                return;
            }

            const newInitialTopOffset = elementTopOffset + scrollTop;

            setInitialTopOffset(newInitialTopOffset);
        }

        useEffect(() => {
            handleResize();
            window.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('scroll', handleScroll);
                window.removeEventListener('resize', handleResize);
            };
        }, [scrolled]);

        return (
            <WrappedComponent
                {...props}
                scrolled={scrolled}
                referenceScrollElement={referenceScrollElement}
            />
        );
    };

    return Component;
};

export default withScrolled;
