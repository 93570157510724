//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames      from 'classnames';
import ReactHtmlParser from 'react-html-parser';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    className: PropTypes.string,
    text:      PropTypes.string,
};

const PrimaryTitle = ({
    className = '',
    text = '',
}) => {
    return (
        <div className={classNames([styles.primaryTitle, className])}>
            {ReactHtmlParser(text)}
        </div>
    );
};

PrimaryTitle.propTypes = propTypes;

export default PrimaryTitle;
