//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes               from '@components/PropTypes';
import { UnitsActions }        from '@slices/units';
import StatelessConfirmOverlay from '@stateless/composed/ConfirmOverlay';
import { selectUnitInEdit }    from '@store/selectors/units';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const DeleteOwnUnitOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch            = useDispatch();
    const unitInEdit          = useSelector(selectUnitInEdit);
    const baseTranslationPath = 'components.editOwnUnitOverlay.';

    function onConfirmClick() {
        dispatch(UnitsActions.deleteUnit(unitInEdit));
    }

    function onDenyClick() {
        dispatch(UnitsActions.setUnitInEdit(null));
        onCloseClick();
    }

    return (
        <StatelessConfirmOverlay
            onCloseClick={onCloseClick}
            open={open}
            confirmText={I18n.t(`${baseTranslationPath}deleteOwnUnitText`)}
            buttonConfirmText={I18n.t(`${baseTranslationPath}deleteOwnUnitConfirmButtonText`)}
            buttonDenyText={I18n.t(`${baseTranslationPath}deleteOwnUnitDenyButtonText`)}
            onConfirmClick={onConfirmClick}
            onDenyClick={onDenyClick}
        />
    );
};

DeleteOwnUnitOverlay.propTypes = propTypes;

export default DeleteOwnUnitOverlay;
