//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _          from 'lodash';
import { select } from 'redux-saga/effects';

function* selectFromState(...path) {
    return yield select((state) => _.get(state, path));
}

function* selectFromStateBySelector(selector) {
    return yield select(selector);
}

export default {
    selectFromState,
    selectFromStateBySelector,
};
