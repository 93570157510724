//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React        from 'react';
import { useState } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                from '@components/PropTypes';
import { AlertBoxActions }      from '@slices/alertBox';
import { VehiclesActions }      from '@slices/vehicles';
import StatelessEditUnitOverlay from '@stateless/composed/EditUnitOverlay';
import { selectVehicleInEdit }  from '@store/selectors/vehicles';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const EditVehicleOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch                = useDispatch();
    const vehicleInEdit           = useSelector(selectVehicleInEdit);
    const [title, setTitle]       = useState(_.get(vehicleInEdit, 'name'));
    const [subtitle, setSubtitle] = useState(_.get(vehicleInEdit, 'description'));

    function onSaveClick() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(VehiclesActions.updateVehicle({
            ...vehicleInEdit,
            name:        title,
            description: subtitle,
        }));
    }

    function getOverlayTitle() {
        return vehicleInEdit ?
            I18n.t('components.editVehiclesOverlay.editVehicleTitle') :
            I18n.t('components.editVehiclesOverlay.addVehicleTitle');
    }

    function getOverlayButtonText() {
        return vehicleInEdit ?
            I18n.t('components.editVehiclesOverlay.editVehicleButton') :
            I18n.t('components.editVehiclesOverlay.addVehicleButton');
    }

    return (
        <StatelessEditUnitOverlay
            onCloseClick={onCloseClick}
            open={open}
            title={title}
            subtitle={subtitle}
            setTitle={setTitle}
            titlePlaceholder={I18n.t('components.editVehiclesOverlay.titlePlaceholder')}
            subtitlePlaceholder={I18n.t('components.editVehiclesOverlay.subtitlePlaceholder')}
            unitInEdit={vehicleInEdit}
            setSubtitle={setSubtitle}
            noSubtitle={false}
            onSaveClick={onSaveClick}
            overlayButtonText={getOverlayButtonText()}
            overlayTitle={getOverlayTitle()}
        />
    );
};

EditVehicleOverlay.propTypes = propTypes;

export default EditVehicleOverlay;
