//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import I18n            from 'i18next';
import { Trans }       from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import AlertBoxManager           from '@connected/AlertBoxManager';
import { useAlertBox }           from '@slices/alertBox';
import { useUser }               from '@slices/user';
import CheckBox                  from '@stateless/atomic/CheckBox';
import Logo                      from '@stateless/atomic/Logo';
import SimpleButton              from '@stateless/atomic/SimpleButton';
import TextInputType             from '@stateless/atomic/TextInput/TextInputType';
import LandingpageFooter         from '@stateless/composed/LandingpageFooter';
import { selectEmail }           from '@store/selectors/user';
import { selectPassword }        from '@store/selectors/user';
import { selectConfirmPassword } from '@store/selectors/user';
import { selectFirstname }       from '@store/selectors/user';
import { selectLastname }        from '@store/selectors/user';

import styles from './styles.module.scss';

const RegisterTestAccount = () => {
    const dispatch                = useDispatch();
    const userActions             = useUser(dispatch);
    const alertBoxActions         = useAlertBox(dispatch);
    const baseTranslationPath     = 'screens.registerTestAccount.';
    const email                   = useSelector(selectEmail);
    const password                = useSelector(selectPassword);
    const confirmPassword         = useSelector(selectConfirmPassword);
    const firstname               = useSelector(selectFirstname);
    const lastname                = useSelector(selectLastname);
    const [accepted, setAccepted] = useState(false);
    const isSubmitButtonDisabled  = !firstname || !lastname || !email || !password || !confirmPassword || !accepted;

    function onFormSubmit(event) {
        event.preventDefault();
        alertBoxActions.clearAlerts();
        userActions.registerTestAccount({
            firstname,
            lastname,
        });
    }

    function onAcceptedClicked() {
        setAccepted((currentAccepted) => {
            return !currentAccepted;
        });
    }

    function onFirstnameChanged(event) {
        userActions.setFirstname({
            firstname: event.target.value,
        });
    }

    function onLastnameChanged(event) {
        userActions.setLastname({
            lastname: event.target.value,
        });
    }

    function onEmailChanged(event) {
        userActions.setEmail({
            email: event.target.value,
        });
    }

    function onPasswordChanged(event) {
        userActions.setPassword({
            password: event.target.value,
        });
    }

    function onConfirmPasswordChanged(event) {
        userActions.setConfirmPassword({
            confirmPassword: event.target.value,
        });
    }

    return (
        <div className={styles.registerTestAccountScreen}>
            <div className={styles.registerTestAccountScreenContent}>
                <div className={styles.registerTestAccountScreenForm}>
                    <div>
                        <Logo showFullLogo={true} />
                        <div>
                            <p className={styles.registerTestAccountScreenFormText}>
                                {I18n.t(`${baseTranslationPath}registerTestAccountText`)}
                            </p>
                        </div>
                    </div>
                    <form onSubmit={onFormSubmit}>
                        <div>
                            <label>
                                <span>
                                    {I18n.t(`${baseTranslationPath}firstname`)}
                                </span>
                                <input
                                    type={TextInputType.text}
                                    placeholder={I18n.t(`${baseTranslationPath}firstnamePlaceholder`)}
                                    value={firstname}
                                    onChange={onFirstnameChanged}
                                />
                            </label>
                            <label>
                                <span>
                                    {I18n.t(`${baseTranslationPath}lastname`)}
                                </span>
                                <input
                                    type={TextInputType.text}
                                    placeholder={I18n.t(`${baseTranslationPath}lastnamePlaceholder`)}
                                    value={lastname}
                                    onChange={onLastnameChanged}
                                />
                            </label>
                        </div>
                        <label>
                            <span>
                                {I18n.t(`${baseTranslationPath}email`)}
                            </span>
                            <input
                                type={TextInputType.email}
                                placeholder={I18n.t(`${baseTranslationPath}emailPlaceholder`)}
                                value={email}
                                onChange={onEmailChanged}
                            />
                        </label>
                        <div>
                            <label>
                                <span>
                                    {I18n.t('password')}
                                </span>
                                <input
                                    type={TextInputType.password}
                                    placeholder={I18n.t(`${baseTranslationPath}password`)}
                                    value={password}
                                    onChange={onPasswordChanged}
                                />
                            </label>
                            <label>
                                <span>
                                    {I18n.t('confirmPassword')}
                                </span>
                                <input
                                    type={TextInputType.password}
                                    placeholder={I18n.t(`${baseTranslationPath}passwordPlaceholder`)}
                                    value={confirmPassword}
                                    onChange={onConfirmPasswordChanged}
                                />
                            </label>
                        </div>
                        <div className={styles.registerTextAccountCheckboxWrapper}>
                            <CheckBox
                                checked={accepted}
                                onClick={onAcceptedClicked}
                            />
                            <div>
                                <Trans i18nKey={`${baseTranslationPath}termsAndConditionsPrivacyText`}>
                                    <a href={I18n.t(`${baseTranslationPath}termsAndConditionsUrl`)} />
                                    <a href={I18n.t(`${baseTranslationPath}privacyUrl`)} />
                                </Trans>
                            </div>
                        </div>
                        <div className={styles.alertBoxWrapper}>
                            <AlertBoxManager />
                        </div>
                        <SimpleButton
                            text={I18n.t(`${baseTranslationPath}activateTestAccount`)}
                            disabled={isSubmitButtonDisabled}
                        />
                    </form>
                    <LandingpageFooter />
                </div>
            </div>
            <div className={styles.registerTestAccountScreenStyle} />
        </div>
    );
};

export default RegisterTestAccount;
