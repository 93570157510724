//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';

import PropTypes                 from '@components/PropTypes';
import PlanListNumber            from '@constants/PlanListNumber';
import { NumericPlanListNumber } from '@constants/PlanListNumber';
import Translator                from '@helper/Translator';
import { selectPlan }            from '@store/selectors/user';
import { selectOrganization }    from '@store/selectors/user';

import styles from './styles.module.scss';

const propTypes = {
    numberOfEntries: PropTypes.number,
};

const ListEntryCounter = ({
    numberOfEntries = 0,
}) => {
    const translator       = new Translator('components.listEntryCounter');
    const customerPlanPlan = useSelector(selectPlan);
    const organization     = useSelector(selectOrganization);
    const planEntryLimit   = _.get(customerPlanPlan, 'includedListEntries', null);
    const organizationType = _.get(organization, 'type', null);

    if (planEntryLimit === PlanListNumber.INFINITE) {
        return null;
    }

    const numericLimit = NumericPlanListNumber[planEntryLimit];

    function calculatePercentualWidth() {
        const percentage = (
            numberOfEntries / numericLimit
        ) * 100;

        if (percentage < 0) {
            return 0;
        }

        return percentage > 100
            ? 100
            : percentage;
    }

    function renderProgressBar() {
        const barStyle = {
            width: `${calculatePercentualWidth()}%`,
        };

        return (
            <div className={styles.progressBarWrapper}>
                <div
                    className={styles.progressBar}
                    style={barStyle}
                />
            </div>
        );
    }

    function renderSubText() {
        if (numericLimit > numberOfEntries) {
            return null;
        }

        let text = translator.t('limitExceededText');

        if (numberOfEntries === numericLimit) {
            text = translator.t('limitReachedText');
        }

        return (
            <p>
                {text}
            </p>
        );
    }

    function renderContent() {
        if (organizationType === 'FREE') {
            return (
                <p>
                    {translator.t('freePlanText')}
                </p>
            );
        }

        return (
            <>
                {renderProgressBar()}
                <p>
                    {translator.t('numberOfEntriesText', {
                        numberOfEntries,
                        numericLimit,
                    })}
                </p>
                {renderSubText()}
            </>
        );
    }

    return (
        <div className={styles.listEntryCounter}>
            {renderContent()}
        </div>
    );
};

ListEntryCounter.propTypes = propTypes;

export default ListEntryCounter;
