//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes              from '@components/PropTypes';
import TextInputTextAlignment from '@stateless/atomic/InputWrapper/TextInputTextAlignment';
import TextColor              from '@stateless/atomic/Text/TextColor';
import TextSize               from '@stateless/atomic/Text/TextSize';

import styles from './styles.module.scss';

const propTypes = {
    alignment: PropTypes.textAlignment,
    children:  PropTypes.children,
    color:     PropTypes.textColor,
    size:      PropTypes.textSize,
    text:      PropTypes.string,
};

const Text = ({
    alignment = TextInputTextAlignment.center,
    children = null,
    color = TextColor.black,
    size = TextSize.middle,
    text = '',
}) => {
    return (
        <div
            className={classNames(
                styles.text,
                {
                    [styles.textCenter]: alignment === TextInputTextAlignment.center,
                    [styles.textLeft]:   alignment === TextInputTextAlignment.left,
                    [styles.textRight]:  alignment === TextInputTextAlignment.right,
                    [styles.black]:      color === TextColor.black,
                    [styles.white]:      color === TextColor.white,
                    [styles.small]:      size === TextSize.small,
                    [styles.middle]:     size === TextSize.middle,
                },
            )}
        >
            {text}
            {children}
        </div>
    );
};

Text.propTypes = propTypes;

export default Text;
