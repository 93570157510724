//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    assistant:        0,
    whenWhere:        1,
    keyword:          2,
    step3Ad:          3,
    headquarter:      4,
    initialSituation: 5,
    firstAction:      6,
    step7Ad:          7,
    personsImpacted:  8,
    ownUnits:         9,
    ownVehicles:      10,
    externalUnits:    11,
    units:            12,
    specificFeatures: 13,
});
