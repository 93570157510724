//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// eslint-disable-next-line rulesdir/format-import-linter -- eslint wants to format this import
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import { convertToHTML }   from 'draft-convert';
import { convertFromHTML } from 'draft-convert';
import CharacterMetadata   from 'draft-js/lib/CharacterMetadata';
import ContentBlock        from 'draft-js/lib/ContentBlock';
import ContentState        from 'draft-js/lib/ContentState';
import EditorState         from 'draft-js/lib/EditorState';
import generateRandomKey   from 'draft-js/lib/generateRandomKey';
import I18n                from 'i18next';
import { List }            from 'immutable';
import { Repeat }          from 'immutable';
import _                   from 'lodash';
import { Editor }          from 'react-draft-wysiwyg';

import IconBold         from '@assets/icons/bold.svg';
import IconItalic       from '@assets/icons/italic.svg';
import IconUnderlined   from '@assets/icons/underlined.svg';
import DefaultTypes     from '@components/DefaultTypes';
import PropTypes        from '@components/PropTypes';
import ContentBlockType from '@constants/ContentBlockType';
import SvgToDataUrl     from '@helper/SvgToDataUrl';
import BorderWrapper    from '@stateless/atomic/BorderWrapper';

import styles from './styles.module.scss';

const propTypes = {
    onChange: PropTypes.func,
    open:     PropTypes.bool,
    text:     PropTypes.string,
};

const TextEditor = ({
    onChange = DefaultTypes.func,
    open = false,
    text = '',
}) => {
    const translationBasePath           = 'components.textEditor.';
    const [openState, setOpenState]     = useState(open);
    const contentState                  = convertFromHTML(text);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
    const toolbar                       = {
        options: ['inline'],
        inline:  {
            options:   [
                'bold',
                'italic',
                'underline',
            ],
            bold:      {
                icon:      SvgToDataUrl.convert(<IconBold />),
                className: styles.editorButton,

            },
            italic:    {
                icon:      SvgToDataUrl.convert(<IconItalic />),
                className: styles.editorButton,

            },
            underline: {
                icon:      SvgToDataUrl.convert(<IconUnderlined />),
                className: styles.editorButton,
            },
        },
    };

    function setEditorContent(blockArray) {
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(blockArray)));
    }

    function isTypeEquals(block, blockType) {
        return _.eq(block?.getType(), blockType);
    }

    function buildContentBlock(type, contentText) {
        return new ContentBlock({
            key:           generateRandomKey(),
            type,
            text:          contentText,
            characterList: new List(Repeat(CharacterMetadata.create(), text.length)),
        });
    }

    function cleanupArray(blockMapArray) {
        let shouldUpdate        = false;
        const updatedBlockArray = [];

        _.forEach(blockMapArray, (block) => {
            const blockIndex = blockMapArray.indexOf(block);

            if (
                block.getType() === ContentBlockType.header &&
                blockIndex > 0
            ) {
                const newUnstyledBlock = buildContentBlock(ContentBlockType.unstyled, block.getText());
                shouldUpdate           = true;

                updatedBlockArray.push(newUnstyledBlock);

                return;
            }

            if (!_.isNil(block)) {
                updatedBlockArray.push(block);
            }
        });

        return [shouldUpdate, updatedBlockArray];
    }

    function ensureFirstBlockIsHeadline(updatedBlockArray, blockMapArray) {
        const headlineBlockExists = _.filter(blockMapArray, (block) => {
            return (
                isTypeEquals(block, ContentBlockType.header) &&
                !_.eq(block.getText(), '')
            );
        });

        if (_.size(headlineBlockExists) === 0) {
            const headlineBlock = buildContentBlock(
                ContentBlockType.header,
                I18n.t(`${translationBasePath}defaultHeadlineTemplateText`),
            );
            const firstBlock    = updatedBlockArray[0];

            if (isTypeEquals(firstBlock, ContentBlockType.header)) {
                updatedBlockArray[0] = headlineBlock;
            } else {
                updatedBlockArray.unshift(headlineBlock);
            }

            return true;
        }

        return false;
    }

    function moveHeadlineToFirstBlock(updatedBlockArray) {
        const headerOneIndex = _.findIndex(updatedBlockArray, (block) => {
            return isTypeEquals(block, ContentBlockType.header);
        });

        if (headerOneIndex > 0) {
            const headerOneBlock = updatedBlockArray[headerOneIndex];
            updatedBlockArray.splice(headerOneIndex, 1);
            updatedBlockArray.unshift(headerOneBlock);

            return true;
        }

        return false;
    }

    function ensureUnstyledBlockExists(updatedBlockArray) {
        const unstyledBlockExists = _.filter(updatedBlockArray, (block) => {
            return (
                isTypeEquals(block, ContentBlockType.unstyled) &&
                !_.eq(block.getText(), '')
            );
        });

        if (_.size(unstyledBlockExists) === 0) {
            const textBlock   = buildContentBlock(
                ContentBlockType.unstyled,
                I18n.t(`${translationBasePath}defaultTextTemplateText`),
            );
            const secondBlock = updatedBlockArray[1];

            if (isTypeEquals(secondBlock, ContentBlockType.unstyled)) {
                updatedBlockArray[1] = textBlock;
            } else {
                updatedBlockArray.push(textBlock);
            }

            return true;
        }

        return false;
    }

    function checkAndFixContent() {
        const editorContentState                   = editorState.getCurrentContent();
        const blockMap                             = editorContentState.getBlockMap();
        const blockMapArray                        = blockMap.toArray();
        let [updateEditorState, updatedBlockArray] = cleanupArray(blockMapArray);

        if (ensureFirstBlockIsHeadline(updatedBlockArray, blockMapArray)) {
            updateEditorState = true;
        }

        updatedBlockArray = _.compact(updatedBlockArray);

        if (
            moveHeadlineToFirstBlock(updatedBlockArray) ||
            ensureUnstyledBlockExists(updatedBlockArray)
        ) {
            updateEditorState = true;
        }

        if (
            updateEditorState &&
            _.size(updatedBlockArray)
        ) {
            setEditorContent(updatedBlockArray);
        }
    }

    useEffect(
        () => {
            if (open !== openState) {
                const updatedContentState = convertFromHTML(text);

                setEditorState(EditorState.createWithContent(updatedContentState));
                setOpenState(open);
            }
        },
        [open, editorState],
    );

    function onEditorStateChanged(newEditorState) {
        const htmlOld = convertToHTML(editorState.getCurrentContent());
        const html    = convertToHTML(newEditorState.getCurrentContent());

        if (!_.includes(html, '<p>')) {
            const newContentState = convertFromHTML(htmlOld);

            setEditorState(EditorState.createWithContent(newContentState));
            onChange(htmlOld);

            return;
        }

        setEditorState(newEditorState);
        onChange(html);
    }

    return (
        <BorderWrapper>
            <Editor
                editorState={editorState}
                toolbar={toolbar}
                toolbarClassName={styles.toolbar}
                editorClassName={styles.editor}
                onEditorStateChange={onEditorStateChanged}
                onBlur={checkAndFixContent}
            />
        </BorderWrapper>
    );
};

TextEditor.propTypes = propTypes;

export default TextEditor;
