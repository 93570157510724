//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';
import PropTypes  from 'prop-types';

import DefaultTypes          from '@components/DefaultTypes';
import AlertBoxManager       from '@connected/AlertBoxManager';
import Environment           from '@helper/Environment';
import CheckBox              from '@stateless/atomic/CheckBox';
import CheckboxSize          from '@stateless/atomic/CheckBox/CheckboxSize';
import Collapsable           from '@stateless/atomic/Collapsable';
import IconType              from '@stateless/atomic/Icon/IconType';
import Overlay               from '@stateless/atomic/Overlay';
import TextInput             from '@stateless/atomic/TextInput';
import TextInputType         from '@stateless/atomic/TextInput/TextInputType';
import ColorButton           from '@stateless/composed/ColorButton';
import ColorButtonColorTheme from '@stateless/composed/ColorButton/ColorButtonColorTheme';
import ColorButtonFontTheme  from '@stateless/composed/ColorButton/ColorButtonFontTheme';

import styles from './styles.module.scss';

const propTypes = {
    emailNotificationsEnabled:         PropTypes.bool,
    loginWooCommerceClicked:           PropTypes.func,
    navigateToInvoicesAndPlansClicked: PropTypes.func,
    notificationsOpen:                 PropTypes.bool,
    oldPassword:                       PropTypes.string,
    onCloseClick:                      PropTypes.func,
    onEmailNotificationsEnabledClick:  PropTypes.func,
    onNotificationsOpen:               PropTypes.func,
    onOldPasswordChanged:              PropTypes.func,
    onOrganizationOpen:                PropTypes.func,
    onPasswordChanged:                 PropTypes.func,
    onPasswordCheckChanged:            PropTypes.func,
    onPasswordOpen:                    PropTypes.func,
    open:                              PropTypes.bool,
    organizationName:                  PropTypes.string,
    organizationNameChanged:           PropTypes.func,
    organizationOpen:                  PropTypes.bool,
    password:                          PropTypes.string,
    passwordCheck:                     PropTypes.string,
    passwordMismatch:                  PropTypes.bool,
    passwordOpen:                      PropTypes.bool,
    saveNotificationSettingsClicked:   PropTypes.func,
    updateOrganizationClicked:         PropTypes.func,
    updatePasswordClicked:             PropTypes.func,
};

const UserSettingsOverlay = ({
    onCloseClick = DefaultTypes.func,
    onOldPasswordChanged = DefaultTypes.func,
    onPasswordChanged = DefaultTypes.func,
    onPasswordCheckChanged = DefaultTypes.func,
    open = false,
    organizationName = '',
    organizationNameChanged = DefaultTypes.func,
    passwordMismatch = false,
    loginWooCommerceClicked = DefaultTypes.func,
    updateOrganizationClicked = DefaultTypes.func,
    updatePasswordClicked = DefaultTypes.func,
    onOrganizationOpen = DefaultTypes.func,
    onPasswordOpen = DefaultTypes.func,
    organizationOpen = false,
    notificationsOpen = false,
    onNotificationsOpen = DefaultTypes.func,
    emailNotificationsEnabled = false,
    onEmailNotificationsEnabledClick = DefaultTypes.func,
    passwordOpen = false,
    navigateToInvoicesAndPlansClicked = DefaultTypes.func,
    password = '',
    saveNotificationSettingsClicked = DefaultTypes.func,
    passwordCheck = '',
    oldPassword = '',
}) => {
    function renderWooCommerceLoginButton() {
        if (Environment.isProduction()) {
            return (
                <div>
                    <ColorButton
                        text={I18n.t('components.userSettingsOverlay.loginWooCommerce')}
                        colorTheme={ColorButtonColorTheme.green}
                        fontTheme={ColorButtonFontTheme.middle}
                        fullWidth={false}
                        onClick={loginWooCommerceClicked}
                    />
                </div>
            );
        }

        return null;
    }

    function renderNavigateToInvoicesAndPlansButton() {
        return (
            <div>
                <ColorButton
                    text={I18n.t('components.userSettingsOverlay.invoicesAndPlans')}
                    colorTheme={ColorButtonColorTheme.green}
                    fontTheme={ColorButtonFontTheme.middle}
                    fullWidth={false}
                    onClick={navigateToInvoicesAndPlansClicked}
                />
            </div>
        );
    }

    return (
        <Overlay
            onCloseClick={onCloseClick}
            open={open}
            blue={true}
        >
            <div className={styles.userSettingsOverlay}>
                <Collapsable
                    title={I18n.t('components.userSettingsOverlay.changePassword')}
                    visible={passwordOpen}
                    setVisible={onPasswordOpen}
                >
                    <div className={styles.changePasswordContent}>
                        <p>
                            {I18n.t('components.userSettingsOverlay.oldPassword')}
                        </p>
                        <TextInput
                            type={TextInputType.password}
                            onChange={onOldPasswordChanged}
                            value={oldPassword}
                        />
                        <p>
                            {I18n.t('components.userSettingsOverlay.newPassword')}
                        </p>
                        <TextInput
                            type={TextInputType.password}
                            onChange={onPasswordChanged}
                            value={password}
                        />
                        <p>
                            {I18n.t('components.userSettingsOverlay.repeatPassword')}
                        </p>
                        <div
                            className={classNames(
                                styles.passwordContainer,
                                {
                                    [styles.passwordMismatch]: passwordMismatch,
                                },
                            )}
                        >
                            <TextInput
                                type={TextInputType.password}
                                onChange={onPasswordCheckChanged}
                                value={passwordCheck}
                            />
                        </div>
                        <div className={styles.alertBoxManagerWrapper}>
                            <AlertBoxManager />
                        </div>
                        <ColorButton
                            text={I18n.t('components.userSettingsOverlay.updatePassword')}
                            colorTheme={ColorButtonColorTheme.green}
                            fontTheme={ColorButtonFontTheme.middle}
                            iconTypeLeft={IconType.sync}
                            onClick={updatePasswordClicked}
                        />
                    </div>
                </Collapsable>
                <Collapsable
                    title={I18n.t('components.userSettingsOverlay.organization')}
                    visible={organizationOpen}
                    setVisible={onOrganizationOpen}
                >
                    <div className={styles.changePasswordContent}>
                        <p>
                            {I18n.t('components.userSettingsOverlay.organizationName')}
                        </p>
                        <TextInput
                            value={organizationName}
                            onChange={organizationNameChanged}
                        />
                        <div className={styles.alertBoxManagerWrapper}>
                            <AlertBoxManager />
                        </div>
                        <ColorButton
                            text={I18n.t('components.userSettingsOverlay.updateData')}
                            colorTheme={ColorButtonColorTheme.green}
                            fontTheme={ColorButtonFontTheme.middle}
                            iconTypeLeft={IconType.sync}
                            onClick={updateOrganizationClicked}
                        />
                    </div>
                </Collapsable>
                <Collapsable
                    title={I18n.t('components.userSettingsOverlay.notifications')}
                    visible={notificationsOpen}
                    setVisible={onNotificationsOpen}
                >
                    <div className={styles.updateNotificationSettingsWrapper}>
                        <CheckBox
                            size={CheckboxSize.large}
                            checked={emailNotificationsEnabled}
                            onClick={onEmailNotificationsEnabledClick}
                            text={I18n.t('components.userSettingsOverlay.enableEmailNotification')}
                        />
                        <div className={styles.alertBoxManagerWrapper}>
                            <AlertBoxManager />
                        </div>
                        <ColorButton
                            text={I18n.t('components.userSettingsOverlay.saveSettings')}
                            colorTheme={ColorButtonColorTheme.green}
                            fontTheme={ColorButtonFontTheme.middle}
                            iconTypeLeft={IconType.sync}
                            onClick={saveNotificationSettingsClicked}
                        />
                    </div>
                </Collapsable>
                {renderWooCommerceLoginButton()}
                {renderNavigateToInvoicesAndPlansButton()}
            </div>
        </Overlay>
    );
};

UserSettingsOverlay.propTypes = propTypes;

export default UserSettingsOverlay;
