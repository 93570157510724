//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }  from 'redux-saga/effects';
import { call } from 'redux-saga/effects';

import * as Api                 from '@api/index';
import { ConfigurationActions } from '@slices/configuration';

function* fetchConfiguration() {
    const response = yield call(Api.context.configuration.fetch);

    if (response.ok) {
        const configuration = response.data;

        yield put(ConfigurationActions.fetchConfigurationSucceeded({
            configuration,
        }));
    } else {
        yield put(ConfigurationActions.fetchConfigurationFailed());
    }
}

export default {
    fetchConfiguration,
};
