//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import AlertBoxType from '@components/stateless/atomic/AlertBox/AlertBoxType';

const initialState = Object.freeze({
    alertBoxes: [],
});

const defaultLifeCounter = 1;

const showAlert = (state, action, type = AlertBoxType.success) => {
    const payload     = _.get(action, 'payload', {});
    const lifeCounter = _.defaultTo(payload.lifeCounter, defaultLifeCounter);

    state.alertBoxes.push({
        lifeCounter,
        text:      payload.text,
        type,
        iconLeft:  payload.iconLeft,
        iconRight: payload.iconRight,
    });
};

const alertBoxSlice = createSlice({
    name:     'alertBox',
    initialState,
    reducers: {
        clearAlerts:                () => {
            return initialState;
        },
        clearSingleAlert:           (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                alertBoxes: {
                    $removeArrayItemAtIndex: payload.index,
                },
            });
        },
        showErrorAlert:             (state, action) => {
            showAlert(state, action, AlertBoxType.error);
        },
        showErrorAlertTranslated:   (state, action) => {
        },
        showSuccessAlert:           (state, action) => {
            showAlert(state, action);
        },
        showSuccessAlertTranslated: (state, action) => {
        },
        alertBoxLocationChange:     (state, action) => {
            const locationChangeUpdateConfiguration = {
                alertBoxes: {},
            };
            const alertBoxesToDelete                = [];

            for (const alertBoxIndex in state.alertBoxes) {
                const alertBox = state.alertBoxes[alertBoxIndex];

                if (defaultLifeCounter >= alertBox.lifeCounter) {
                    alertBoxesToDelete.push([
                        alertBoxIndex,
                        1,
                    ]);
                } else {
                    locationChangeUpdateConfiguration.alertBoxes[alertBoxIndex] = {
                        lifeCounter: {
                            $set: alertBox.lifeCounter - 1,
                        },
                    };
                }
            }

            const stateWithIncreasedLifeCounters = update(state, locationChangeUpdateConfiguration);

            alertBoxesToDelete.reverse();

            const stateWithRemovedAlertBoxes = update(stateWithIncreasedLifeCounters, {
                alertBoxes: {
                    $splice: alertBoxesToDelete,
                },
            });

            return stateWithRemovedAlertBoxes;
        },
    },
});

export const AlertBoxActions = alertBoxSlice.actions;

export const AlertBoxReducer = alertBoxSlice.reducer;

export const useAlertBox = (dispatch) => bindActionCreators(AlertBoxActions, dispatch);

export default alertBoxSlice;
