//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useDispatch } from 'react-redux';

import PropTypes                  from '@components/PropTypes';
import StatelessMobileMenuOverlay from '@components/stateless/composed/MobileMenuOverlay';
import Url                        from '@helper/Url';
import { OverlayActions }         from '@slices/overlay';
import { useUser }                from '@slices/user';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const MobileMenuOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch    = useDispatch();
    const userActions = useUser(dispatch);

    function navigateClicked(to) {
        return () => {
            dispatch(OverlayActions.closeOverlay());
            Url.openInNewTab(to);
        };
    }

    function openOverlayClicked(overlay) {
        return () => {
            dispatch(OverlayActions.openOverlay({
                overlay,
            }));
        };
    }

    function logoutClicked() {
        return () => {
            dispatch(OverlayActions.closeOverlay());
            userActions.logout();
        };
    }

    return (
        <StatelessMobileMenuOverlay
            onCloseClick={onCloseClick}
            open={open}
            navigateClicked={navigateClicked}
            openOverlayClicked={openOverlayClicked}
            logoutClicked={logoutClicked}
        />
    );
};

MobileMenuOverlay.propTypes = propTypes;

export default MobileMenuOverlay;
