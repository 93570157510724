//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                     from '@components/PropTypes';
import { ParticularitiesActions }    from '@slices/particularities';
import StatelessConfirmOverlay       from '@stateless/composed/ConfirmOverlay';
import { selectParticularityInEdit } from '@store/selectors/particularities';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const DeleteParticularityOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch            = useDispatch();
    const particularityInEdit = useSelector(selectParticularityInEdit);
    const baseTranslationPath = 'components.editParticularitiesOverlay.';

    function onConfirmClick() {
        dispatch(ParticularitiesActions.deleteParticularity(particularityInEdit));
    }

    function onDenyClick() {
        dispatch(ParticularitiesActions.setParticularityInEdit(null));
        onCloseClick();
    }

    return (
        <StatelessConfirmOverlay
            onCloseClick={onCloseClick}
            open={open}
            confirmText={I18n.t(`${baseTranslationPath}deleteParticularityText`)}
            buttonConfirmText={I18n.t(`${baseTranslationPath}deleteParticularityConfirmButtonText`)}
            buttonDenyText={I18n.t(`${baseTranslationPath}deleteParticularityDenyButtonText`)}
            onConfirmClick={onConfirmClick}
            onDenyClick={onDenyClick}
        />
    );
};

DeleteParticularityOverlay.propTypes = propTypes;

export default DeleteParticularityOverlay;
