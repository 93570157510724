//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import I18n            from 'i18next';
import update          from 'immutability-helper';
import _               from 'lodash';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    units:      [],
    unitInEdit: null,
});

const externalUnitsSlice = createSlice({
    name:     'externalUnits',
    initialState,
    reducers: {
        resetUnits:            () => {
            return initialState;
        },
        fetchUnits:            LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loadingExternalUnits')),
        fetchUnitsFailed:      LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchUnitsSucceeded:   LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                units: {
                    $set: _.get(action, 'payload.units', []),
                },
            });
        }),
        setExternalUnitInEdit: (state, action) => {
            const unit = _.get(action, 'payload');

            return update(state, {
                unitInEdit: {
                    $set: unit,
                },
            });
        },
        updateUnit:            LoadingLevelHelper.increaseLoadingEmptyReducer((state) => {
            const iri = _.get(state, 'externalUnits.unitInEdit.id', null);

            return (
                iri ?
                    I18n.t('updatingExternalUnit') :
                    I18n.t('creatingExternalUnit')
            );
        }),
        updateUnitFailed:      LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        updateUnitSucceeded:   LoadingLevelHelper.decreaseLoading((state, action) => {
            const newUnit      = _.get(action, 'payload.unit');
            const exisingIndex = _.findIndex(state.units, {
                id: newUnit.id,
            });

            if (exisingIndex === -1) {
                return update(state, {
                    units: {
                        $push: [newUnit],
                    },
                });
            }

            return update(state, {
                units: {
                    [exisingIndex]: {
                        $set: newUnit,
                    },
                },
            });
        }),
        deleteUnit:            LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('deletingExternalUnit')),
        deleteUnitFailed:      LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        deleteUnitSucceeded:   LoadingLevelHelper.decreaseLoading((state, action) => {
            const unit     = _.get(action, 'payload');
            const id       = _.get(unit, 'id');
            const newUnits = state.units.filter((oldUnit) => oldUnit.id !== id);

            return update(state, {
                units: {
                    $set: newUnits,
                },
            });
        }),
    },
});

export const ExternalUnitsActions = externalUnitsSlice.actions;

export const ExternalUnitsReducer = externalUnitsSlice.reducer;

export default externalUnitsSlice;
