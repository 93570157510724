//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _           from 'lodash';
import queryString from 'query-string';

import RouteHelper from '@store/helper/RouteHelper';

class Navigation {
    static goBack() {
        window.history.back();
    }

    static getToFromRouteObject(routeObject, searchObject = [], hash = null) {
        let searchParameters = {};
        let pathname         = routeObject;

        if (typeof routeObject === 'object') {
            pathname         = _.get(routeObject, 'route', '');
            const parameters = _.get(routeObject, 'parameters', {});
            searchParameters = queryString.parse(_.get(routeObject, 'search', {}));
            pathname         = RouteHelper.replaceParametersInUrl(pathname, parameters);
        }

        const search       = (
            Object
                .entries({
                    ...searchObject,
                    ...searchParameters,
                })
                .map(([key, value]) => `${key}=${value}`)
                .join('&')
        );
        const toParameters = {
            pathname,
            search: (
                search ?
                    `?${search}` :
                    ''
            ),
            hash:   (
                hash ?
                    `#${hash}` :
                    ''
            ),
        };

        return toParameters;
    }

    static getParameterFromUrl(ownProps, parameter) {
        return _.get(
            ownProps,
            [
                'match',
                'params',
                parameter,
            ],
        );
    }
}

export default Navigation;
