//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import { useRef } from 'react';

import PropTypes from '@components/PropTypes';
import Colors    from '@styles/colors.scss';

import styles from './styles.module.scss';

const propTypes = {
    color:      PropTypes.string,
    percentage: PropTypes.number,
    size:       PropTypes.number,
};

const PlanCircle = ({
    color = Colors.colorBlack,
    percentage = 0,
    size = 200,
}) => {
    const circleRadius    = size / 2;
    // eslint-disable-next-line rulesdir/no-shortcut-variable-name -- This is a constant
    const circumReference = circleRadius * 2 * Math.PI;
    const circleOffset    = circumReference - (
        circumReference * (
            percentage / 100
        )
    );
    const strokeWidth     = 12;
    const circleReference = useRef();

    function getStyle() {
        return {
            strokeDashoffset: circleOffset,
            strokeDasharray:  circumReference,
        };
    }

    return (
        <svg
            className={styles.planCircleWrapper}
            width={size}
            height={size}
        >
            <circle
                className={styles.planCircle}
                ref={circleReference}
                stroke={Colors.colorWhite20}
                strokeWidth={strokeWidth}
                fill="transparent"
                r={circleRadius}
                cx={circleRadius}
                cy={circleRadius}
            />
            <circle
                style={getStyle()}
                className={styles.planCircle}
                ref={circleReference}
                stroke={color}
                strokeWidth={strokeWidth}
                fill="transparent"
                r={circleRadius}
                cx={circleRadius}
                cy={circleRadius}
            />
        </svg>
    );
};

PlanCircle.propTypes = propTypes;

export default PlanCircle;
