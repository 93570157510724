import { useRef }    from 'react';
import { useEffect } from 'react';

const useAutoFocus = (dependencies) => {
    const reference = useRef(null);

    useEffect(() => {
        reference.current.focus();
    }, dependencies || []);

    return reference;
};

export default useAutoFocus;
