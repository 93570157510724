//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import Firewriter from '@assets/images/firewriter.svg';
import Flame      from '@assets/images/flame.svg';
import PropTypes  from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    showFullLogo: PropTypes.bool,
};

const Logo = ({
    showFullLogo = false,
}) => {
    const logo = (
        showFullLogo ?
            <Firewriter /> :
            <Flame />
    );

    return (
        <div className={styles.logo}>
            {logo}
        </div>
    );
};

Logo.propTypes = propTypes;

export default Logo;
