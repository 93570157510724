//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import PropTypes  from 'prop-types';

import DefaultTypes          from '@components/DefaultTypes';
import AdBanner              from '@stateless/atomic/AdBanner';
import AdBannerSlot          from '@stateless/atomic/AdBanner/AdBannerSlot';
import AssistantTip          from '@stateless/atomic/AssistantTip';
import IconType              from '@stateless/atomic/Icon/IconType';
import ListEntryCounter      from '@stateless/atomic/ListEntryCounter';
import Overlay               from '@stateless/atomic/Overlay';
import ColorButton           from '@stateless/composed/ColorButton';
import ColorButtonColorTheme from '@stateless/composed/ColorButton/ColorButtonColorTheme';

import styles from './styles.module.scss';

const propTypes = {
    buttonText:        PropTypes.string,
    hintText:          PropTypes.string,
    newLinePerElement: PropTypes.bool,
    numberOfUnits:     PropTypes.number,
    onAddUnitClicked:  PropTypes.func,
    onCloseClick:      PropTypes.func,
    open:              PropTypes.bool,
    renderUnits:       PropTypes.func,
    setUnitInEdit:     PropTypes.func,
    showHint:          PropTypes.bool,
};

const EditUnitsOverlay = ({
    onCloseClick = DefaultTypes.func,
    open = false,
    onAddUnitClicked = DefaultTypes.func,
    setUnitInEdit = DefaultTypes.func,
    renderUnits = DefaultTypes.func,
    numberOfUnits = 0,
    newLinePerElement = false,
    buttonText = '',
    showHint = true,
    hintText = '',
}) => {
    useEffect(() => {
        if (open) {
            setUnitInEdit(null);
        }
    }, [open]);

    function renderHint() {
        if (!showHint) {
            return null;
        }

        return (
            <div className={styles.hintContainer}>
                <AssistantTip text={hintText} />
            </div>
        );
    }

    return (
        <Overlay
            onCloseClick={onCloseClick}
            open={open}
            mobileTransparent={true}
        >
            <div className={styles.unitOverlayContent}>
                <ColorButton
                    text={buttonText}
                    colorTheme={ColorButtonColorTheme.white}
                    iconTypeLeft={IconType.add}
                    onClick={onAddUnitClicked}
                />
                <ListEntryCounter
                    numberOfEntries={numberOfUnits}
                />
                <AdBanner slot={AdBannerSlot.LIST_BANNER} />
                <div
                    className={classNames(
                        styles.unitOverlayContentContainer,
                        {
                            [styles.newLinePerElement]: newLinePerElement,
                        },
                    )}
                >
                    {renderUnits()}
                </div>
                {renderHint()}
            </div>
        </Overlay>
    );
};

EditUnitsOverlay.propTypes = propTypes;

export default EditUnitsOverlay;
