//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Environment             from '@helper/Environment';
import { useLoading }          from '@slices/loading';
import StatelessLoadingOverlay from '@stateless/atomic/LoadingOverlay';
import selectLoading           from '@store/selectors/loading';
import { selectLoadingText }   from '@store/selectors/loading';

const LoadingOverlay = () => {
    const dispatch                      = useDispatch();
    const isLoading                     = useSelector(selectLoading);
    const text                          = useSelector(selectLoadingText);
    const loadingActions                = useLoading(dispatch);
    const [showLoading, setShowLoading] = useState(false);
    const [timeoutId, setTimeoutId]     = useState(null);
    const isLoadingOverlayVisible       = (
        Environment.isDevelopment() ?
            isLoading :
            showLoading
    );

    useEffect(
        () => {
            const handler = setTimeout(() => {
                if (isLoading) {
                    setShowLoading(true);

                    if (timeoutId) {
                        clearTimeout(timeoutId);
                        setTimeoutId(null);
                    }
                } else {
                    const currentTimeout = setTimeout(
                        () => {
                            setShowLoading(false);
                        },
                        100,
                    );

                    setTimeoutId(currentTimeout);
                }

                return _.noop;
            }, 300);

            return () => {
                clearTimeout(handler);
            };
        },
        [isLoading],
    );

    function loadingOverlayClicked() {
        loadingActions.resetOverlay();
        setShowLoading(false);
    }

    return (
        <StatelessLoadingOverlay
            isLoading={isLoadingOverlayVisible}
            loadingOverlayClicked={loadingOverlayClicked}
            text={text}
        />
    );
};

export default LoadingOverlay;
