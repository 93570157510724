//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LOCATION_CHANGE } from 'connected-react-router';
import { REHYDRATE }       from 'redux-persist';
import { all }             from 'redux-saga/effects';
import { takeLatest }      from 'redux-saga/effects';
import { takeEvery }       from 'redux-saga/effects';

import { AlertBoxActions }              from '@slices/alertBox';
import { ConfigurationActions }         from '@slices/configuration';
import { CreatePressArticleActions }    from '@slices/createPressArticle';
import { EditPressArticleActions }      from '@slices/editPressArticle';
import { ExternalUnitsActions }         from '@slices/externalUnits';
import { GeneratePressArticleActions }  from '@slices/generatePressArticle';
import { LoadingActions }               from '@slices/loading';
import { NavigationActions }            from '@slices/navigation';
import { ParticularitiesActions }       from '@slices/particularities';
import { PressArticleActions }          from '@slices/pressArticle';
import { SmartUpdaterActions }          from '@slices/smartUpdater';
import { StagingAuthenticationActions } from '@slices/stagingAuthentication';
import { UnitsActions }                 from '@slices/units';
import { UserActions }                  from '@slices/user';
import { VehiclesActions }              from '@slices/vehicles';
import { WindowActions }                from '@slices/window';

// Sagas
import AlertBoxSagas              from './alertBox';
import ConfigurationSagas         from './configuration';
import CreatePressArticleSagas    from './createPressArticle';
import EditPressArticleSagas      from './editPressArticle';
import ExternalUnitsSagas         from './externalUnits';
import GeneratePressArticleSagas  from './generatePressArticle';
import LoadingSagas               from './loading';
import NavigationSagas            from './navigation';
import ParticularitiesSagas       from './particularities';
import PreLoadSagas               from './preload';
import PressArticleSagas          from './pressArticle';
import SmartUpdaterSagas          from './smartUpdater';
import StagingAuthenticationSagas from './stagingAuthentication';
import OwnUnitsSagas              from './units';
import UserSagas                  from './user';
import OwnVehiclesSagas           from './vehicles';
import WindowSagas                from './window';

const callExternalUnitsSagas = () => {
    return [
        // @formatter:off
        takeLatest([ExternalUnitsActions.fetchUnits().type],          ExternalUnitsSagas.fetchUnits),
        takeLatest([ExternalUnitsActions.updateUnit().type],          ExternalUnitsSagas.updateUnit),
        takeLatest([ExternalUnitsActions.updateUnitFailed().type],    ExternalUnitsSagas.updateUnitFailed),
        takeLatest([ExternalUnitsActions.updateUnitSucceeded().type], ExternalUnitsSagas.updateUnitSucceeded),
        takeLatest([ExternalUnitsActions.deleteUnit().type],          ExternalUnitsSagas.deleteUnit),
        takeLatest([ExternalUnitsActions.deleteUnitFailed().type],    ExternalUnitsSagas.deleteUnitFailed),
        takeLatest([ExternalUnitsActions.deleteUnitSucceeded().type], ExternalUnitsSagas.deleteUnitSucceeded),
        // @formatter:on
    ];
};

const callOwnUnitsSagas = () => {
    return [
        // @formatter:off
        takeLatest([UnitsActions.fetchUnits().type],          OwnUnitsSagas.fetchUnits),
        takeLatest([UnitsActions.updateUnit().type],          OwnUnitsSagas.updateUnit),
        takeLatest([UnitsActions.updateUnitFailed().type],    OwnUnitsSagas.updateUnitFailed),
        takeLatest([UnitsActions.updateUnitSucceeded().type], OwnUnitsSagas.updateUnitSucceeded),
        takeLatest([UnitsActions.deleteUnit().type],          OwnUnitsSagas.deleteUnit),
        takeLatest([UnitsActions.deleteUnitFailed().type],    OwnUnitsSagas.deleteUnitFailed),
        takeLatest([UnitsActions.deleteUnitSucceeded().type], OwnUnitsSagas.deleteUnitSucceeded),
        // @formatter:on
    ];
};

const callOwnParticularitiesSagas = () => {
    return [
        // @formatter:off
        takeLatest([ParticularitiesActions.fetchParticularities().type],         ParticularitiesSagas.fetchParticularities),
        takeLatest([ParticularitiesActions.updateParticularity().type],          ParticularitiesSagas.updateParticularity),
        takeLatest([ParticularitiesActions.updateParticularityFailed().type],    ParticularitiesSagas.updateParticularityFailed),
        takeLatest([ParticularitiesActions.updateParticularitySucceeded().type], ParticularitiesSagas.updateParticularitySucceeded),
        takeLatest([ParticularitiesActions.deleteParticularity().type],          ParticularitiesSagas.deleteParticularity),
        takeLatest([ParticularitiesActions.deleteParticularitySucceeded().type], ParticularitiesSagas.deleteParticularitySucceeded),
        takeLatest([ParticularitiesActions.deleteParticularityFailed().type],    ParticularitiesSagas.deleteParticularityFailed),
        // @formatter:on
    ];
};

const callOwnVehiclesSagas = () => {
    return [
        // @formatter:off
        takeLatest([VehiclesActions.fetchVehicles().type],          OwnVehiclesSagas.fetchVehicles),
        takeLatest([VehiclesActions.updateVehicle().type],          OwnVehiclesSagas.updateVehicle),
        takeLatest([VehiclesActions.updateVehicleFailed().type],    OwnVehiclesSagas.updateVehicleFailed),
        takeLatest([VehiclesActions.updateVehicleSucceeded().type], OwnVehiclesSagas.updateVehicleSucceeded),
        takeLatest([VehiclesActions.deleteVehicle().type],          OwnVehiclesSagas.deleteVehicle),
        takeLatest([VehiclesActions.deleteVehicleFailed().type],    OwnVehiclesSagas.deleteVehicleFailed),
        takeLatest([VehiclesActions.deleteVehicleSucceeded().type], OwnVehiclesSagas.deleteVehicleSucceeded),
        // @formatter:on
    ];
};

const callAlertBoxSagas = () => {
    return [
        // @formatter:off
        takeLatest([AlertBoxActions.showErrorAlertTranslated().type],   AlertBoxSagas.showErrorAlertTranslated),
        takeLatest([AlertBoxActions.showSuccessAlertTranslated().type], AlertBoxSagas.showSuccessAlertTranslated),
        // @formatter:on
    ];
};

const callConfigurationSagas = () => {
    return [
        // @formatter:off
        takeLatest([ConfigurationActions.fetchConfiguration().type], ConfigurationSagas.fetchConfiguration),
        // @formatter:on
    ];
};

const callSmartUpdaterSagas = () => {
    return [
        // @formatter:off
        takeLatest([SmartUpdaterActions.reloadData().type],    SmartUpdaterSagas.reloadData),
        takeLatest([SmartUpdaterActions.reloadAllData().type], SmartUpdaterSagas.reloadAllData),
        // @formatter:on
    ];
};

const callStagingAuthenticationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.resetState().type],              StagingAuthenticationSagas.reset),
        takeLatest([StagingAuthenticationActions.authenticate().type], StagingAuthenticationSagas.authenticate),
        // @formatter:on
    ];
};

const callUserSagas = () => {
    return [
        // @formatter:off
        takeLatest([UserActions.updateOrganization().type],                  UserSagas.updateOrganization),
        takeLatest([UserActions.updateOrganizationSucceeded().type],         UserSagas.updateOrganizationSucceeded),
        takeLatest([UserActions.updateOrganizationFailed().type],            UserSagas.updateOrganizationFailed),
        takeLatest([UserActions.login().type],                               UserSagas.login),
        takeLatest([UserActions.loginWithCredentials().type],                UserSagas.loginWithCredentials),
        takeLatest([UserActions.loginFailed().type],                         UserSagas.loginFailed),
        takeEvery([UserActions.loginSucceeded().type],                       UserSagas.loginSucceeded),
        takeEvery([UserActions.loginSucceeded().type],                       PreLoadSagas.preLoadData),
        takeEvery([UserActions.fetchCustomerPlan().type],                    UserSagas.fetchCustomerPlan),
        takeEvery([UserActions.fetchUser().type],                            UserSagas.fetchUser),
        takeEvery([UserActions.fetchUserFailed().type],                      UserSagas.fetchUserFailed),
        takeEvery([UserActions.fetchUserSucceeded().type],                   UserSagas.fetchUserSucceeded),
        takeLatest([UserActions.tryRestoreToken().type],                     UserSagas.restoreToken),
        takeLatest([UserActions.logout().type],                              UserSagas.logout),
        takeLatest([UserActions.updatePassword().type],                      UserSagas.updatePassword),
        takeLatest([UserActions.updatePasswordSucceeded().type],             UserSagas.updatePasswordSucceeded),
        takeLatest([UserActions.updatePasswordFailed().type],                UserSagas.updatePasswordFailed),
        takeLatest([UserActions.resetPassword().type],                       UserSagas.resetPassword),
        takeLatest([UserActions.resetPasswordSucceeded().type],              UserSagas.resetPasswordSucceeded),
        takeLatest([UserActions.resetPasswordFailed().type],                 UserSagas.resetPasswordFailed),
        takeLatest([UserActions.setNewPassword().type],                      UserSagas.setNewPassword),
        takeLatest([UserActions.setNewPasswordSucceeded().type],             UserSagas.setNewPasswordSucceeded),
        takeLatest([UserActions.setNewPasswordFailed().type],                UserSagas.setNewPasswordFailed),
        takeLatest([UserActions.updateUser().type],                          UserSagas.updateUser),
        takeLatest([UserActions.updateUserSucceeded().type],                 UserSagas.updateUserSucceeded),
        takeLatest([UserActions.updateUserFailed().type],                    UserSagas.updateUserFailed),
        takeLatest([UserActions.activateTestAccount().type],                 UserSagas.activateTestAccount),
        takeLatest([UserActions.activateTestAccountFailed().type],           UserSagas.activateTestAccountFailed),
        takeLatest([UserActions.activateTestAccountSucceeded().type],        UserSagas.activateTestAccountSucceeded),
        takeLatest([NavigationActions.resetState().type],                    UserSagas.logout),
        takeLatest([UserActions.registerTestAccount().type],                 UserSagas.registerTestAccount),
        takeLatest([UserActions.registerTestAccountFailed().type],           UserSagas.registerTestAccountFailed),
        takeLatest([UserActions.registerTestAccountSucceeded().type],        UserSagas.registerTestAccountSucceeded),
        takeLatest([UserActions.updateNotificationSettings().type],          UserSagas.updateNotificationSettings),
        takeLatest([UserActions.updateNotificationSettingsFailed().type],    UserSagas.updateNotificationSettingsFailed),
        takeLatest([UserActions.updateNotificationSettingsSucceeded().type], UserSagas.updateNotificationSettingsSucceeded),
        takeLatest([UserActions.terminatePaidPlan().type],                   UserSagas.terminatePaidPlan),
        takeLatest([UserActions.terminatePaidPlanConfirmed().type],          UserSagas.terminatePaidPlanConfirmed),
        takeLatest([UserActions.terminatePaidPlanConfirmedSucceeded().type], UserSagas.terminatePaidPlanSucceeded),
        takeLatest([UserActions.terminatePaidPlanConfirmedFailed().type],    UserSagas.terminatePaidPlanFailed),
        // @formatter:on
    ];
};

const callLoadingSagas = () => {
    return [
        // @formatter:off
        takeLatest([LoadingActions.overlayClicked().type], LoadingSagas.overlayClicked),
        // @formatter:on
    ];
};

const callNavigationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.redirect().type], NavigationSagas.redirect),
        // @formatter:on
    ];
};

const callGeneratePressArticleSage = () => {
    return [
        // @formatter:off
        takeLatest([GeneratePressArticleActions.generatePressArticle], GeneratePressArticleSagas.generatePressArticle),
        // @formatter:on
    ];
};

const callLocationChangeSagas = () => {
    return [
        // @formatter:off
        takeLatest([LOCATION_CHANGE], NavigationSagas.locationChange),
        takeLatest([LOCATION_CHANGE], LoadingSagas.resetClickCount),
        // @formatter:on
    ];
};

const callWindowSagas = () => {
    return [
        // @formatter:off
        takeLatest([WindowActions.windowBecameVisible().type], WindowSagas.windowBecameVisible),
        takeLatest([WindowActions.windowGotFocus().type],      WindowSagas.windowGotFocus),
        // @formatter:on
    ];
};

const callPressArticleSagas = () => {
    return [
        // @formatter:off
        takeLatest([PressArticleActions.updateSearch().type],                PressArticleSagas.updateSearch),
        takeLatest([PressArticleActions.fetchPressArticles().type],          PressArticleSagas.fetchPressArticles),
        takeLatest([PressArticleActions.fetchPressArticlesFailed().type],    PressArticleSagas.fetchPressArticlesFailed),
        takeLatest([PressArticleActions.fetchPressArticlesSucceeded().type], PressArticleSagas.fetchPressArticlesSucceeded),
        takeLatest([PressArticleActions.fetchSinglePressArticle().type],     PressArticleSagas.fetchSinglePressArticle),
        // @formatter:on
    ];
};

const callCreatePressArticleSagas = () => {
    return [
        // @formatter:off
        takeLatest([CreatePressArticleActions.createPressArticle().type],          CreatePressArticleSagas.createPressArticle),
        takeLatest([CreatePressArticleActions.openEmptyCreatePressArticle().type], CreatePressArticleSagas.openEmptyCreatePressArticle),
        takeLatest([CreatePressArticleActions.setStep().type],                     CreatePressArticleSagas.setStep),
        // @formatter:on
    ];
};

const callEditPressArticleSagas = () => {
    return [
        // @formatter:off
        takeLatest([EditPressArticleActions.editPressArticle().type],           EditPressArticleSagas.editPressArticle),
        takeLatest([EditPressArticleActions.save().type],                       EditPressArticleSagas.save),
        takeLatest([EditPressArticleActions.saveFailed().type],                 EditPressArticleSagas.saveFailed),
        takeLatest([EditPressArticleActions.saveSucceeded().type],              EditPressArticleSagas.saveSucceeded),
        takeLatest([EditPressArticleActions.delete().type],                     EditPressArticleSagas.deletePressArticle),
        takeLatest([EditPressArticleActions.deleteFailed().type],               EditPressArticleSagas.deletePressArticleFailed),
        takeLatest([EditPressArticleActions.deleteSucceeded().type],            EditPressArticleSagas.deletePressArticleSucceeded),
        takeLatest([EditPressArticleActions.openCreateDialogToEditData().type], EditPressArticleSagas.openCreateDialogToEditData),
        // @formatter:on
    ];
};

const callRehydrateSagas = () => {
    return [
        // @formatter:off
        takeLatest([REHYDRATE], LoadingSagas.rehydrate),
        takeLatest([REHYDRATE], PreLoadSagas.preLoadData),
        // @formatter:on
    ];
};

function* root() {
    yield all([
        ...callAlertBoxSagas(),
        ...callConfigurationSagas(),
        ...callCreatePressArticleSagas(),
        ...callEditPressArticleSagas(),
        ...callExternalUnitsSagas(),
        ...callLoadingSagas(),
        ...callLocationChangeSagas(),
        ...callNavigationSagas(),
        ...callOwnUnitsSagas(),
        ...callOwnVehiclesSagas(),
        ...callRehydrateSagas(),
        ...callPressArticleSagas(),
        ...callOwnParticularitiesSagas(),
        ...callSmartUpdaterSagas(),
        ...callStagingAuthenticationSagas(),
        ...callUserSagas(),
        ...callWindowSagas(),
        ...callGeneratePressArticleSage(),
    ]);
}

export default {
    root,
};
