//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import I18n            from 'i18next';
import update          from 'immutability-helper';
import _               from 'lodash';

import LoadingLevelHelper     from '@store/helper/LoadingLevelHelper';
import selectEditPressArticle from '@store/selectors/editPressArticle';

const initialState = Object.freeze({
    articleText:     null,
    articleHeadline: null,
});

const editPressArticleSlice          = createSlice({
    name:     'editPressArticle',
    initialState,
    reducers: {
        editPressArticle:           (state, action) => {
            return update(state, {
                $set: action.payload.pressArticle,
            });
        },
        setChangedText:             (state, action) => {
            const { payload }        = action;
            const { text, headline } = payload;

            return update(state, {
                articleText: {
                    $set: text,
                },

                articleHeadline: {
                    $set: headline,
                },
            });
        },
        save:                       LoadingLevelHelper.increaseLoadingEmptyReducer((state) => {
            const pressArticle = selectEditPressArticle(state);
            const iri          = _.get(pressArticle, 'iri', null);

            return (
                iri ?
                    I18n.t('loadingText.updatePressArticle') :
                    I18n.t('loadingText.savePressArticle')
            );
        }),
        saveFailed:                 LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        saveSucceeded:              LoadingLevelHelper.decreaseLoading((state, action) => {
            const { payload } = action;

            return update(state, {
                $set: payload.pressArticle,
            });
        }),
        delete:                     LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loadingText.deletePressArticle')),
        deleteFailed:               LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        deleteSucceeded:            LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        openCreateDialogToEditData: (state, action) => {
            const pressArticle = _.get(action, 'payload.pressArticle', null);

            if (!pressArticle) {
                return state;
            }

            return update(state, {
                $set: action.payload.pressArticle,
            });
        },
    },
});
export const EditPressArticleActions = editPressArticleSlice.actions;

export const EditPressArticleReducer = editPressArticleSlice.reducer;

export default editPressArticleSlice;
