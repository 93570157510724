//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put } from 'redux-saga/effects';

import SagaStateHelper            from '@helper/SagaStateHelper';
import { ConfigurationActions }   from '@slices/configuration';
import { ExternalUnitsActions }   from '@slices/externalUnits';
import { ParticularitiesActions } from '@slices/particularities';
import { PressArticleActions }    from '@slices/pressArticle';
import { UnitsActions }           from '@slices/units';
import { UserActions }            from '@slices/user';
import { VehiclesActions }        from '@slices/vehicles';

function* preLoadData() {
    yield put(ConfigurationActions.fetchConfiguration());
    yield put(UserActions.tryRestoreToken());
    yield put(UnitsActions.resetUnits());
    yield put(ParticularitiesActions.resetParticularities());
    yield put(VehiclesActions.resetVehicles());
    yield put(ExternalUnitsActions.resetUnits());
    yield put(UnitsActions.fetchUnits());
    yield put(ParticularitiesActions.fetchParticularities());
    yield put(VehiclesActions.fetchVehicles());
    yield put(ExternalUnitsActions.fetchUnits());

    const token = yield SagaStateHelper.selectFromState('user', 'token');

    if (token) {
        yield put(PressArticleActions.fetchPressArticles());
        yield put(UserActions.fetchUser());
    }
}

export default {
    preLoadData,
};
