//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import 'core-js';
import '@language/Language';
import 'moment/locale/de';

import 'swiper/css';
import 'swiper/css/pagination';

import { ConnectedRouter } from 'connected-react-router';
import moment              from 'moment';
import { createRoot }      from 'react-dom/client';
import { Provider }        from 'react-redux';
import { PersistGate }     from 'redux-persist/integration/react';

import LoadingOverlay      from '@connected/LoadingOverlay';
import MainRouter          from '@connected/MainRouter';
import StagingLoginOverlay from '@connected/StagingLoginOverlay';
import ImageStorage        from '@helper/ImageStorage';
import Language            from '@helper/Language';
import CacheInvalidator    from '@stateless/atomic/CacheInvalidator';
import { history }         from '@store';
import createStore         from '@store';

import * as serviceWorker from './serviceWorker';

import '@external/sentry';
import '@store/miscellaneous/immutability-helper-extensions';
import '@helper/LodashExtensions';

ImageStorage.initializeStore();

moment.locale(Language.getDefaultLanguageShort());

const { store, persistor } = createStore();
const container            = document.getElementById('root');
const root                 = createRoot(container);

root.render((
    <Provider store={store}>
        <PersistGate
            persistor={persistor}
            loading={null}
        >
            <CacheInvalidator />
            {/*
                If you don't want to automatically clear the browsers cache and update the
                application you can switch <CacheInvalidator /> by <CacheInvalidatorWithPrompt />
                to ask the user if he wants to update.
            */}
            <StagingLoginOverlay />
            <LoadingOverlay />
            <ConnectedRouter history={history}>
                <MainRouter />
            </ConnectedRouter>
        </PersistGate>
    </Provider>
));

serviceWorker.register();
