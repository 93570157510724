//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ReactDOMServer from 'react-dom/server';
import svgToDataURL   from 'svg-to-dataurl';

class SvgToDataUrl {
    /**
     * Converts a svg react element to a data url
     * @param reactElement
     * @returns {string|null}
     */
    static convert(reactElement) {
        if (!reactElement) {
            return null;
        }

        const staticRenderValue = ReactDOMServer.renderToStaticMarkup((
            reactElement
        ));

        return svgToDataURL(staticRenderValue);
    }
}

export default SvgToDataUrl;
