//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import Headline          from '@stateless/atomic/Headline';
import HeadlineType      from '@stateless/atomic/Headline/HeadlineType';
import ScreenWithSidebar from '@stateless/composed/ScreenWithSidebar';

const ExternalTaskForces = () => {
    return (
        <ScreenWithSidebar>
            <Headline
                title={'ExternalTaskForces'}
                type={HeadlineType.headline1}
            />
        </ScreenWithSidebar>
    );
};

export default ExternalTaskForces;
