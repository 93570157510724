//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { SwiperSlide } from 'swiper/react';

import Api               from '@constants/Api';
import PaymentMethod     from '@constants/PaymentMethod';
import Environment       from '@helper/Environment';
import Translator        from '@helper/Translator';
import PlanInfoItems     from '@screens/InvoicesAndPlans/PlanInfoItems';
import { UserActions }   from '@slices/user';
import PlanDisplayItem   from '@stateless/atomic/PlanDisplayItem';
import PlanSwiper        from '@stateless/atomic/PlanSwiper';
import TextSwitch        from '@stateless/atomic/TextSwitch';
import ScreenWithSidebar from '@stateless/composed/ScreenWithSidebar';
import selectToken       from '@store/selectors/user';

import styles from './styles.module.scss';

const InvoicesAndPlansScreen = () => {
    const dispatch                                          = useDispatch();
    const userToken                                         = useSelector(selectToken);
    const translator                                        = new Translator('components.invoicesAndPlans');
    const monthlyPaymentItem                                = {
        text:  translator.t('monthlyPayment'),
        value: PaymentMethod.MONTHLY,

    };
    const yearlyPaymentItem                                 = {
        text:  translator.t('yearlyPayment'),
        value: PaymentMethod.YEARLY,
        label: translator.t('yearlyDiscountText'),
    };
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentMethod.MONTHLY);
    const managementItems                                   = [
        {
            headline: translator.t('currentPlan'),
            ctas:     [
                {
                    text: translator.t('changePlan'),
                    link: translator.t('changePlanUrl'),
                },
            ],
        },
        {
            headline: translator.t('paymentInformation'),
            ctas:     [
                {
                    text: translator.t('editPaymentInformation'),
                    link: translator.t('editPaymentInformationUrl'),
                },
            ],
        },
        {
            headline: translator.t('invoiceInformation'),
            ctas:     [
                {
                    text: translator.t('editInvoiceInformation'),
                    link: translator.t('editInvoiceInformationUrl'),
                },
                {
                    text: translator.t('showInvoices'),
                    link: translator.t('showInvoicesUrl'),
                },
            ],
        },
        {
            headline: translator.t('quitAbo'),
            ctas:     [
                {
                    text: translator.t('quitAboHere'),
                    link: translator.t('quitAboHereUrl'),
                },
            ],
        },
    ];

    function onPaymentMethodChange(paymentMethod) {
        setSelectedPaymentMethod(paymentMethod);
    }

    function onPlanTerminationClicked() {
        dispatch(UserActions.terminatePaidPlan());
    }

    function renderPlanInfoItems() {
        return _.map(PlanInfoItems, (plan) => {
            return (
                <SwiperSlide
                    key={plan.name}
                >
                    <PlanDisplayItem
                        onPlanTerminationClicked={onPlanTerminationClicked}
                        planItem={plan}
                        userToken={userToken}
                        paymentMethod={selectedPaymentMethod}
                    />
                </SwiperSlide>
            );
        });
    }

    const onManagementItemClicked = (managementUrl) => () => {
        let url;

        if (Environment.isProduction()) {
            const backendUrl = Api.getBackendUrl();
            url              = `${backendUrl}/connector?token=${userToken}&redirect=${managementUrl}`;
        } else {
            url = `${I18n.t('wordpressUrl')}${managementUrl}`;
        }

        window.open(url, '_blank', 'noopener,noreferrer');
    };

    function renderManagementItems() {
        function renderCtas(ctas) {
            return _.map(ctas, (cta) => {
                return (
                    <button
                        className={styles.ctaItem}
                        onClick={onManagementItemClicked(cta.link)}
                    >
                        {cta.text}
                    </button>
                );
            });
        }

        return _.map(managementItems, (managementItem) => {
            return (
                <div className={styles.managementItem}>
                    <h3 className={styles.title}>
                        {managementItem.headline}
                    </h3>
                    {renderCtas(managementItem.ctas)}
                </div>
            );
        });
    }

    function renderPlanSwiper() {
        return (
            // wrapper div is required for swiper to work
            <div>
                <PlanSwiper>
                    {renderPlanInfoItems()}
                </PlanSwiper>
            </div>
        );
    }

    return (
        <ScreenWithSidebar>
            <div className={styles.invoicesAndPlansScreen}>
                <div className={styles.headerRow}>
                    <h2>
                        {translator.t('plansAndInvoices')}
                    </h2>
                    <TextSwitch
                        leftItem={monthlyPaymentItem}
                        rightItem={yearlyPaymentItem}
                        selectedValue={selectedPaymentMethod}
                        onSelectionChange={onPaymentMethodChange}
                    />
                </div>
                {renderPlanSwiper()}
                <p className={styles.vatLabel}>
                    {translator.t('pricesInclusiveVat')}
                </p>
                <div className={styles.headerRow}>
                    <h2>
                        {translator.t('aboAndPayment')}
                    </h2>
                </div>
                <div className={styles.managementContainer}>
                    {renderManagementItems()}
                </div>
            </div>
        </ScreenWithSidebar>
    );
};

export default InvoicesAndPlansScreen;
