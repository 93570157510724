//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import Routes from '@constants/Routes';

class Route {
    static buildPath(route, sourceParameters = {}) {
        const to         = this.buildRoute(route, sourceParameters);
        let pathname     = _.get(to, 'route', '');
        const parameters = _.get(to, 'parameters', {});
        pathname         = this.replaceParametersInUrl(pathname, parameters);

        return {
            pathname,
        };
    }

    static buildRoute(route, ...parameters) {
        const routeParameters    = this.getParametersFromRoute(route);
        const routeParameterKeys = Object.keys(routeParameters);

        parameters.forEach((parameter, index) => {
            const routeParameterKey            = routeParameterKeys[index];
            routeParameters[routeParameterKey] = parameter[routeParameterKey];
        });

        if (_.isEmpty(parameters)) {
            routeParameterKeys.forEach((parameter) => {
                routeParameters[parameter] = null;
            });
        }

        return {
            route,
            parameters: routeParameters,
        };
    }

    static getParametersFromRoute(route, matchingRoute = route) {
        const results = {};
        const matches = matchingRoute.match(/:([\w-]+)/g);

        matches.forEach((match) => {
            const index                 = matchingRoute.indexOf(match);
            const preParameter          = matchingRoute.slice(0, index);
            const postParameter         = matchingRoute.slice(index + match.length);
            results[match.substring(1)] = route.replace(preParameter, '').replace(postParameter, '');
        });

        return _.cloneDeep(results);
    }

    static getQueryParametersFromCurrentRoute(...params) {
        const currentRoute = window.location.search;
        const urlParams    = new URLSearchParams(currentRoute);
        const results      = {};

        for (const param of params) {
            results[param] = urlParams.get(param);
        }

        return results;
    }

    static replaceParametersInUrl(url, parameters) {
        const keys      = _.keys(parameters).join('|');
        let replacedUrl = url;

        // Replace optional path parameter values
        _.forEach(parameters, (value, key) => {
            if (_.isNil(value)) {
                replacedUrl = url.replace(`:${key}?`, '');
            } else {
                replacedUrl = url.replace(`:${key}?`, parameters[key]);
            }
        });

        const regExp = new RegExp(`:(${keys})`, 'g');
        replacedUrl  = replacedUrl.replace(
            regExp,
            (matched) => (
                parameters[matched.replace(':', '')] ||
                matched
            ),
        );

        return replacedUrl;
    }

    static getRouteFromPath(path, capitalize = false) {
        let route = Object.keys(Routes).find((key) => Routes[key] === path);

        if (!route) {
            route = 'notFound';
        }

        if (capitalize) {
            return route.charAt(0).toUpperCase() + route.slice(1);
        }

        return route;
    }
}

export default Route;
