//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n   from 'i18next';
import moment from 'moment';

class DateTime {
    static getCurrentTime = () => {
        const now         = new Date();
        const currentTime = now.getTime();

        return currentTime;
    };

    static getCurrentTimeInSeconds = () => {
        return DateTime.getCurrentTime() / 1000;
    };

    static getDaysInMilliseconds = (days) => {
        return DateTime.getHoursInMilliseconds(days * 24);
    };

    static getHoursInMilliseconds = (hours) => {
        return DateTime.getMinutesInMilliseconds(hours * 60);
    };

    static getMinutesInMilliseconds = (minutes) => {
        return DateTime.getSecondsInMilliseconds(minutes * 60);
    };

    static getSecondsInMilliseconds = (seconds) => {
        return seconds * 1000;
    };

    static getTimeString = (dateTime, fallbackText) => {
        const messageDate = moment(dateTime);

        if (messageDate.isValid()) {
            const today           = moment();
            const timeStringParts = [];

            if (messageDate.isSame(today, 'day')) {
                timeStringParts.push(I18n.t('today'));
            } else {
                const yesterday = moment().subtract(1, 'day');

                if (messageDate.isSame(yesterday, 'day')) {
                    timeStringParts.push(I18n.t('yesterday'));
                } else {
                    timeStringParts.push(messageDate.format(I18n.t('messageDateFormat')));
                }
            }

            timeStringParts.push(messageDate.format(I18n.t('messageTimeFormat')));
            timeStringParts.push(I18n.t('oclock'));

            const timeString = timeStringParts.join(' ');

            return timeString;
        }

        return fallbackText;
    };

    static formatDateString = (newDate, newTime) => {
        const newYear    = newDate.year;
        const newMonth   = newDate.month.toString().padStart(2, 0);
        const newDay     = newDate.day.toString().padStart(2, 0);
        const newHours   = newTime.hours.toString().padStart(2, 0);
        const newMinutes = newTime.minutes.toString().padStart(2, 0);

        return `${newYear}-${newMonth}-${newDay}T${newHours}:${newMinutes}`;
    };

    static getNowAsFormattedDateString = () => {
        const now  = new Date();
        const date = {
            year:  now.getFullYear(),
            month: now.getMonth() + 1,
            day:   now.getDate(),
        };
        const time = {
            hours:   now.getHours(),
            minutes: now.getMinutes(),
        };

        return DateTime.formatDateString(date, time);
    };
}

export default DateTime;
