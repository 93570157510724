//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';

import DefaultTypes      from '@components/DefaultTypes';
import PropTypes         from '@components/PropTypes';
import Icon              from '@stateless/atomic/Icon';
import IconType          from '@stateless/atomic/Icon/IconType';
import ImageWithFallback from '@stateless/atomic/ImageWithFallback';
import IconButton        from '@stateless/composed/IconButton';

import styles from './styles.module.scss';

const propTypes = {
    isLoading:             PropTypes.bool,
    loadingOverlayClicked: PropTypes.func,
    text:                  PropTypes.string,
};

const LoadingOverlay = ({
    isLoading = false,
    loadingOverlayClicked = DefaultTypes.func,
    text = '',
}) => {
    return (
        <div
            className={classNames(
                styles.loadingOverlay,
                {
                    [styles.loadingOverlayVisible]: isLoading,
                },
            )}
        >
            <div className={styles.loadingOverlayContent}>
                <div className={styles.loadingOverlayContentTitle}>
                    {I18n.t('components.loadingOverlay.title')}
                </div>
                <div className={styles.loadingOverlayContentText}>
                    {text}
                </div>
                <div className={styles.loadingOverlayContentFirefighter}>
                    <ImageWithFallback
                        alt={'components.loadingOverlay.alternativeImageText'}
                    />
                </div>
                <div className={styles.loadingOverlayContentLoadingWrapper}>
                    <div className={styles.loadingOverlayContentLoading}>
                        <Icon
                            iconType={IconType.wait}
                        />
                    </div>
                </div>
                <div className={styles.loadingOverlayContentCloseButton}>
                    <IconButton
                        iconType={IconType.closeWindow}
                        onClick={loadingOverlayClicked}
                    />
                </div>
            </div>
        </div>
    );
};

LoadingOverlay.propTypes = propTypes;

export default LoadingOverlay;
