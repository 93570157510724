//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';
import { useRef }    from 'react';

import classNames from 'classnames';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';
import IconType     from '@stateless/atomic/Icon/IconType';
import IconButton   from '@stateless/composed/IconButton';

import styles from './styles.module.scss';

const propTypes = {
    blue:              PropTypes.bool,
    children:          PropTypes.children,
    mobileTransparent: PropTypes.bool,
    onCloseClick:      PropTypes.func,
    open:              PropTypes.bool,
    small:             PropTypes.bool,
    title:             PropTypes.string,
};

const Overlay = ({
    blue = false,
    children = null,
    mobileTransparent = false,
    onCloseClick = DefaultTypes.func,
    open = false,
    small = false,
    title = null,
}) => {
    const overlayReference = useRef();

    useEffect(() => {
        if (open) {
            overlayReference.current.focus();
        }
    }, [open]);

    return (
        <div
            ref={overlayReference}
            tabIndex={0}
            className={classNames(
                styles.overlay,
                {
                    [styles.overlayHidden]:            !open,
                    [styles.overlayMobileTransparent]: mobileTransparent,
                    [styles.overlayContentSmall]:      small,
                },
            )}
        >
            <div
                className={classNames(
                    styles.overlayContent,
                    {
                        [styles.overlayContentBlue]: blue,
                    },
                )}
            >
                <div
                    className={styles.overlayContentInner}
                >
                    <div className={styles.overlayContentTitle}>
                        {title}
                        <div className={styles.overlayContentCloseButton}>
                            <IconButton
                                iconType={IconType.closeWindow}
                                onClick={onCloseClick}
                            />
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

Overlay.propTypes = propTypes;

export default Overlay;
