//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import { current }     from '@reduxjs/toolkit';
import I18n            from 'i18next';
import update          from 'immutability-helper';
import _               from 'lodash';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    pressArticles: [],
    search:        null,
});

const pressArticleSlice = createSlice({
    name:     'pressArticle',
    initialState,
    reducers: {
        fetchPressArticles:               LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loadingText.fetchPressArticles')),
        fetchPressArticlesFailed:         LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchPressArticlesSucceeded:      LoadingLevelHelper.decreaseLoading((state, action) => {
            const {
                pressArticles,
            } = action.payload;

            return update(state, {
                pressArticles: {
                    $set: pressArticles,
                },
            });
        }),
        fetchSinglePressArticle:          (state, action) => {
        },
        fetchSinglePressArticleSucceeded: (state, action) => {
            const { pressArticle }     = action.payload;
            const stateList            = _.get(current(state), 'pressArticles');
            const previousPressArticle = _.find(
                stateList,
                (existingPressArticle) => existingPressArticle.id === pressArticle.id,
            );
            const index                = _.indexOf(
                stateList,
                previousPressArticle,
            );

            return update(state, {
                pressArticles: {
                    [index]: {
                        $set: pressArticle,
                    },
                },
            });
        },
        updateSearch:                     (state, action) => {
            const {
                search,
            } = action.payload;

            return update(state, {
                search: {
                    $set: search,
                },
            });
        },
    },
});

export const PressArticleActions = pressArticleSlice.actions;

export const PressArticleReducer = pressArticleSlice.reducer;

export default pressArticleSlice;
