//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';
import SideMenu  from '@stateless/composed/SideMenu';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.children,
};

const ScreenWithSidebar = ({
    children = null,
}) => {
    return (
        <div className={styles.screen}>
            <SideMenu />
            <div className={styles.contentWrapper}>
                {children}
            </div>
        </div>
    );
};

ScreenWithSidebar.propTypes = propTypes;

export default ScreenWithSidebar;
