//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import I18n from 'i18next';

import Headline      from '@stateless/atomic/Headline';
import HeadlineColor from '@stateless/atomic/Headline/HeadlineColor';
import HeadlineType  from '@stateless/atomic/Headline/HeadlineType';
import Icon          from '@stateless/atomic/Icon';
import IconType      from '@stateless/atomic/Icon/IconType';
import IconButton    from '@stateless/composed/IconButton';

import styles from './styles.module.scss';

const propTypes = {};

const ExplanationCard = () => {
    const [open, setOpen]          = useState(true);
    const translationBaseKey       = 'components.explanationCard.';
    const explanationVideoEmbedUrl = 'https://www.youtube.com/embed/AIhAxFcTVFo?si=i27HebnyNqDPIfxR';

    function onCloseClick() {
        setOpen(false);
    }

    if (open) {
        return (
            <div className={styles.explanationCardWrapper}>
                <div className={styles.explanationCardCloseButtonWrapper}>
                    <IconButton
                        iconType={IconType.closeWindow}
                        size={20}
                        onClick={onCloseClick}
                    />
                </div>
                <div className={styles.explanationCardContent}>
                    <Headline
                        title={I18n.t(`${translationBaseKey}explanationCardTitle`)}
                        type={HeadlineType.headline2}
                        color={HeadlineColor.white}
                    />
                    <p>
                        {I18n.t(`${translationBaseKey}explanationCardText`)}
                    </p>
                    <div className={styles.explanationCardVideoWrapper}>
                        <iframe
                            width="100%"
                            height="100%"
                            src={explanationVideoEmbedUrl}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                    </div>
                </div>
                <div className={styles.explanationCardIconWrapper}>
                    <Icon iconType={IconType.infoVideo} />
                </div>
            </div>
        );
    }

    return null;
};

ExplanationCard.propTypes = propTypes;

export default ExplanationCard;
