//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                 from '@components/PropTypes';
import OverlayConstants          from '@constants/Overlay';
import { OverlayActions }        from '@slices/overlay';
import { VehiclesActions }       from '@slices/vehicles';
import StateLessEditUnitsOverlay from '@stateless/composed/EditUnitsOverlay';
import VehicleListItem           from '@stateless/composed/VehicleListItem';
import { selectVehicles }        from '@store/selectors/vehicles';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const EditVehiclesOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch      = useDispatch();
    const vehicles      = useSelector(selectVehicles);
    const numberOfUnits = vehicles.length;
    const showHint      = vehicles.length === 0;

    function setVehicleInEdit(vehicle) {
        dispatch(VehiclesActions.setVehicleInEdit(vehicle));
    }

    function onAddVehicleClicked() {
        dispatch(OverlayActions.openOverlay({
            overlay: OverlayConstants.editVehicle,
        }));
    }

    function onEditVehicleClicked(vehicle) {
        return () => {
            dispatch(VehiclesActions.setVehicleInEdit(vehicle));
            onAddVehicleClicked();
        };
    }

    function onDeleteVehicleClicked(id) {
        return () => {
            dispatch(VehiclesActions.setVehicleInEdit({
                id,
            }));
            dispatch(OverlayActions.openOverlay({
                overlay: OverlayConstants.deleteVehicle,
            }));
        };
    }

    function renderVehicles() {
        return vehicles.map((vehicle) => {
            const { id, name, description } = vehicle;

            return (
                <VehicleListItem
                    key={`own-vehicle-${id}`}
                    title={name}
                    subtitle={description}
                    onDeleteClick={onDeleteVehicleClicked(id)}
                    onEditClick={onEditVehicleClicked({
                        id,
                        name,
                        description,
                    })}
                />
            );
        });
    }

    return (
        <StateLessEditUnitsOverlay
            onCloseClick={onCloseClick}
            open={open}
            numberOfUnits={numberOfUnits}
            renderUnits={renderVehicles}
            onAddUnitClicked={onAddVehicleClicked}
            setUnitInEdit={setVehicleInEdit}
            buttonText={I18n.t('components.editOwnUnitOverlay.addVehicle')}
            showHint={showHint}
            hintText={I18n.t('components.editUnitsOverlay.addVehicleHint')}
        />
    );
};

EditVehiclesOverlay.propTypes = propTypes;

export default EditVehiclesOverlay;
