//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _        from 'lodash';
import { put }  from 'redux-saga/effects';
import { call } from 'redux-saga/effects';

import * as Api                     from '@api/index';
import SagaStateHelper              from '@helper/SagaStateHelper';
import { PressArticleActions }      from '@slices/pressArticle';
import { selectPressArticleSearch } from '@store/selectors/pressArticle';

function* fetchPressArticles() {
    const search     = yield SagaStateHelper.selectFromStateBySelector(selectPressArticleSearch);
    const parameters = (
        search &&
        search.length > 0 ?
            {
                'or[articleText,articleHeadline]': search,
            } :
            null
    );
    const response   = yield call(
        Api.context.pressArticle.fetchPressArticles,
        parameters,
    );

    if (response.ok) {
        const pressArticles = _.get(response, 'data.member', []);

        yield put(PressArticleActions.fetchPressArticlesSucceeded({
            pressArticles,
        }));
    } else {
        yield put(PressArticleActions.fetchPressArticlesFailed());
    }
}

function* fetchPressArticlesFailed(action) {
    console.log('fetchPressArticlesFailed: Failed to fetch press articles');
}

function* fetchPressArticlesSucceeded(action) {
    console.log('fetchPressArticlesSucceeded: Successfully fetched press articles');
}

function* updateSearch() {
    yield put(PressArticleActions.fetchPressArticles());
}

function* fetchSinglePressArticle(action) {
    const iri      = _.get(action, 'payload.iri');
    const response = yield call(
        Api.context.pressArticle.fetchPressArticle,
        iri,
    );

    if (response.ok) {
        const pressArticle = _.get(response, 'data');

        yield put(PressArticleActions.fetchSinglePressArticleSucceeded({
            pressArticle,
        }));
    }
}

export default {
    fetchPressArticles,
    fetchPressArticlesFailed,
    fetchPressArticlesSucceeded,
    fetchSinglePressArticle,
    updateSearch,
};
