//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiUrls from '@constants/ApiUrls';

const userApi = (api) => {
    return {
        login:               (
            email,
            password,
        ) => {
            return api.post(
                ApiUrls.API_TOKENS,
                {
                    email,
                    password,
                },
            );
        },
        fetchUser:           (iri) => {
            return api.get(iri);
        },
        removeToken:         () => {
            /* eslint-disable no-param-reassign */
            /* Not possible to disable this here since we actually have to remove this in the api object */
            delete api.headers.Authorization;
        },
        setToken:            (token) => {
            console.log('API: setToken', token);

            if (token) {
                const authorization = `Bearer ${token}`;

                api.setHeader('Authorization', authorization);
            } else {
                userApi(api).removeToken();
            }
        },
        updatePassword:      (iri, newPassword, oldPassword) => {
            return api.put(ApiUrls.API_USER_UPDATE_PASSWORD(iri), {
                newPassword,
                oldPassword,
            });
        },
        update:              (iri, data) => {
            return api.put(iri, data);
        },
        activateTestAccount: (iri, hash, password) => {
            return api.get(ApiUrls.API_USER_ACTIVATE_TESTACCOUNT(iri), {
                hash,
                password,
            });
        },
        registerTestAccount: (firstname, lastname, email, password) => {
            return api.get(ApiUrls.API_REGISTER_TESTACCOUNT, {
                firstname,
                lastname,
                email,
                password,
            });
        },
        resetPassword:       (email) => {
            return api.post(ApiUrls.API_USER_RESET_PASSWORD, {
                email,
            });
        },
        setNewPassword:      (token, password) => {
            return api.post(ApiUrls.API_USER_SET_NEW_PASSWORD, {
                token,
                password,
            });
        },
        terminatePlan:       () => {
            return api.post(ApiUrls.API_PLAN_TERMINATE);
        },
    };
};

export default (api) => userApi(api);
