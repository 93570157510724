//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import moment          from 'moment';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Api                           from '@constants/Api';
import Overlay                       from '@constants/Overlay';
import Pages                         from '@constants/Pages';
import PlanUnit                      from '@constants/PlanUnit';
import Environment                   from '@helper/Environment';
import PressArticleStatusHelper      from '@helper/PressArticleStatusHelper';
import ProfileHelper                 from '@helper/Profile';
import RouteHelper                   from '@helper/Route';
import Url                           from '@helper/Url';
import { CreatePressArticleActions } from '@slices/createPressArticle';
import { EditPressArticleActions }   from '@slices/editPressArticle';
import { OverlayActions }            from '@slices/overlay';
import { PressArticleActions }       from '@slices/pressArticle';
import AdBanner                      from '@stateless/atomic/AdBanner';
import AdBannerSlot                  from '@stateless/atomic/AdBanner/AdBannerSlot';
import ExplanationCard               from '@stateless/atomic/ExplanationCard';
import Headline                      from '@stateless/atomic/Headline';
import HeadlineColor                 from '@stateless/atomic/Headline/HeadlineColor';
import HeadlineType                  from '@stateless/atomic/Headline/HeadlineType';
import Icon                          from '@stateless/atomic/Icon';
import IconType                      from '@stateless/atomic/Icon/IconType';
import IconTip                       from '@stateless/atomic/IconTip';
import PlanStatus                    from '@stateless/atomic/PlanStatus';
import TextInput                     from '@stateless/atomic/TextInput';
import ArticleListItem               from '@stateless/composed/ArticleListItem';
import DashboardButton               from '@stateless/composed/DashboardButton';
import DashboardButtonFlow           from '@stateless/composed/DashboardButton/DashboardButtonFlow';
import DashboardButtonTheme          from '@stateless/composed/DashboardButton/DashboardButtonTheme';
import ScreenWithSidebar             from '@stateless/composed/ScreenWithSidebar';
import selectPressArticles           from '@store/selectors/pressArticle';
import { selectPressArticleSearch }  from '@store/selectors/pressArticle';
import { selectCustomerPlan }        from '@store/selectors/user';
import { selectPlan }                from '@store/selectors/user';
import { selectUserProfile }         from '@store/selectors/user';
import selectToken                   from '@store/selectors/user';

import styles from './styles.module.scss';

const Home = () => {
    const dispatch                                            = useDispatch();
    const pressArticles                                       = useSelector(selectPressArticles);
    const userToken                                           = useSelector(selectToken);
    const customerPlan                                        = useSelector(selectCustomerPlan);
    const plan                                                = useSelector(selectPlan);
    const userProfile                                         = useSelector(selectUserProfile);
    const search                                              = useSelector(selectPressArticleSearch);
    const planUnit                                            = _.get(plan, 'planUnit', null);
    const customerPlanWordsLeft                               = _.get(customerPlan, 'wordsLeft', 0);
    const customerPlanArticlesLeft                            = _.get(customerPlan, 'articlesLeft', 0);
    const planWordCount                                       = _.get(plan, 'includedWordCount', 0);
    const planArticleCount                                    = _.get(plan, 'includedPressArticles', 0);
    const isTestAccount                                       = _.get(userProfile, 'testAccount');
    const planTitle                                           = _.get(plan, 'title');
    const name                                                = ProfileHelper.getFirstName(userProfile);
    const [showSocialMediaIconTip, setShowSocialMediaIconTip] = useState(false);
    const testAccountWordCount                                = 600;

    useEffect(
        () => {
            const { pressArticle: pressArticleId } = RouteHelper.getQueryParametersFromCurrentRoute('pressArticle');

            if (!pressArticleId) {
                return;
            }

            const targetPressArticle = _.find(pressArticles, (pressArticle) => {
                return _.includes(pressArticle.iri, pressArticleId);
            });

            if (!targetPressArticle) {
                return;
            }

            dispatch(EditPressArticleActions.editPressArticle({
                targetPressArticle,
            }));
        },
        [],
    );

    function onNewPressArticleOverlayClicked() {
        dispatch(CreatePressArticleActions.openEmptyCreatePressArticle());
    }

    function onEditPressArticleClicked(pressArticle) {
        return () => {
            if (PressArticleStatusHelper.isInEdit(pressArticle)) {
                dispatch(EditPressArticleActions.openCreateDialogToEditData({
                    pressArticle,
                }));

                return;
            }

            dispatch(EditPressArticleActions.editPressArticle({
                pressArticle,
            }));
        };
    }

    function onReloadPressArticleClicked(pressArticle) {
        return () => {
            const targetStatus   = PressArticleStatusHelper.getPendingStatusFromFailedStatus(pressArticle);
            const targetAttempts = 0;

            dispatch(CreatePressArticleActions.loadCreatePressArticle({
                pressArticle,
            }));
            dispatch(CreatePressArticleActions.createPressArticle({
                targetStatus,
                targetAttempts,
            }));
        };
    }

    function onDeletePressArticleClicked(pressArticle) {
        return () => {
            dispatch(EditPressArticleActions.editPressArticle({
                pressArticle,
                open: false,
            }));
            dispatch(OverlayActions.openOverlay({
                overlay: Overlay.deleteDraft,
            }));
        };
    }

    function onSearchChange(event) {
        const searchValue = _.get(event, 'target.value');

        dispatch(PressArticleActions.updateSearch({
            search: searchValue,
        }));
    }

    function getPlanWordCount() {
        if (isTestAccount) {
            return testAccountWordCount;
        }

        return planWordCount;
    }

    function getPlanTitle() {
        if (isTestAccount) {
            return I18n.t('components.yourPlan.plans.testaccount');
        }

        return planTitle;
    }

    function navigateTo(to) {
        return () => {
            dispatch(OverlayActions.closeOverlay());
            Url.openInNewTab(to);
        };
    }

    function renderExplanationCard() {
        return (
            <ExplanationCard />
        );
    }

    function renderPressArticle(pressArticle) {
        const articleHeadline      = _.get(pressArticle, 'articleHeadline');
        const articleText          = _.get(pressArticle, 'articleText');
        const status               = _.get(pressArticle, 'pressArticleStatus.articleStatus');
        const missionStartDateTime = _.get(pressArticle, 'missionDetail.missionStartDateTime');
        const formattedDate        = moment(missionStartDateTime).format(I18n.t('dateTimeFormat'));

        return (
            <ArticleListItem
                key={pressArticle.iri}
                title={articleHeadline}
                subtitle={formattedDate}
                text={articleText}
                status={status}
                onReloadClick={onReloadPressArticleClicked(pressArticle)}
                onEditClick={onEditPressArticleClicked(pressArticle)}
                onDeleteClick={onDeletePressArticleClicked(pressArticle)}
            />
        );
    }

    function renderPressArticles() {
        return _.map(pressArticles, renderPressArticle);
    }

    function renderArchiveContent() {
        if (
            _.isEmpty(pressArticles) &&
            !search
        ) {
            return (
                renderExplanationCard()
            );
        }

        return (
            <div className={styles.articleList}>
                {renderPressArticles()}
            </div>
        );
    }

    function getMaximum() {
        switch (planUnit) {
            case null:
            case PlanUnit.WORDS:
                return getPlanWordCount();
            case PlanUnit.ARTICLES:
                return planArticleCount;
            default:
                return 0;
        }
    }

    function getCurrent() {
        switch (planUnit) {
            case null:
            case PlanUnit.WORDS:
                return customerPlanWordsLeft;
            case PlanUnit.ARTICLES:
                return customerPlanArticlesLeft;
            default:
                return 0;
        }
    }

    function renderPlanStatus() {
        return (
            <PlanStatus
                minimum={0}
                maximum={getMaximum()}
                current={getCurrent()}
                plan={getPlanTitle()}
                planUnit={planUnit}
            />
        );
    }

    function onMouseEnterSocialMedia() {
        setShowSocialMediaIconTip(true);
    }

    function onMouseLeaveSocialMedia() {
        setShowSocialMediaIconTip(false);
    }

    let showSocialMediaTipTimeout = null;

    function onSocialMediaClick() {
        setShowSocialMediaIconTip(true);
        clearTimeout(showSocialMediaTipTimeout);
        showSocialMediaTipTimeout = setTimeout(() => {
            setShowSocialMediaIconTip(false);
        }, 2000);
    }

    function renderDashboard() {
        return (
            <>
                <Headline
                    title={I18n.t('hello')}
                    titlePostfix={`${name}!`}
                    type={HeadlineType.headline1}
                />
                <div className={styles.buttonsMobile}>
                    <DashboardButton
                        icons={[IconType.newEntry]}
                        titleKey={'components.dashboardButton.createPressRelease'}
                        onClick={onNewPressArticleOverlayClicked}
                        theme={DashboardButtonTheme.red}
                    />
                    {/* TODO: https://lulububu.atlassian.net/browse/FIREWRITER-97 */}
                    <div
                        className={styles.disabledButtonWrapper}
                        onClick={onSocialMediaClick}
                    >
                        <DashboardButton
                            icons={[IconType.instagram, IconType.facebook]}
                            titleKey={'components.dashboardButton.createSocialMediaPosting'}
                            disabled={true}
                        />
                        <IconTip
                            show={showSocialMediaIconTip}
                            text={I18n.t('disabledSocialMediaButtonText')}
                            iconType={IconType.constructionSite}
                        />
                    </div>
                </div>
                <div className={styles.buttonsDesktop}>
                    <DashboardButton
                        icons={[IconType.newEntry]}
                        titleKey={'components.dashboardButton.createPressRelease'}
                        onClick={onNewPressArticleOverlayClicked}
                        theme={DashboardButtonTheme.red}
                        flow={DashboardButtonFlow.row}
                    />
                    {/* TODO: https://lulububu.atlassian.net/browse/FIREWRITER-97 */}
                    <div
                        className={styles.disabledButtonWrapper}
                        onMouseEnter={onMouseEnterSocialMedia}
                        onMouseLeave={onMouseLeaveSocialMedia}
                    >
                        <DashboardButton
                            icons={[IconType.facebook, IconType.instagram]}
                            titleKey={'components.dashboardButton.createSocialMediaPosting'}
                            theme={DashboardButtonTheme.red}
                            flow={DashboardButtonFlow.row}
                            disabled={true}
                            buttonHoverText={I18n.t('disabledSocialMediaButtonText')}
                        />
                        <IconTip
                            show={showSocialMediaIconTip}
                            text={I18n.t('disabledSocialMediaButtonText')}
                            iconType={IconType.constructionSite}
                        />
                    </div>
                </div>
                <div className={styles.planStatusMobile}>
                    {renderPlanStatus()}
                </div>
                <AdBanner slot={AdBannerSlot.ARCHIVE_BANNER} />
                <div className={styles.homeArchive}>
                    <div className={styles.archiveTitle}>
                        <Headline
                            title={'Dein'}
                            titlePostfix={'Archiv'}
                            type={HeadlineType.headline1}
                        />
                        <TextInput
                            placeholderText={I18n.t('search')}
                            preIconType={IconType.search}
                            value={search}
                            onChange={onSearchChange}
                        />
                    </div>
                    {renderArchiveContent()}
                </div>
            </>
        );
    }

    function onLoginWooCommerceClicked() {
        if (Environment.isProduction()) {
            const backendUrl = Api.getBackendUrl();
            const url        = `${backendUrl}/connector?token=${userToken}&redirect=shop`;

            window.open(url, '_blank', 'noopener,noreferrer');
        }
    }

    function renderEmptyWordsScreen() {
        return (
            <div>
                <div className={styles.emptyWordsIconWrapper}>
                    <Icon
                        iconType={IconType.error}
                    />
                </div>
                <Headline
                    title={I18n.t('testAccountNoWordsLeftHeader')}
                    color={HeadlineColor.red}
                    type={HeadlineType.headline1}
                />
                <p>
                    {I18n.t('testAccountNoWordsLeftText')}
                </p>
                <div className={styles.emptyWordsButtonWrapper}>
                    <DashboardButton
                        icons={[IconType.basket]}
                        theme={DashboardButtonTheme.green}
                        titleKey={'testAccountNoWordsLeftButtonOrder'}
                        flow={DashboardButtonFlow.row}
                        onClick={onLoginWooCommerceClicked}

                    />
                    <DashboardButton
                        icons={[IconType.videocall]}
                        theme={DashboardButtonTheme.red}
                        titleKey={'testAccountNoWordsLeftButtonConsultation'}
                        subTextKey={'testAccountNoWordsLeftButtonConsultationSub'}
                        flow={DashboardButtonFlow.row}
                        onClick={navigateTo(Pages.consultation)}
                    />
                </div>
            </div>
        );
    }

    function renderContent() {
        if (
            isTestAccount &&
            customerPlanWordsLeft <= 50
        ) {
            return renderEmptyWordsScreen();
        }

        return renderDashboard();
    }

    return (
        <ScreenWithSidebar>
            <div className={styles.content}>
                {renderContent()}
            </div>
            <div className={styles.leftPanel}>
                <div className={styles.planStatus}>
                    {renderPlanStatus()}
                </div>
                <AdBanner slot={AdBannerSlot.HOME_RIGHT_BANNER} />
            </div>
        </ScreenWithSidebar>
    );
};

export default Home;
