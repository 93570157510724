//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { createRef } from 'react';

import I18n      from 'i18next';
import PropTypes from 'prop-types';

import DefaultTypes          from '@components/DefaultTypes';
import AlertBoxManager       from '@connected/AlertBoxManager';
import FileUploadHelper      from '@helper/FileUpload';
import Avatar                from '@stateless/atomic/Avatar';
import IconType              from '@stateless/atomic/Icon/IconType';
import Overlay               from '@stateless/atomic/Overlay';
import TextInput             from '@stateless/atomic/TextInput';
import TextInputType         from '@stateless/atomic/TextInput/TextInputType';
import ColorButton           from '@stateless/composed/ColorButton';
import ColorButtonColorTheme from '@stateless/composed/ColorButton/ColorButtonColorTheme';
import ColorButtonFontTheme  from '@stateless/composed/ColorButton/ColorButtonFontTheme';

import styles from './styles.module.scss';

const propTypes = {
    avatar:                 PropTypes.string,
    email:                  PropTypes.string,
    firstname:              PropTypes.string,
    lastname:               PropTypes.string,
    onCloseClick:           PropTypes.func,
    onEmailChanged:         PropTypes.func,
    onFirstnameChanged:     PropTypes.func,
    onLastnameChanged:      PropTypes.func,
    onLoadingFilesComplete: PropTypes.func,
    onSaveClicked:          PropTypes.func,
    open:                   PropTypes.bool,
};

const ProfileSettingsOverlay = ({
    avatar = '',
    email = '',
    firstname = '',
    lastname = '',
    onCloseClick = DefaultTypes.func,
    onEmailChanged = DefaultTypes.func,
    onFirstnameChanged = DefaultTypes.func,
    onLastnameChanged = DefaultTypes.func,
    onLoadingFilesComplete = DefaultTypes.func,
    onSaveClicked = DefaultTypes.func,
    open = false,
}) => {
    const inputReference   = createRef();
    const addFiles         = async (files) => {
        const addedFiles = await FileUploadHelper.addFiles(files);

        onLoadingFilesComplete(addedFiles);
    };
    const onImageUploaded  = async (event) => {
        const uploadedFiles = event.target.files;

        await addFiles(uploadedFiles);

        inputReference.current.value = null;
    };
    const onAddFileClicked = () => {
        inputReference.current.click();
    };

    return (
        <Overlay
            onCloseClick={onCloseClick}
            open={open}
            blue={true}
        >
            <div className={styles.profileSettingsOverlay}>
                <Avatar image={avatar} />
                <ColorButton
                    text={I18n.t('components.profileSettingsOverlay.uploadProfileImage')}
                    colorTheme={ColorButtonColorTheme.green}
                    fontTheme={ColorButtonFontTheme.middle}
                    iconTypeLeft={IconType.add}
                    onClick={onAddFileClicked}
                />
                <input
                    ref={inputReference}
                    className={styles.profileSettingsImageUpload}
                    type={'file'}
                    accept={'image/*'}
                    multiple={false}
                    onChange={onImageUploaded}
                />
                <p>
                    {I18n.t('components.profileSettingsOverlay.firstname')}
                </p>
                <TextInput
                    type={TextInputType.text}
                    value={firstname}
                    onChange={onFirstnameChanged}
                />
                <p>
                    {I18n.t('components.profileSettingsOverlay.lastname')}
                </p>
                <TextInput
                    type={TextInputType.text}
                    value={lastname}
                    onChange={onLastnameChanged}
                />
                <p>
                    {I18n.t('components.profileSettingsOverlay.eMail')}
                </p>
                <TextInput
                    type={TextInputType.text}
                    value={email}
                    onChange={onEmailChanged}
                />
                <div className={styles.alertBoxManagerWrapper}>
                    <AlertBoxManager />
                </div>
                <ColorButton
                    text={I18n.t('components.profileSettingsOverlay.update')}
                    colorTheme={ColorButtonColorTheme.green}
                    fontTheme={ColorButtonFontTheme.middle}
                    iconTypeLeft={IconType.sync}
                    onClick={onSaveClicked}
                />
            </div>
        </Overlay>
    );
};

ProfileSettingsOverlay.propTypes = propTypes;

export default ProfileSettingsOverlay;
