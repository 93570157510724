//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import IconAdd              from '@assets/icons/add.svg';
import IconAlarm            from '@assets/icons/alarm.svg';
import IconArrowUp          from '@assets/icons/arrowUp.svg';
import IconBasket           from '@assets/icons/basket.svg';
import IconBell             from '@assets/icons/bell.svg';
import IconBold             from '@assets/icons/bold.svg';
import IconCheck            from '@assets/icons/check.svg';
import IconChevronLeft      from '@assets/icons/chevronLeft.svg';
import IconChevronRight     from '@assets/icons/chevronRight.svg';
import IconCloseWindow      from '@assets/icons/closeWindow.svg';
import IconConstructionSite from '@assets/icons/constructionSite.svg';
import IconCross            from '@assets/icons/cross.svg';
import IconDanger           from '@assets/icons/danger.svg';
import IconDelete           from '@assets/icons/delete.svg';
import Dialog               from '@assets/icons/dialog.svg';
import IconDocuments        from '@assets/icons/documents.svg';
import IconDoubleArrowRight from '@assets/icons/doubleArrowRight.svg';
import IconEditMany         from '@assets/icons/editMany.svg';
import IconError            from '@assets/icons/error.svg';
import IconExit             from '@assets/icons/exit.svg';
import IconFacebook         from '@assets/icons/facebook.svg';
import IconFileAdd          from '@assets/icons/fileAdd.svg';
import IconFiretruck        from '@assets/icons/firetruck.svg';
import IconInfoVideo        from '@assets/icons/infoVideo.svg';
import IconInstagram        from '@assets/icons/instagram.svg';
import IconItalic           from '@assets/icons/italic.svg';
import IconMenuArrow        from '@assets/icons/menuArrow.svg';
import IconNewEntry         from '@assets/icons/newEntry.svg';
import IconNews             from '@assets/icons/news.svg';
import IconOk               from '@assets/icons/ok.svg';
import IconProfile          from '@assets/icons/profile.svg';
import IconQuestionmark     from '@assets/icons/questionmark.svg';
import IconRefresh          from '@assets/icons/refresh.svg';
import IconSearch           from '@assets/icons/search.svg';
import IconSettings         from '@assets/icons/settings.svg';
import IconSync             from '@assets/icons/sync.svg';
import IconTriangle         from '@assets/icons/triangle.svg';
import IconUnderlined       from '@assets/icons/underlined.svg';
import IconUnits            from '@assets/icons/units.svg';
import IconVideocall        from '@assets/icons/videocall.svg';
import IconWait             from '@assets/icons/wait.svg';
import PropTypes            from '@components/PropTypes';
import SelectionHelper      from '@helper/SelectionHelper';

import IconType from './IconType';
import styles   from './styles.module.scss';

const propTypes = {
    className: PropTypes.string,
    iconType:  PropTypes.iconType,
    onClick:   PropTypes.func,
};

const Icon = ({
    className = '',
    iconType = null,
    onClick = null,
}) => {
    function renderSvg() {
        return SelectionHelper.get(
            iconType,
            {
                [IconType.add]:              <IconAdd />,
                [IconType.alarm]:            <IconAlarm />,
                [IconType.arrowUp]:          <IconArrowUp />,
                [IconType.bell]:             <IconBell />,
                [IconType.bold]:             <IconBold />,
                [IconType.check]:            <IconCheck />,
                [IconType.chevronLeft]:      <IconChevronLeft />,
                [IconType.chevronRight]:     <IconChevronRight />,
                [IconType.closeWindow]:      <IconCloseWindow />,
                [IconType.constructionSite]: <IconConstructionSite />,
                [IconType.cross]:            <IconCross />,
                [IconType.units]:            <IconUnits />,
                [IconType.delete]:           <IconDelete />,
                [IconType.documents]:        <IconDocuments />,
                [IconType.editMany]:         <IconEditMany />,
                [IconType.infoVideo]:        <IconInfoVideo />,
                [IconType.exit]:             <IconExit />,
                [IconType.facebook]:         <IconFacebook />,
                [IconType.fileAdd]:          <IconFileAdd />,
                [IconType.firetruck]:        <IconFiretruck />,
                [IconType.instagram]:        <IconInstagram />,
                [IconType.italic]:           <IconItalic />,
                [IconType.newEntry]:         <IconNewEntry />,
                [IconType.news]:             <IconNews />,
                [IconType.ok]:               <IconOk />,
                [IconType.questionmark]:     <IconQuestionmark />,
                [IconType.refresh]:          <IconRefresh />,
                [IconType.basket]:           <IconBasket />,
                [IconType.videocall]:        <IconVideocall />,
                [IconType.error]:            <IconError />,
                [IconType.search]:           <IconSearch />,
                [IconType.settings]:         <IconSettings />,
                [IconType.sync]:             <IconSync />,
                [IconType.triangle]:         <IconTriangle />,
                [IconType.underlined]:       <IconUnderlined />,
                [IconType.wait]:             <IconWait />,
                [IconType.danger]:           <IconDanger />,
                [IconType.profile]:          <IconProfile />,
                [IconType.dialog]:           <Dialog />,
                [IconType.doubleArrowRight]: <IconDoubleArrowRight />,
                [IconType.menuArrow]:        <IconMenuArrow />,
            },
        );
    }

    return (
        <span
            className={classNames(styles.icon, className)}
            onClick={onClick}
        >
            {renderSvg()}
        </span>
    );
};

Icon.propTypes = propTypes;

export default Icon;
