//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import DefaultTypes      from '@components/DefaultTypes';
import PropTypes         from '@components/PropTypes';
import DateTime          from '@helper/DateTime';
import Spacer            from '@stateless/atomic/Spacer';
import DatePickerControl from '@stateless/composed/DatePickerControl';
import TimePickerControl from '@stateless/composed/TimePickerControl';

import styles from './styles.module.scss';

const propTypes = {
    date:     PropTypes.date,
    onChange: PropTypes.func,
};

const DateTimePicker = ({
    onChange = DefaultTypes.func,
    date = null,
}) => {
    const [time, setTime] = useState({});
    const [day, setDay]   = useState({});

    useEffect(() => {
        setTime({
            hours:   date.getHours(),
            minutes: date.getMinutes(),
        });
        setDay({
            year:  date.getFullYear(),
            month: date.getMonth() + 1, // + 1 because js uses 0 - 11 :man-shrugging:
            day:   date.getDate(),
        });
    }, [date]);

    function handleChange(newDay, newTime) {
        const newDateString = DateTime.formatDateString(newDay, newTime);
        const newDate       = new Date(newDateString);

        onChange(newDate);
    }

    function handleTimeChange(newTime) {
        if (newTime) {
            setTime(newTime);
            handleChange(day, newTime);
        }
    }

    function handleDayChange(newDay) {
        if (newDay) {
            setDay(newDay);
            handleChange(newDay, time);
        }
    }

    return (
        <div className={styles.dateTimePicker}>
            <DatePickerControl
                date={date}
                value={day}
                onChange={handleDayChange}
            />
            <Spacer height={20} />
            <TimePickerControl
                value={time}
                onChange={handleTimeChange}
            />
        </div>
    );
};

DateTimePicker.propTypes = propTypes;

export default DateTimePicker;
