//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _          from 'lodash';
import { put }    from 'redux-saga/effects';
import { call }   from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import * as Api                      from '@api/index';
import Overlay                       from '@constants/Overlay';
import Hydra                         from '@helper/Hydra';
import Notification                  from '@helper/Notification';
import { AlertBoxActions }           from '@slices/alertBox';
import { CreatePressArticleActions } from '@slices/createPressArticle';
import { OverlayActions }            from '@slices/overlay';
import { VehiclesActions }           from '@slices/vehicles';
import IconType                      from '@stateless/atomic/Icon/IconType';
import { selectPreviousOverlays }    from '@store/selectors/overlay';

function* deleteVehicle(action) {
    const vehicle  = _.get(action, 'payload');
    const iri      = _.get(vehicle, 'id');
    const response = yield call(Api.context.vehicles.delete, iri);

    if (response.ok) {
        yield put(VehiclesActions.deleteVehicleSucceeded(vehicle));
    } else {
        yield put(VehiclesActions.deleteVehicleFailed());
    }
}

function* deleteVehicleFailed() {
    Notification.error('deleteVehicleFailed');
}

function* deleteVehicleSucceeded() {
    Notification.success('deleteVehicleSucceeded');
    yield put(OverlayActions.closeOverlay());
}

function* fetchVehicles() {
    const response = yield call(Api.context.vehicles.fetch);

    if (response.ok) {
        const vehicles = Hydra.getMembersFromResponse(response.data);

        yield put(VehiclesActions.fetchVehiclesSucceeded({
            vehicles,
        }));
    } else {
        yield put(VehiclesActions.fetchVehiclesFailed());
    }
}

function* updateVehicle(action) {
    const vehicle  = _.get(action, 'payload');
    const iri      = _.get(vehicle, 'id');
    const isNew    = iri === undefined;
    const response = (
        !isNew ?
            yield call(Api.context.vehicles.update, iri, vehicle) :
            yield call(Api.context.vehicles.create, vehicle)
    );

    if (response.ok) {
        yield put(VehiclesActions.updateVehicleSucceeded({
            vehicle: response.data,
            isNew,
        }));
        yield put(OverlayActions.closeOverlay());
    } else {
        const errorKey =
                  _.get(response, 'data.violations[0].message', null) ||
                  _.get(response, 'data.error', null);

        yield put(VehiclesActions.updateVehicleFailed({
            vehicle,
            isNew,
            errorKey,
        }));
    }
}

function* updateVehicleFailed(action) {
    const { payload: { isNew, errorKey } } = action;

    let errorMessageKey;

    if (Notification.translationExistsForKey(errorKey)) {
        errorMessageKey = errorKey;
    } else {
        errorMessageKey = (
            isNew
                ? 'createVehicleFailed'
                : 'updateVehicleFailed'
        );
    }

    Notification.error(errorMessageKey);
    yield put(AlertBoxActions.showErrorAlertTranslated({
        textKey:  `notifications.${errorMessageKey}`,
        iconLeft: IconType.danger,
    }));
}

function* updateVehicleSucceeded(action) {
    const { payload: { isNew, vehicle } } = action;
    const errorMessageKey                 = (
        isNew
            ? 'createVehicleSucceeded'
            : 'updateVehicleSucceeded'
    );

    Notification.success(errorMessageKey);

    const previousOverlays = yield select(selectPreviousOverlays);
    const previousOverlay  = _.last(previousOverlays);

    if (previousOverlay === Overlay.createPressArticle) {
        yield put(CreatePressArticleActions.addVehicle({
            vehicles: [vehicle],
        }));
    }
}

export default {
    deleteVehicle,
    deleteVehicleFailed,
    deleteVehicleSucceeded,
    fetchVehicles,
    updateVehicle,
    updateVehicleFailed,
    updateVehicleSucceeded,
};
