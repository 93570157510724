//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                     from '@components/PropTypes';
import ArticleStatus                 from '@constants/ArticleStatus';
import { AlertBoxActions }           from '@slices/alertBox';
import { CreatePressArticleActions } from '@slices/createPressArticle';
import StatelessConfirmOverlay       from '@stateless/composed/ConfirmOverlay';
import selectEditPressArticle        from '@store/selectors/editPressArticle';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const RegeneratePressArticleOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch            = useDispatch();
    const pressArticle        = useSelector(selectEditPressArticle);
    const baseTranslationPath = 'components.editPressArticleOverlay.';

    function onConfirmClick() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(CreatePressArticleActions.loadCreatePressArticle({
            pressArticle,
        }));
        dispatch(CreatePressArticleActions.createPressArticle({
            targetStatus: ArticleStatus.TEXT_GENERATION_PENDING,
        }));
        onCloseClick();
    }

    function onDenyClick() {
        onCloseClick();
    }

    return (
        <StatelessConfirmOverlay
            onCloseClick={onCloseClick}
            open={open}
            confirmText={I18n.t(`${baseTranslationPath}confirmRegenerateText`)}
            buttonConfirmText={I18n.t(`${baseTranslationPath}confirmRegenerateConfirmButtonText`)}
            buttonDenyText={I18n.t(`${baseTranslationPath}confirmRegenerateDenyButtonText`)}
            onConfirmClick={onConfirmClick}
            onDenyClick={onDenyClick}
        />
    );
};

RegeneratePressArticleOverlay.propTypes = propTypes;

export default RegeneratePressArticleOverlay;
