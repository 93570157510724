//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React        from 'react';
import { useState } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                     from '@components/PropTypes';
import { AlertBoxActions }           from '@slices/alertBox';
import { ParticularitiesActions }    from '@slices/particularities';
import StatelessEditUnitOverlay      from '@stateless/composed/EditUnitOverlay';
import { selectParticularityInEdit } from '@store/selectors/particularities';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const EditParticularityOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch                = useDispatch();
    const particularityInEdit     = useSelector(selectParticularityInEdit);
    const [title, setTitle]       = useState(_.get(particularityInEdit, 'phrase'));
    const [subtitle, setSubtitle] = useState(_.get(particularityInEdit, 'description'));
    const isNew                   = _.get(particularityInEdit, 'id');

    function onSaveClick() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(ParticularitiesActions.updateParticularity({
            ...particularityInEdit,
            phrase: title,
        }));
    }

    function getOverlayTitle() {
        return isNew ?
            I18n.t('components.editParticularitiesOverlay.editParticularityTitle') :
            I18n.t('components.editParticularitiesOverlay.addParticularityTitle');
    }

    function getOverlayButtonText() {
        return isNew
            ? I18n.t('components.editParticularitiesOverlay.editParticularityButton') :
            I18n.t('components.editParticularitiesOverlay.addParticularityButton');
    }

    return (
        <StatelessEditUnitOverlay
            onCloseClick={onCloseClick}
            open={open}
            title={title}
            setTitle={setTitle}
            subtitle={subtitle}
            setSubtitle={setSubtitle}
            setTitleKey={'phrase'}
            unitInEdit={particularityInEdit}
            titlePlaceholder={I18n.t('components.editParticularitiesOverlay.titlePlaceholder')}
            subtitlePlaceholder={I18n.t('components.editParticularitiesOverlay.subtitlePlaceholder')}
            noSubtitle={true}
            onSaveClick={onSaveClick}
            overlayButtonText={getOverlayButtonText()}
            overlayTitle={getOverlayTitle()}
        />
    );
};

EditParticularityOverlay.propTypes = propTypes;

export default EditParticularityOverlay;
