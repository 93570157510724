//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import _ from 'lodash';

import PropTypes from '@components/PropTypes';

import Value  from './Value';
import styles from './styles.module.scss';

const propTypes = {
    floatingList:      PropTypes.bool,
    onSelectionChange: PropTypes.func,
    values:            PropTypes.arrayOf(PropTypes.multipleChoiceValue),
};

const MultipleChoiceSelector = ({
    values = [],
    floatingList = true,
    onSelectionChange = _.noop,
}) => {
    const [allValues, setAllValues] = useState([]);

    useEffect(() => {
        const choices = values.map((value, index) => (
            {
                label:    value.name ?? value.phrase,
                value:    index,
                selected: value.selected,
            }
        ));

        setAllValues(choices);
    }, [values]);

    function getSelectedIndexes(selectedChoices) {
        return selectedChoices.map((selectedChoice) => selectedChoice.value);
    }

    function toggleChoiceInChoices(choice, choices) {
        choices.find((value) => value.value === choice.value).selected = !choice.selected;
    }

    function getSelectedValuesByIndexes(selectedIndexes) {
        const selectedValues = [];

        selectedIndexes.forEach((index) => selectedValues.push(values[index]));

        return selectedValues;
    }

    function getSelectedValues(choices) {
        const selectedChoices                    = _.filter(choices, 'selected');
        const selectedIndexes                    = getSelectedIndexes(selectedChoices);
        const selectedValues                     = getSelectedValuesByIndexes(selectedIndexes);
        const selectedValuesWithoutSelectedField = _.map(selectedValues, (value) => _.omit(value, ['selected']));

        return selectedValuesWithoutSelectedField;
    }

    function onClick(choice) {
        return () => {
            const newChoices = [...allValues];

            toggleChoiceInChoices(choice, newChoices);
            setAllValues(newChoices);

            const selectedValues = getSelectedValues(newChoices);

            onSelectionChange(selectedValues);
        };
    }

    function renderValue(element) {
        const { label, value, selected } = element;

        return (
            <Value
                key={value}
                label={label}
                isSelected={selected}
                onClick={onClick(element)}
                tabIndex={0}
            />
        );
    }

    const listStyle = {
        flexDirection: floatingList
            ? 'row'
            : 'column',
    };

    return (
        <div
            className={styles.multipleChoiceSelector}
            style={listStyle}
        >
            {allValues.map(renderValue)}
        </div>
    );
};

MultipleChoiceSelector.propTypes = propTypes;

export default MultipleChoiceSelector;
