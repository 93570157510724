//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

export default [
    {
        name:                 'freePlan',
        monthlyMethodPrice:   0,
        yearlyMethodPrice:    0,
        pressArticlePerMonth: 2,
        wordsPerMonth:        0,
        externalUnits:        0,
        units:                0,
        vehicles:             0,
        adFree:               false,
        support:              true,
        termination:          true,
    },
    {
        name:                 'basicPlan',
        monthlyMethodPrice:   15,
        yearlyMethodPrice:    10,
        pressArticlePerMonth: 0,
        wordsPerMonth:        2000,
        externalUnits:        5,
        units:                5,
        vehicles:             5,
        adFree:               true,
        support:              true,
    },
    {
        name:                 'proPlan',
        monthlyMethodPrice:   30,
        yearlyMethodPrice:    25,
        pressArticlePerMonth: 0,
        wordsPerMonth:        4000,
        externalUnits:        20,
        units:                20,
        vehicles:             20,
        adFree:               true,
        support:              true,
        label:                I18n.t('components.planDisplayItem.recommended'),
    },
    {
        name:                 'premiumPlan',
        monthlyMethodPrice:   60,
        yearlyMethodPrice:    50,
        pressArticlePerMonth: 0,
        wordsPerMonth:        12000,
        externalUnits:        'infinite',
        units:                'infinite',
        vehicles:             'infinite',
        adFree:               true,
        support:              true,
    },
];
