//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    add:              'add',
    alarm:            'alarm',
    arrowUp:          'arrowUp',
    bell:             'bell',
    bold:             'bold',
    check:            'check',
    chevronLeft:      'chevronLeft',
    chevronRight:     'chevronRight',
    closeWindow:      'closeWindow',
    constructionSite: 'constructionSite',
    cross:            'cross',
    delete:           'delete',
    infoVideo:        'infoVideo',
    documents:        'documents',
    doubleArrowRight: 'doubleArrowRight',
    editMany:         'editMany',
    exit:             'exit',
    facebook:         'facebook',
    fileAdd:          'fileAdd',
    firetruck:        'firetruck',
    instagram:        'instagram',
    dialog:           'dialog',
    italic:           'italic',
    error:            'error',
    basket:           'basket',
    videocall:        'videocall',
    units:            'units',
    menuArrow:        'menuArrow',
    newEntry:         'newEntry',
    news:             'news',
    ok:               'ok',
    profile:          'profile',
    questionmark:     'questionmark',
    refresh:          'refresh',
    search:           'search',
    settings:         'settings',
    sync:             'sync',
    triangle:         'triangle',
    underlined:       'underlined',
    wait:             'wait',
    danger:           'danger',
});
