//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    API_ARTICLE_GENERATE_URL:      (iri) => `${iri}/generate`,
    API_ARTICLE_URL:               'api/press-articles',
    API_SINGLE_ARTICLE_URL:        (iri) => iri,
    API_CONFIGURATION_URL:         'api/configuration',
    API_EXTERNAL_UNITS_URL:        'api/external-emergency-services',
    API_TOKENS:                    'api/tokens',
    API_UNITS_URL:                 'api/units',
    API_PARTICULARITIES_URL:       'api/particularities',
    API_USER_ACTIVATE_TESTACCOUNT: (iri) => `api/users/${iri}/email-verification`,
    API_REGISTER_TESTACCOUNT:      'api/register-test-account',
    API_USER_RESET_PASSWORD:       'api/users/reset-password',
    API_USER_SET_NEW_PASSWORD:     '/api/users/set-new-password',
    API_USER_UPDATE_PASSWORD:      (iri) => `${iri}/password`,
    API_VEHICLES_URL:              'api/vehicles',
    API_PLAN_TERMINATE:            'api/users/terminate-plan',
});
