//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _          from 'lodash';
import { put }    from 'redux-saga/effects';
import { call }   from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import * as Api                      from '@api/index';
import Overlay                       from '@constants/Overlay';
import Hydra                         from '@helper/Hydra';
import Notification                  from '@helper/Notification';
import { AlertBoxActions }           from '@slices/alertBox';
import { CreatePressArticleActions } from '@slices/createPressArticle';
import { ExternalUnitsActions }      from '@slices/externalUnits';
import { OverlayActions }            from '@slices/overlay';
import IconType                      from '@stateless/atomic/Icon/IconType';
import { selectPreviousOverlays }    from '@store/selectors/overlay';

function* deleteUnit(action) {
    const unit     = _.get(action, 'payload');
    const iri      = _.get(unit, 'id');
    const response = yield call(Api.context.externalUnits.delete, iri);

    if (response.ok) {
        yield put(ExternalUnitsActions.deleteUnitSucceeded(unit));
    } else {
        yield put(ExternalUnitsActions.deleteUnitFailed());
    }
}

function* deleteUnitFailed() {
    Notification.error('deleteExternalUnitFailed');
}

function* deleteUnitSucceeded() {
    Notification.success('deleteExternalUnitSucceeded');
    yield put(OverlayActions.closeOverlay());
}

function* fetchUnits() {
    const response = yield call(Api.context.externalUnits.fetch);

    if (response.ok) {
        const units = Hydra.getMembersFromResponse(response.data);

        yield put(ExternalUnitsActions.fetchUnitsSucceeded({
            units,
        }));
    } else {
        yield put(ExternalUnitsActions.fetchUnitsFailed());
    }
}

function* updateUnit(action) {
    const unit     = _.get(action, 'payload');
    const iri      = _.get(unit, 'id');
    const isNew    = iri === undefined;
    const response = (
        !isNew ?
            yield call(Api.context.externalUnits.update, iri, unit) :
            yield call(Api.context.externalUnits.create, unit)
    );

    if (response.ok) {
        yield put(ExternalUnitsActions.updateUnitSucceeded({
            unit: response.data,
            isNew,
        }));
        yield put(OverlayActions.closeOverlay());
    } else {
        const errorKey =
                  _.get(response, 'data.violations[0].message', null) ||
                  _.get(response, 'data.error', null);

        yield put(ExternalUnitsActions.updateUnitFailed({
            unit,
            isNew,
            errorKey,
        }));
    }
}

function* updateUnitFailed(action) {
    const { payload: { isNew, errorKey } } = action;

    let errorMessageKey;

    if (Notification.translationExistsForKey(errorKey)) {
        errorMessageKey = errorKey;
    } else {
        errorMessageKey = (
            isNew
                ? 'createExternalUnitFailed'
                : 'updateExternalUnitFailed'
        );
    }

    Notification.error(errorMessageKey);
    yield put(AlertBoxActions.showErrorAlertTranslated({
        textKey:  `notifications.${errorMessageKey}`,
        iconLeft: IconType.danger,
    }));
}

function* updateUnitSucceeded(action) {
    const { payload: { isNew, unit } } = action;
    const errorMessageKey              = (
        isNew
            ? 'createExternalUnitSucceeded'
            : 'updateExternalUnitSucceeded'
    );

    Notification.success(errorMessageKey);

    const previousOverlays = yield select(selectPreviousOverlays);
    const previousOverlay  = _.last(previousOverlays);

    if (previousOverlay === Overlay.createPressArticle) {
        yield put(CreatePressArticleActions.addExternalEmergencyService({
            externalEmergencyServices: [unit],
        }));
    }
}

export default {
    deleteUnit,
    deleteUnitFailed,
    deleteUnitSucceeded,
    fetchUnits,
    updateUnit,
    updateUnitFailed,
    updateUnitSucceeded,
};
