//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import AlertBoxManager    from '@connected/AlertBoxManager';
import { useAlertBox }    from '@slices/alertBox';
import { useUser }        from '@slices/user';
import Logo               from '@stateless/atomic/Logo';
import SimpleButton       from '@stateless/atomic/SimpleButton';
import LandingpageFooter  from '@stateless/composed/LandingpageFooter';
import { selectEmail }    from '@store/selectors/user';

import styles from './styles.module.scss';

const ResetPassword = () => {
    const dispatch        = useDispatch();
    const userActions     = useUser(dispatch);
    const alertBoxActions = useAlertBox(dispatch);
    const email           = useSelector(selectEmail);

    function onFormSubmit(event) {
        event.preventDefault();
        console.log('RestPasswordScreen: resetPasswordFormSubmitted');
        alertBoxActions.clearAlerts();
        userActions.resetPassword();
    }

    function emailChanged(event) {
        userActions.setEmail({
            email: event.target.value,
        });
    }

    return (
        <div className={styles.resetPasswordScreen}>
            <div className={styles.resetPasswordScreenContent}>
                <div className={styles.resetPasswordScreenForm}>
                    <Logo showFullLogo={true} />
                    <p>
                        {I18n.t('resetPasswordScreenText')}
                    </p>
                    <div className={styles.alertBoxWrapper}>
                        <AlertBoxManager />
                    </div>
                    <form onSubmit={onFormSubmit}>
                        <label>
                            <span>
                                {I18n.t('emailAddress')}
                            </span>
                            <input
                                type={'email'}
                                onChange={emailChanged}
                                value={email}
                                placeholder={I18n.t('emailAddressPlaceholder')}
                            />
                        </label>
                        <SimpleButton text={I18n.t('resetPassword')} />
                    </form>
                    <LandingpageFooter />
                </div>
            </div>
            <div
                className={styles.resetPasswordScreenStyle}
                aria-hidden={true}
            >
            </div>
        </div>
    );
};

export default ResetPassword;
