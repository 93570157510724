//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    children:     PropTypes.children,
    padding:      PropTypes.number,
    paddingRight: PropTypes.number,
};

const BorderWrapper = ({
    children = null,
    padding = 8,
    paddingRight = 4,
}) => {
    const wrapperStyle = {
        padding: `${padding}px ${paddingRight}px ${padding}px ${padding}px`,
    };

    return (
        <div
            style={wrapperStyle}
            className={styles.borderWrapper}
        >
            {children}
        </div>
    );
};

BorderWrapper.propTypes = propTypes;

export default BorderWrapper;
