//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import PropTypes     from '@components/PropTypes';
import BasePropTypes from '@components/PropTypes';
import ArticleStatus from '@constants/ArticleStatus';
import TextListItem  from '@stateless/composed/TextListItem';

import styles from './styles.module.scss';

const propTypes = {
    onDeleteClick: PropTypes.func,
    onEditClick:   PropTypes.func,
    onReloadClick: PropTypes.func,
    status:        BasePropTypes.oneOfObjectKeys(ArticleStatus),
    subtitle:      PropTypes.string,
    text:          PropTypes.string,
    title:         PropTypes.string,
};

const ArticleListItem = ({
    onDeleteClick = null,
    onEditClick = null,
    onReloadClick = null,
    status = null,
    subtitle = '',
    text = '',
    title = '',
}) => {
    const baseTranslationKey     = 'components.articleListItem.';
    const renderListItemByStatus = () => {
        switch (status) {
            case ArticleStatus.GENERATION_PENDING:
            case ArticleStatus.IN_GENERATE:
            case ArticleStatus.TEXT_GENERATION_PENDING:
            case ArticleStatus.HEADLINE_GENERATION_PENDING:
                return (
                    <TextListItem
                        subtitle={I18n.t(`${baseTranslationKey}pressArticle`)}
                        text={I18n.t(`${baseTranslationKey}isGenerating`)}
                        styleOverrides={{
                            content: styles.overrideLoadingContent,
                        }}
                        isLoading={true}
                    />
                );

            case ArticleStatus.IN_EDIT:
                return (
                    <TextListItem
                        title={I18n.t(`${baseTranslationKey}draft`)}
                        subtitle={subtitle}
                        onEditClick={onEditClick}
                        onDeleteClick={onDeleteClick}
                        styleOverrides={{
                            header:  styles.overrideDraftHeader,
                            content: styles.overrideDraftContent,
                        }}
                    />
                );

            case ArticleStatus.GENERATION_FAILED:
            case ArticleStatus.TEXT_GENERATION_FAILED:
            case ArticleStatus.HEADLINE_GENERATION_FAILED:
                return (
                    <TextListItem
                        subtitle={I18n.t(`${baseTranslationKey}generationFailed`)}
                        onReloadClick={onReloadClick}
                        onDeleteClick={onDeleteClick}
                        isFailed={true}
                        styleOverrides={{
                            header:  styles.overrideFailedHeader,
                            content: styles.overrideFailedContent,
                        }}
                    />
                );

            case ArticleStatus.GENERATED:
            default:
                return (
                    <TextListItem
                        title={title}
                        text={text}
                        subtitle={subtitle}
                        onEditClick={onEditClick}
                    />
                );
        }
    };

    return renderListItemByStatus();
};

ArticleListItem.propTypes = propTypes;

export default ArticleListItem;
