//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import Image from 'react-fix-image-orientation';

import Firefighter  from '@assets/images/firefighter.svg';
import PropTypes    from '@components/PropTypes';
import SvgToDataUrl from '@helper/SvgToDataUrl';

const propTypes = {
    alt:   PropTypes.string,
    image: PropTypes.orNull(PropTypes.string),
};

const ImageWithFallback = ({
    alt = null,
    image = null,
}) => {
    function renderImage() {
        if (image) {
            return (
                <Image
                    alt={alt}
                    src={image}
                />
            );
        }

        return (
            <img
                src={SvgToDataUrl.convert(<Firefighter />)}
                alt={alt}
            />
        );
    }

    return renderImage();
};

ImageWithFallback.propTypes = propTypes;

export default ImageWithFallback;
