//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                     from '@components/PropTypes';
import { AlertBoxActions }           from '@slices/alertBox';
import { CreatePressArticleActions } from '@slices/createPressArticle';
import { OverlayActions }            from '@slices/overlay';
import StatelessConfirmOverlay       from '@stateless/composed/ConfirmOverlay';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const SaveDraftOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const baseTranslationPath = 'components.pressArticleOverlay.';
    const dispatch            = useDispatch();
    const iri                 = useSelector((state) => _.get(state, 'createPressArticle.iri', null));
    const confirmTextKey      = iri ?
        `${baseTranslationPath}updateDraftText` :
        `${baseTranslationPath}saveDraftText`;

    function close() {
        dispatch(OverlayActions.closeOverlay({
            count: 2,
        }));
        dispatch(AlertBoxActions.alertBoxLocationChange());
    }

    function onConfirmSaveClick() {
        dispatch(CreatePressArticleActions.createPressArticle());
        close();
    }

    return (
        <StatelessConfirmOverlay
            onCloseClick={onCloseClick}
            open={open}
            confirmText={I18n.t(confirmTextKey)}
            buttonConfirmText={I18n.t(`${baseTranslationPath}saveDraftConfirmText`)}
            buttonDenyText={I18n.t(`${baseTranslationPath}saveDraftDenyText`)}
            onConfirmClick={onConfirmSaveClick}
            onDenyClick={close}
        />
    );
};

SaveDraftOverlay.propTypes = propTypes;

export default SaveDraftOverlay;
