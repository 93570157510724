//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                  from '@components/PropTypes';
import OverlayConstants           from '@constants/Overlay';
import { OverlayActions }         from '@slices/overlay';
import { ParticularitiesActions } from '@slices/particularities';
import StateLessEditUnitsOverlay  from '@stateless/composed/EditUnitsOverlay';
import VehicleListItem            from '@stateless/composed/VehicleListItem';
import selectParticularities      from '@store/selectors/particularities';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const EditParticularitiesOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch        = useDispatch();
    const particularities = useSelector(selectParticularities);
    const numberOfUnits   = particularities.length;
    const showHint        = particularities.length === 0;

    function setParticularityInEdit(particularity) {
        dispatch(ParticularitiesActions.setParticularityInEdit(particularity));
    }

    function onAddParticularityClicked() {
        dispatch(OverlayActions.openOverlay({
            overlay: OverlayConstants.editParticularity,
        }));
    }

    function onEditParticularityClicked(particularity) {
        return () => {
            dispatch(ParticularitiesActions.setParticularityInEdit(particularity));
            onAddParticularityClicked();
        };
    }

    function onDeleteParticularityClicked(id) {
        return () => {
            dispatch(ParticularitiesActions.setParticularityInEdit({
                id,
            }));
            dispatch(OverlayActions.openOverlay({
                overlay: OverlayConstants.deleteParticularity,
            }));
        };
    }

    function renderParticularities() {
        return particularities.map((particularity) => {
            const { id, phrase } = particularity;

            return (
                <VehicleListItem
                    key={`own-vehicle-${id}`}
                    title={phrase}
                    onDeleteClick={onDeleteParticularityClicked(id)}
                    onEditClick={onEditParticularityClicked({
                        id,
                        phrase,
                    })}
                />
            );
        });
    }

    return (
        <StateLessEditUnitsOverlay
            onCloseClick={onCloseClick}
            open={open}
            renderUnits={renderParticularities}
            numberOfUnits={numberOfUnits}
            onAddUnitClicked={onAddParticularityClicked}
            setUnitInEdit={setParticularityInEdit}
            newLinePerElement={true}
            buttonText={I18n.t('components.editParticularitiesOverlay.addParticularity')}
            showHint={showHint}
            hintText={I18n.t('components.editParticularitiesOverlay.addParticularityHint')}
        />
    );
};

EditParticularitiesOverlay.propTypes = propTypes;

export default EditParticularitiesOverlay;
