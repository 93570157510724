//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import moment                 from 'moment';
import { bindActionCreators } from 'redux';

import RouteHelper        from '@helper/Route';
import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    changes:         {},
    confirmPassword: '',
    email:           null,
    iri:             '',
    lastLogin:       null,
    password:        '',
    stayLoggedIn:    false,
    token:           null,
    username:        '',
    userProfile:     null,
    firstname:       '',
    lastname:        '',
});

const setUserProfileValue = (state, key, value) => {
    let updatedState = _.cloneDeep(state);

    if (!state.changes) {
        updatedState = update(updatedState, {
            changes: {
                $set: {},
            },
        });
    }

    return update(updatedState, {
        userProfile: {
            [key]: {
                $set: value,
            },
        },
        changes:     {
            [key]: {
                $set: true,
            },
        },
    });
};

const userSlice = createSlice({
    name:     'user',
    initialState,
    reducers: {
        updateAvatar:                        (state, action) => {
            const avatar = _.get(action, 'payload.avatar');

            return setUserProfileValue(state, 'uploadedAvatar', avatar);
        },
        updateOrganization:                  LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('updatingOrganization')),
        updateOrganizationSucceeded:         LoadingLevelHelper.decreaseLoading((state, action) => {
            const organization = _.get(action, 'payload.organization', {});

            return update(state, {
                userProfile: {
                    organization: {
                        $merge: organization,
                    },
                },
            });
        }),
        updateOrganizationFailed:            LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        login:                               LoadingLevelHelper.increaseLoading(
            () => {
            },
            I18n.t('loadingLogin'),
        ),
        loginWithCredentials:                _.noop,
        loginFailed:                         LoadingLevelHelper.decreaseLoading((state) => {
            return update(state, {
                password: {
                    $set: '',
                },
            });
        }),
        loginSucceeded:                      LoadingLevelHelper.decreaseLoading((state, action) => {
            const payload   = _.get(action, 'payload', {});
            const now       = moment().utc().format();
            const lastLogin = _.get(payload, 'lastLogin.date', now);

            return update(state, {
                lastLogin: {
                    $set: lastLogin,
                },
                password:  {
                    $set: '',
                },
                token:     {
                    $set: payload.token,
                },
                username:  {
                    $set: payload.username,
                },
                iri:       {
                    $set: payload.userId,
                },
            });
        }),
        registerTestAccount:                 LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loadingText.registeringTestAccount')),
        registerTestAccountFailed:           LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        registerTestAccountSucceeded:        LoadingLevelHelper.decreaseLoading((state) => {
            return update(state, {
                password:        {
                    $set: '',
                },
                confirmPassword: {
                    $set: '',
                },
                email:           {
                    $set: '',
                },
                firstname:       {
                    $set: '',
                },
                lastname:        {
                    $set: '',
                },
            });
        }),
        activateTestAccount:                 LoadingLevelHelper.increaseLoading(
            () => {
            },
            I18n.t('loadingText.activatingTestAccount'),
        ),
        activateTestAccountSucceeded:        LoadingLevelHelper.decreaseLoading((state) => {
            const { email } = RouteHelper.getQueryParametersFromCurrentRoute('email');

            return update(state, {
                password:        {
                    $set: '',
                },
                confirmPassword: {
                    $set: '',
                },
                email:           {
                    $set: email,
                },
            });
        }),
        activateTestAccountFailed:           LoadingLevelHelper.decreaseLoading(() => {
        }),
        logout:                              (state) => {
            return update(state, {
                $set: initialState,
            });
        },
        setPassword:                         (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                password: {
                    $set: payload.password,
                },
            });
        },
        setConfirmPassword:                  (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                confirmPassword: {
                    $set: payload.confirmPassword,
                },
            });
        },
        setUsername:                         (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                username: {
                    $set: payload.username,
                },
            });
        },
        setEmailNotificationsEnabled:        (state, action) => {
            const emailNotificationsEnabled = _.get(action, 'payload.emailNotificationsEnabled', {});

            return update(state, {
                userProfile: {
                    emailNotificationsEnabled: {
                        $set: emailNotificationsEnabled,
                    },
                },
            });
        },
        setEmail:                            (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                email: {
                    $set: payload.email,
                },
            });
        },
        setFirstname:                        (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                firstname: {
                    $set: payload.firstname,
                },
            });
        },
        setLastname:                         (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                lastname: {
                    $set: payload.lastname,
                },
            });
        },
        toggleStayLoggedIn:                  (state) => {
            return update(state, {
                stayLoggedIn: {
                    $set: !state.stayLoggedIn,
                },
            });
        },
        tryRestoreToken:                     () => {
        },
        fetchUser:                           LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loadingText.fetchUser')),
        fetchUserFailed:                     LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchUserSucceeded:                  LoadingLevelHelper.decreaseLoading((state, action) => {
            const {
                userProfile,
            } = action.payload;

            return update(state, {
                userProfile: {
                    $set: userProfile,
                },
            });
        }),
        fetchCustomerPlan:                   () => {
        },
        fetchCustomerPlanFailed:             () => {
        },
        fetchCustomerPlanSucceeded:          (state, action) => {
            const customerPlan = _.get(action, 'payload.customerPlan', {});

            return update(state, {
                userProfile: {
                    organization: {
                        customerPlan: {
                            $merge: customerPlan,
                        },
                    },
                },
            });
        },
        updatePassword:                      LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('updatingPassword')),
        updatePasswordFailed:                LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        updatePasswordSucceeded:             LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        updateUser:                          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('updatingUser')),
        updateUserFailed:                    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        updateUserSucceeded:                 LoadingLevelHelper.decreaseLoading((state, action) => {
            const userProfile = _.get(action, 'payload.userProfile', {});

            return update(state, {
                userProfile: {
                    $set: userProfile,
                },
                changes:     {
                    $set: {},
                },
            });
        }),
        resetPassword:                       LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('resettingPassword')),
        resetPasswordFailed:                 LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        resetPasswordSucceeded:              LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        setNewPassword:                      LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('setNewPassword')),
        setNewPasswordFailed:                LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        setNewPasswordSucceeded:             LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        updateNotificationSettings:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('updateNotifications')),
        updateNotificationSettingsSucceeded: LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        updateNotificationSettingsFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        terminatePaidPlan:                   _.noop,
        terminatePaidPlanConfirmed:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('progressTermination')),
        terminatePaidPlanConfirmedSucceeded: LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        terminatePaidPlanConfirmedFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
    },
});

export const UserActions = userSlice.actions;

export const UserReducer = userSlice.reducer;
export const useUser     = (dispatch) => bindActionCreators(UserActions, dispatch);

export default userSlice;
