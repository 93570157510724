//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useSelector } from 'react-redux';

import DefaultTypes  from '@components/DefaultTypes';
import IconType      from '@stateless/atomic/Icon/IconType';
import Logo          from '@stateless/atomic/Logo';
import IconButton    from '@stateless/composed/IconButton';
import selectOverlay from '@store/selectors/overlay';

import styles from './styles.module.scss';

const propTypes = {
    closeMenuClicked:     DefaultTypes.func,
    menuClicked:          DefaultTypes.func,
    notificationsClicked: DefaultTypes.func,
};

const MobileHeader = ({
    menuClicked = DefaultTypes.func,
    closeMenuClicked = DefaultTypes.func,
    notificationsClicked = DefaultTypes.func,
}) => {
    const currentOverlay = useSelector(selectOverlay);
    const iconSize       = 32;

    function renderSettingsButton() {
        if (!currentOverlay) {
            return (
                <IconButton
                    iconType={IconType.settings}
                    onClick={menuClicked}
                    size={iconSize}
                />
            );
        }

        return (
            <IconButton
                iconType={IconType.closeWindow}
                onClick={closeMenuClicked}
                size={iconSize}
            />
        );
    }

    return (
        <div className={styles.logoHeader}>
            <IconButton
                iconType={IconType.bell}
                onClick={notificationsClicked}
                size={iconSize}
            />
            <div className={styles.logo}>
                <Logo
                    showFullLogo={true}
                />
            </div>
            {renderSettingsButton()}
        </div>
    );
};

MobileHeader.propTypes = propTypes;

export default MobileHeader;
