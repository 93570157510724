//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes    from '@components/PropTypes';
import Icon         from '@stateless/atomic/Icon';
import IconType     from '@stateless/atomic/Icon/IconType';
import ListItem     from '@stateless/atomic/ListItem';
import PrimaryTitle from '@stateless/atomic/PrimaryTitle';
import Subtitle     from '@stateless/atomic/Subtitle';

import styles from './styles.module.scss';

const propTypes = {
    isFailed:       PropTypes.bool,
    isLoading:      PropTypes.bool,
    onDeleteClick:  PropTypes.func,
    onEditClick:    PropTypes.func,
    onReloadClick:  PropTypes.func,
    styleOverrides: PropTypes.shape({
        buttonGroup: PropTypes.string,
        subtitle:    PropTypes.string,
        title:       PropTypes.string,
        text:        PropTypes.string,
    }),
    subtitle:       PropTypes.string,
    text:           PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    title:          PropTypes.string,
};

const EntityListItem = ({
    onDeleteClick = null,
    onEditClick = null,
    onReloadClick = null,
    styleOverrides = {},
    subtitle = null,
    text = null,
    title = '',
    isLoading = false,
    isFailed = false,
}) => {
    const components = {};

    if (!_.isEmpty(title)) {
        components.title = (
            <PrimaryTitle
                text={title}
                className={styleOverrides.title}
            />
        );
    }

    if (!isFailed) {
        if (!_.isEmpty(subtitle)) {
            components.subtitle = (
                <Subtitle
                    text={subtitle}
                    className={styleOverrides.subtitle}
                />
            );
        }

        if (!_.isEmpty(text)) {
            components.content = text;
        }
    } else {
        components.content = (
            <>
                <Icon
                    iconType={IconType.error}
                    className={styles.errorIcon}
                />
                <p>
                    {text}
                </p>
            </>
        );
    }

    if (
        onEditClick ||
        onReloadClick
    ) {
        components.headerRight = (
            <div
                className={classNames(
                    styles.buttonGroup,
                    styleOverrides.buttonGroup,
                )}
            >
                {onEditClick && <Icon
                    iconType={IconType.editMany}
                    onClick={onEditClick}
                />}
                {onReloadClick && <Icon
                    iconType={IconType.sync}
                    onClick={onReloadClick}
                />}
            </div>
        );
    }

    if (onDeleteClick) {
        components.contentButtons = (
            <div
                className={classNames(
                    styles.contentButtons,
                    styleOverrides.contentButtons,
                )}
            >
                {onDeleteClick && <Icon
                    iconType={IconType.delete}
                    onClick={onDeleteClick}
                />}
            </div>
        );
    }

    return (
        <ListItem
            components={components}
            styleOverrides={styleOverrides}
            isLoading={isLoading}
        />
    );
};

EntityListItem.propTypes = propTypes;

export default EntityListItem;
