//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n       from 'i18next';
import _          from 'lodash';
import { put }    from 'redux-saga/effects';
import { call }   from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import * as Api                        from '@api/index';
import MissionDetailField              from '@constants/MissionDetailField';
import Overlay                         from '@constants/Overlay';
import Notification                    from '@helper/Notification';
import PressArticleStatusHelper        from '@helper/PressArticleStatusHelper';
import SagaStateHelper                 from '@helper/SagaStateHelper';
import { AlertBoxActions }             from '@slices/alertBox';
import { CreatePressArticleActions }   from '@slices/createPressArticle';
import { EditPressArticleActions }     from '@slices/editPressArticle';
import { GeneratePressArticleActions } from '@slices/generatePressArticle';
import { LoadingActions }              from '@slices/loading';
import { OverlayActions }              from '@slices/overlay';
import { PressArticleActions }         from '@slices/pressArticle';
import { UserActions }                 from '@slices/user';
import IconType                        from '@stateless/atomic/Icon/IconType';
import selectEditPressArticle          from '@store/selectors/editPressArticle';
import selectOverlay                   from '@store/selectors/overlay';

function* editPressArticle(action) {
    const { payload } = action;
    const open        = _.get(payload, 'open', true);

    if (!open) {
        return;
    }

    yield put(OverlayActions.openOverlay({
        overlay:      Overlay.editPressArticle,
        clearHistory: true,
    }));
}

function validateHeadlineAndText(
    articleHeadline,
    articleText,
) {
    const translationPath = 'components.textEditor.';

    if (_.eq(articleHeadline, I18n.t(`${translationPath}defaultHeadlineTemplateText`))) {
        return `${translationPath}headlineTemplateTextError`;
    }

    if (_.eq(articleText, I18n.t(`${translationPath}defaultTextTemplateText`))) {
        return `${translationPath}textTemplateTextError`;
    }

    return null;
}

function* save(action) {
    const pressArticle                     = yield SagaStateHelper.selectFromStateBySelector(selectEditPressArticle);
    const missionDetail                    = _.get(pressArticle, 'missionDetail');
    const personsDead                      = _.get(missionDetail, MissionDetailField.personsDead, '');
    const personsSaved                     = _.get(missionDetail, MissionDetailField.personsSaved, '');
    const numberOfEmergencyServices        = _.get(missionDetail, MissionDetailField.numberOfEmergencyServices, '');
    const createdWithAssistant             = _.get(pressArticle, 'createdWithAssistant', false) ?? false;
    const particularitiesText              = _.get(missionDetail, MissionDetailField.particularities, '');
    const formattedPressArticle            = {
        ...pressArticle,
        units:                     _.getSingleValue(pressArticle.units, 'iri'),
        externalEmergencyServices: _.getSingleValue(pressArticle.externalEmergencyServices, 'iri'),
        vehicles:                  _.getSingleValue(pressArticle.vehicles, 'iri'),
        particularities:           _.getSingleValue(pressArticle.particularities, 'iri'),
        createdWithAssistant,
        missionDetail:             {
            ...missionDetail,
            [MissionDetailField.particularities]:           particularitiesText,
            [MissionDetailField.personsDead]:               _.toString(personsDead),
            [MissionDetailField.personsSaved]:              _.toString(personsSaved),
            [MissionDetailField.numberOfEmergencyServices]: _.toString(numberOfEmergencyServices),
        },
    };
    const iri                              = _.get(pressArticle, 'iri');
    const createPressArticle               = _.isEmpty(iri);
    const { articleHeadline, articleText } = formattedPressArticle;
    const errorMsgKey                      = validateHeadlineAndText(articleHeadline, articleText);

    if (errorMsgKey) {
        yield put(AlertBoxActions.showErrorAlertTranslated({
            textKey:  errorMsgKey,
            iconLeft: IconType.danger,
        }));
        yield put(LoadingActions.decreaseLevel({
            loadingAction: action,
        }));

        return;
    }

    let response;

    if (createPressArticle) {
        response = yield call(
            Api.context.pressArticle.createPressArticle,
            formattedPressArticle,
        );
    } else {
        response = yield call(
            Api.context.pressArticle.updatePressArticle,
            iri,
            formattedPressArticle,
        );
    }

    yield put(OverlayActions.closeOverlay());

    if (response.ok) {
        yield put(EditPressArticleActions.saveSucceeded({
            pressArticle: response.data,
            createPressArticle,
        }));
    } else {
        yield put(EditPressArticleActions.saveFailed({
            response,
            createPressArticle,
        }));
    }
}

function* saveFailed(action) {
    const { payload: { createPressArticle, response } } = action;
    const showCustomAlertBox                            = _.get(response, 'data.showCustomAlertBox', false);
    const targetOverlay                                 = createPressArticle ?
        Overlay.createPressArticle :
        Overlay.editPressArticle;

    if (!showCustomAlertBox) {
        const errorMessageKey = createPressArticle ?
            'createPressArticleFailed' :
            'savePressArticleFailed';

        Notification.error(errorMessageKey);
        yield put(AlertBoxActions.showErrorAlertTranslated({
            textKey:  Notification.translationBasePath + errorMessageKey,
            iconLeft: IconType.danger,
        }));
    } else {
        yield put(AlertBoxActions.showErrorAlertTranslated({
            textKey:  Notification.translationBasePath + _.get(response, 'data.error', ''),
            iconLeft: IconType.danger,
        }));
    }

    yield put(OverlayActions.openOverlay({
        overlay: targetOverlay,
    }));
}

function* saveSucceeded(action) {
    const { payload: { createPressArticle, pressArticle } } = action;
    const successMessageKey                                 = createPressArticle ?
        'createPressArticleSucceeded' :
        'savePressArticleSucceeded';

    Notification.success(successMessageKey);

    const currentOverlay = yield select(selectOverlay);

    if (PressArticleStatusHelper.isGenerated(pressArticle)) {
        yield put(AlertBoxActions.showSuccessAlertTranslated({
            textKey: Notification.translationBasePath + successMessageKey,
        }));

        if (currentOverlay !== Overlay.editPressArticle) {
            yield put(OverlayActions.openOverlay({
                overlay: Overlay.editPressArticle,
            }));
        }
    }

    yield put(PressArticleActions.fetchPressArticles());
    yield put(UserActions.fetchCustomerPlan());

    if (PressArticleStatusHelper.isAnyGenerationPending(pressArticle)) {
        yield put(GeneratePressArticleActions.generatePressArticle({
            pressArticle,
        }));
    }
}

function* deletePressArticle() {
    const pressArticle = yield SagaStateHelper.selectFromStateBySelector(selectEditPressArticle);
    const iri          = _.get(pressArticle, 'iri');
    const response     = yield call(
        Api.context.pressArticle.deletePressArticle,
        iri,
    );

    if (response.ok) {
        yield put(EditPressArticleActions.deleteSucceeded());
    } else {
        yield put(EditPressArticleActions.deleteFailed());
    }
}

function* deletePressArticleFailed() {
    Notification.error('deletePressArticleFailed');
    yield put(AlertBoxActions.showErrorAlertTranslated({
        textKey:  `${Notification.translationBasePath}deletePressArticleFailed`,
        iconLeft: IconType.danger,
    }));
}

function* deletePressArticleSucceeded() {
    Notification.success('deletePressArticleSucceeded');
    yield put(OverlayActions.closeOverlay());
    yield put(PressArticleActions.fetchPressArticles());
}

function* openCreateDialogToEditData() {
    const pressArticle = yield SagaStateHelper.selectFromStateBySelector(selectEditPressArticle);

    yield put(CreatePressArticleActions.loadCreatePressArticle({
        pressArticle,
    }));
    yield put(OverlayActions.openOverlay({
        overlay: Overlay.createPressArticle,
    }));
}

export default {
    editPressArticle,
    save,
    saveFailed,
    saveSucceeded,
    deletePressArticle,
    deletePressArticleFailed,
    deletePressArticleSucceeded,
    openCreateDialogToEditData,
};
