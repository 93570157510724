//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

const initialState = Object.freeze({
    lastUpdateDates: {},
});

const smartUpdaterSlice = createSlice({
    name:     'smartUpdater',
    initialState,
    reducers: {
        clearAllLastFetchDate: (state, action) => {
            return update(state, {
                $set: initialState,
            });
        },
        clearLastFetchDate:    (state, action) => {
            const { target } = action.payload;

            return update(state, {
                lastUpdateDates: {
                    $unset: [target],
                },
            });
        },
        setLastFetchDate:      (state, action) => {
            const { key, date } = action.payload;

            return update(state, {
                lastUpdateDates: {
                    [key]: {
                        $set: date,
                    },
                },
            });
        },
        reloadAllData:         (state, action) => {
        },
        reloadData:            (state, action) => {
        },
    },
});

export const SmartUpdaterActions = smartUpdaterSlice.actions;

export const SmartUpdaterReducer = smartUpdaterSlice.reducer;

export const useSmartUpdater = (dispatch) => bindActionCreators(SmartUpdaterActions, dispatch);

export default smartUpdaterSlice;
