//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import DefaultTypes          from '@components/DefaultTypes';
import PropTypes             from '@components/PropTypes';
import StringHelper          from '@helper/String';
import Icon                  from '@stateless/atomic/Icon';
import ColorButtonColorTheme from '@stateless/composed/ColorButton/ColorButtonColorTheme';
import ColorButtonFontTheme  from '@stateless/composed/ColorButton/ColorButtonFontTheme';

import styles from './styles.module.scss';

const propTypes = {
    colorTheme:    PropTypes.oneOfObjectKeys(ColorButtonColorTheme),
    fontTheme:     PropTypes.oneOfObjectKeys(ColorButtonFontTheme),
    fullWidth:     PropTypes.bool,
    iconTypeLeft:  PropTypes.iconType,
    iconTypeRight: PropTypes.iconType,
    onClick:       PropTypes.func,
    text:          PropTypes.string,
};

const ColorButton = ({
    colorTheme = ColorButtonColorTheme.white,
    fontTheme = ColorButtonFontTheme.small,
    fullWidth = false,
    iconTypeLeft = null,
    iconTypeRight = null,
    onClick = DefaultTypes.func,
    text = null,
}) => {
    function renderIcon(propertyValue, extraClassName) {
        if (propertyValue) {
            return (
                <span
                    className={classNames(
                        styles.iconWrapper,
                        extraClassName,
                    )}
                >
                    <Icon iconType={propertyValue} />
                </span>
            );
        }

        return null;
    }

    function renderIconLeft() {
        return renderIcon(
            iconTypeLeft,
            styles.iconWrapperLeft,
        );
    }

    function renderIconRight() {
        return renderIcon(
            iconTypeRight,
            styles.iconWrapperRight,
        );
    }

    return (
        <button
            className={classNames(
                styles.colorButton,
                {
                    [styles.colorButtonColorThemeWhite]: colorTheme === ColorButtonColorTheme.white,
                    [styles.colorButtonColorThemeRed]:   colorTheme === ColorButtonColorTheme.red,
                    [styles.colorButtonColorThemeGreen]: colorTheme === ColorButtonColorTheme.green,
                    [styles.colorButtonFontThemeSmall]:  fontTheme === ColorButtonFontTheme.small,
                    [styles.colorButtonFontThemeMiddle]: fontTheme === ColorButtonFontTheme.middle,
                    [styles.colorButtonFontThemeLarge]:  fontTheme === ColorButtonFontTheme.large,
                    [styles.colorButtonFullWidth]:       fullWidth,
                },
            )}
            onClick={onClick}
        >
            {renderIconLeft()}
            {StringHelper.slashLineBreak(text)}
            {renderIconRight()}
        </button>
    );
};

ColorButton.propTypes = propTypes;

export default ColorButton;
