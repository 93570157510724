//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes      from 'prop-types';
import { Pagination } from 'swiper/modules';
import { Swiper }     from 'swiper/react';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const PlanSwiper = ({
    children,
}) => {
    return (
        <Swiper
            className={styles.planSwiper}
            modules={[Pagination]}
            pagination={{
                enabled:   true,
                clickable: true,
            }}
            grabCursor={true}
            updateOnWindowResize={true}
            breakpoints={{
                0:    {
                    slidesPerView: 1,
                    spaceBetween:  10,
                },
                680:  {
                    slidesPerView: 2,
                    spaceBetween:  10,
                },
                992:  {
                    slidesPerView: 2,
                    spaceBetween:  20,
                },
                1280: {
                    slidesPerView: 3,
                    spaceBetween:  20,
                },
                1640: {
                    slidesPerView: 4,
                    spaceBetween:  20,
                },
            }}
            slidesPerView={2}
            spaceBetween={20}
        >
            {children}
        </Swiper>
    );
};

PlanSwiper.propTypes = propTypes;

export default PlanSwiper;
