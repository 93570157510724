//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';

import PropTypes         from '@components/PropTypes';
import ImageWithFallback from '@stateless/atomic/ImageWithFallback';

import styles from './styles.module.scss';

const propTypes = {
    image: PropTypes.orNull(PropTypes.string),
};

const Avatar = ({
    image = null,
}) => {
    return (
        <div className={styles.avatar}>
            <div
                className={classNames(
                    styles.avatarImage,
                    {
                        [styles.fallbackImage]: !image,
                    },
                )}
            >
                <ImageWithFallback
                    image={image}
                    alt={I18n.t('components.userProfilePreview.alternativeImageText')}
                />
            </div>
        </div>
    );
};

Avatar.propTypes = propTypes;

export default Avatar;
