//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Environment                  from '@helper/Environment';
import { useStagingAuthentication } from '@slices/stagingAuthentication';
import StatelessStagingLoginOverlay from '@stateless/composed/StagingLoginOverlay';
import selectAuthenticated          from '@store/selectors/stagingAuthentication';

const StagingLoginOverlay = () => {
    const dispatch                     = useDispatch();
    const stagingAuthenticationActions = useStagingAuthentication(dispatch);
    const isAuthenticated              = useSelector(selectAuthenticated);

    function shouldShowStagingLoginOverlay() {
        return (
            Environment.isStaging() &&
            !Environment.isDevelopment() &&
            !Environment.isTest() &&
            !isAuthenticated
        );
    }

    if (shouldShowStagingLoginOverlay()) {
        return (
            <StatelessStagingLoginOverlay
                authenticate={stagingAuthenticationActions.authenticate}
                passwordChanged={stagingAuthenticationActions.authenticationPasswordChanged}
            />
        );
    }

    return null;
};

export default StagingLoginOverlay;
