//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes       from '@components/PropTypes';
import SelectionHelper from '@helper/SelectionHelper';
import HeadlineColor   from '@stateless/atomic/Headline/HeadlineColor';
import HeadlineType    from '@stateless/atomic/Headline/HeadlineType';
import UnderlineColor  from '@stateless/atomic/Headline/UnderlineColor';

import styles from './styles.module.scss';

const propTypes = {
    color:          PropTypes.headlineColor,
    title:          PropTypes.string.isRequired,
    titlePostfix:   PropTypes.string,
    titlePrefix:    PropTypes.string,
    type:           PropTypes.headlineType,
    underlineColor: PropTypes.oneOfObjectValues(UnderlineColor),
};

const Headline = ({
    color = HeadlineColor.black,
    title = null,
    titlePostfix = null,
    titlePrefix = null,
    type = HeadlineType.headline2,
    underlineColor = UnderlineColor.red,
}) => {
    let prefixComponent       = null;
    let postfixComponent      = null;
    const underlineClassnames = classNames(
        styles.underline,
        SelectionHelper.get(
            underlineColor,
            {
                [UnderlineColor.red]:  styles.underlineRed,
                [UnderlineColor.blue]: styles.underlineBlue,
            },
        ),
    );

    if (titlePrefix) {
        prefixComponent = React.createElement(
            'span',
            {
                className: underlineClassnames,
            },
            `${titlePrefix} `,
        );
    }

    if (titlePostfix) {
        postfixComponent = React.createElement(
            'span',
            {
                className: underlineClassnames,
            },
            ` ${titlePostfix}`,
        );
    }

    return React.createElement(
        type,
        {
            className: classNames(
                styles.headline,
                SelectionHelper.get(
                    color,
                    {
                        [HeadlineColor.black]: styles.headlineBlack,
                        [HeadlineColor.blue]:  styles.headlineBlue,
                        [HeadlineColor.white]: styles.headlineWhite,
                        [HeadlineColor.red]:   styles.headlineRed,
                    },
                ),
            ),
        },
        prefixComponent,
        title,
        postfixComponent,
    );
};

Headline.propTypes = propTypes;

export default Headline;
