//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import I18n            from 'i18next';
import update          from 'immutability-helper';
import _               from 'lodash';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    vehicles:      [],
    vehicleInEdit: null,
});

const vehiclesSlice = createSlice({
    name:     'vehicles',
    initialState,
    reducers: {
        resetVehicles:          () => {
            return initialState;
        },
        fetchVehicles:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loadingVehicles')),
        fetchVehiclesFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchVehiclesSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                vehicles: {
                    $push: _.get(action, 'payload.vehicles'),
                },
            });
        }),
        setVehicleInEdit:       (state, action) => {
            const vehicle = _.get(action, 'payload');

            return update(state, {
                vehicleInEdit: {
                    $set: vehicle,
                },
            });
        },
        updateVehicle:          LoadingLevelHelper.increaseLoadingEmptyReducer((state) => {
            const iri = _.get(state, 'vehicles.vehicleInEdit.id', null);

            return (
                iri ?
                    I18n.t('updatingVehicle') :
                    I18n.t('creatingVehicle')
            );
        }),
        updateVehicleFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        updateVehicleSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            const newVehicle   = _.get(action, 'payload.vehicle');
            const exisingIndex = _.findIndex(state.vehicles, {
                id: newVehicle.id,
            });

            if (exisingIndex === -1) {
                return update(state, {
                    vehicles: {
                        $push: [newVehicle],
                    },
                });
            }

            return update(state, {
                vehicles: {
                    [exisingIndex]: {
                        $set: newVehicle,
                    },
                },
            });
        }),
        deleteVehicle:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('deletingVehicle')),
        deleteVehicleFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        deleteVehicleSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            const vehicle     = _.get(action, 'payload');
            const id          = _.get(vehicle, 'id');
            const newVehicles = state.vehicles.filter((oldVehicle) => oldVehicle.id !== id);

            return update(state, {
                vehicles: {
                    $set: newVehicles,
                },
            });
        }),
    },
});

export const VehiclesActions = vehiclesSlice.actions;

export const VehiclesReducer = vehiclesSlice.reducer;

export default vehiclesSlice;
