//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes     from '@components/PropTypes';
import Avatar        from '@stateless/atomic/Avatar';
import Headline      from '@stateless/atomic/Headline';
import HeadlineColor from '@stateless/atomic/Headline/HeadlineColor';
import HeadlineType  from '@stateless/atomic/Headline/HeadlineType';

import styles from './styles.module.scss';

const propTypes = {
    image:    PropTypes.orNull(PropTypes.string),
    name:     PropTypes.string,
    subtitle: PropTypes.string,
};

const UserProfilePreview = ({
    image = null,
    name = '',
    subtitle = null,
}) => {
    return (
        <div className={styles.userProfilePreviewWrapper}>
            <Avatar image={image} />
            <Headline
                title={name}
                color={HeadlineColor.white}
                type={HeadlineType.headline3}
            />
            <span className={styles.userProfileSubtitle}>
                {subtitle}
            </span>
        </div>
    );
};

UserProfilePreview.propTypes = propTypes;

export default UserProfilePreview;
