//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import DefaultTypes            from '@components/DefaultTypes';
import PropTypes               from '@components/PropTypes';
import StatelessConfirmOverlay from '@stateless/composed/ConfirmOverlay';

const propTypes = {
    onCloseClick:        PropTypes.func,
    onConfirmCloseClick: PropTypes.func,
    open:                PropTypes.bool,
};

const ConfirmExitOverlay = ({
    onCloseClick = _.noop,
    onConfirmCloseClick = DefaultTypes.func,
    open = false,
}) => {
    const baseTranslationPath = 'components.pressArticleOverlay.';

    return (
        <StatelessConfirmOverlay
            onCloseClick={onCloseClick}
            open={open}
            confirmText={I18n.t(`${baseTranslationPath}confirmCloseText`)}
            buttonConfirmText={I18n.t('confirmExit')}
            buttonDenyText={I18n.t('confirmExitAbort')}
            onConfirmClick={onConfirmCloseClick}
            onDenyClick={onCloseClick}
        />
    );
};

ConfirmExitOverlay.propTypes = propTypes;

export default ConfirmExitOverlay;
