//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import I18n            from 'i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import AlertBoxManager        from '@connected/AlertBoxManager';
import Routes                 from '@constants/Routes';
import Environment            from '@helper/Environment';
import RouteHelper            from '@helper/Route';
import { useAlertBox }        from '@slices/alertBox';
import { useUser }            from '@slices/user';
import CheckBox               from '@stateless/atomic/CheckBox';
import Link                   from '@stateless/atomic/Link';
import Logo                   from '@stateless/atomic/Logo';
import SimpleButton           from '@stateless/atomic/SimpleButton';
import LandingpageFooter      from '@stateless/composed/LandingpageFooter';
import { selectEmail }        from '@store/selectors/user';
import { selectPassword }     from '@store/selectors/user';
import { selectStayLoggedIn } from '@store/selectors/user';

import styles from './styles.module.scss';

const Login = () => {
    const dispatch        = useDispatch();
    const userActions     = useUser(dispatch);
    const alertBoxActions = useAlertBox(dispatch);
    const email           = useSelector(selectEmail);
    const password        = useSelector(selectPassword);
    const stayLoggedIn    = useSelector(selectStayLoggedIn);

    useEffect(() => {
        const { email: urlEmail, verified } = RouteHelper.getQueryParametersFromCurrentRoute('email', 'verified');

        if (
            urlEmail &&
            verified === 'true'
        ) {
            userActions.setEmail({
                email: urlEmail,
            });
            alertBoxActions.showSuccessAlertTranslated({
                textKey: 'emailVerifiedText',
            });
        }
    }, []);

    function onFormSubmit(event) {
        event.preventDefault();
        console.log('LoginScreen: loginFormSubmitted');
        alertBoxActions.clearAlerts();
        userActions.login();
    }

    function passwordChanged(event) {
        userActions.setPassword({
            password: event.target.value,
        });
    }

    function emailChanged(event) {
        userActions.setEmail({
            email: event.target.value,
        });
    }

    function stayLoggedInClicked() {
        userActions.toggleStayLoggedIn();
    }

    function onAutoLoginButtonClicked(event) {
        event.preventDefault();

        const defaultEmail    = 'info@lulububu.de';
        const defaultPassword = 'lulububu';

        userActions.loginWithCredentials({
            email:    defaultEmail,
            password: defaultPassword,
        });
    }

    function renderDevelopLoginButton() {
        if (Environment.isDevelopment()) {
            return (
                <SimpleButton
                    text={I18n.t('autoLogin')}
                    onClick={onAutoLoginButtonClicked}
                />
            );
        }

        return null;
    }

    return (
        <div className={styles.loginScreen}>
            <div className={styles.loginScreenContent}>
                <div className={styles.loginScreenForm}>
                    <Logo showFullLogo={true} />
                    <p>
                        {I18n.t('loginScreenText')}
                    </p>
                    <div className={styles.alertBoxWrapper}>
                        <AlertBoxManager />
                    </div>
                    <form onSubmit={onFormSubmit}>
                        <label>
                            <span>
                                {I18n.t('emailAddress')}
                            </span>
                            <input
                                tabIndex="1"
                                type={'email'}
                                onChange={emailChanged}
                                value={email}
                                placeholder={I18n.t('emailAddressPlaceholder')}
                            />
                        </label>
                        <label>
                            <span>
                                {I18n.t('password')}
                                <Link
                                    tabIndex="-1"
                                    to={Routes.resetPassword}
                                >
                                    {I18n.t('forgotPassword')}
                                </Link>
                            </span>
                            <input
                                tabIndex="2"
                                type={'password'}
                                onChange={passwordChanged}
                                value={password}
                                placeholder={I18n.t('passwordPlaceholder')}
                            />
                        </label>
                        <CheckBox
                            onClick={stayLoggedInClicked}
                            text={I18n.t('stayLoggedIn')}
                            checked={stayLoggedIn}
                        />
                        <SimpleButton text={I18n.t('login')} />
                        {renderDevelopLoginButton()}
                    </form>
                    <LandingpageFooter />
                </div>
            </div>
            <div
                className={styles.loginScreenStyle}
                aria-hidden={true}
            />
        </div>
    );
};

export default Login;
