//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import classNames from 'classnames';

const getInputClassNames = (
    styles,
    disabled,
    multiline,
    postIconType,
    preIconType,
    bold,
    resizable,
) => {
    const inputClassNames = classNames(
        styles.textInput,
        {
            [styles.textInputDisabled]:     disabled,
            [styles.textInputMultiLine]:    multiline,
            [styles.textInputSingleLine]:   !multiline,
            [styles.textInputWithPostIcon]: postIconType,
            [styles.textInputWithPreIcon]:  preIconType,
            [styles.textInputBold]:         bold,
            [styles.textInputResizable]:    resizable,
        },
    );

    return inputClassNames;
};

export default {
    getInputClassNames,
};
