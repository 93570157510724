import { call } from 'redux-saga/effects';
import { put }  from 'redux-saga/effects';

import * as Api                from '@api/index';
import { PressArticleActions } from '@slices/pressArticle';
import { UserActions }         from '@slices/user';

function* generatePressArticle(action) {
    const { pressArticle } = action.payload;
    const { iri }          = pressArticle;

    yield call(
        Api.context.pressArticle.generatePressArticle,
        iri,
        pressArticle,
    );
    yield put(PressArticleActions.fetchSinglePressArticle({
        iri,
    }));
    yield put(UserActions.fetchCustomerPlan());
}

export default {
    generatePressArticle,
};
