//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

_.mixin({
    'either':         (leftValue, rightValue) => {
        return _.isEmpty(leftValue)
            ? rightValue
            : leftValue;
    },
    'getSingleValue': (objectOrArray, key) => {
        if (_.isArray(objectOrArray)) {
            return _.map(objectOrArray, (object) => {
                return _.get(object, key);
            });
        }

        return _.get(objectOrArray, key);
    },
});
