//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import DebounceInput from 'react-debounce-input';

import DefaultTypes           from '@components/DefaultTypes';
import PropTypes              from '@components/PropTypes';
import InputClassHelper       from '@helper/InputClassHelper';
import KeyboardEvent          from '@helper/KeyboardEvent';
import InputWrapper           from '@stateless/atomic/InputWrapper';
import TextInputStyle         from '@stateless/atomic/InputWrapper/TextInputSize';
import TextInputTextAlignment from '@stateless/atomic/InputWrapper/TextInputTextAlignment';
import TextInputType          from '@stateless/atomic/TextInput/TextInputType';

import styles from './styles.module.scss';

const propTypes = {
    autoFocus:       PropTypes.bool,
    disabled:        PropTypes.bool,
    inputRef:        PropTypes.object,
    max:             PropTypes.number,
    min:             PropTypes.number,
    minLength:       PropTypes.number,
    multiline:       PropTypes.bool,
    onBlur:          PropTypes.func,
    onChange:        PropTypes.func,
    onClick:         PropTypes.func,
    onFocus:         PropTypes.func,
    onHitEnter:      PropTypes.func,
    onKeyDown:       PropTypes.func,
    placeholderText: PropTypes.string,
    postIconType:    PropTypes.iconType,
    preIconType:     PropTypes.iconType,
    resizable:       PropTypes.bool,
    size:            PropTypes.oneOfObjectValues(TextInputStyle),
    tabIndex:        PropTypes.number,
    textAlignment:   PropTypes.oneOfObjectValues(TextInputTextAlignment),
    type:            PropTypes.string,
    value:           PropTypes.date,
};

const TextInput     = ({
    autoFocus = false,
    disabled = false,
    max = null,
    min = null,
    minLength = 1,
    multiline = false,
    resizable = false,
    onBlur = DefaultTypes.func,
    onChange = DefaultTypes.func,
    onClick = DefaultTypes.func,
    onFocus = DefaultTypes.func,
    onHitEnter = DefaultTypes.func,
    onKeyDown = DefaultTypes.func,
    placeholderText = null,
    postIconType = null,
    preIconType = null,
    size = TextInputStyle.default,
    tabIndex = 0,
    textAlignment = TextInputTextAlignment.left,
    type = TextInputType.text,
    value = '',
    inputRef = null,
}) => {
    function valueChanged(event) {
        onChange(event);
    }

    function renderInputModeText() {
        const element    = (
            multiline ?
                'textarea' :
                'input'
        );
        const bold       = size === TextInputStyle.bold;
        const className  = InputClassHelper.getInputClassNames(
            styles,
            disabled,
            multiline,
            postIconType,
            preIconType,
            bold,
            resizable,
        );
        const valueToUse = value || '';

        function onKeyDownEvent(event) {
            const isEnterKey         = KeyboardEvent.isEnterKey(event);
            const isNotMultilineText = type !== TextInputType.text && !multiline;
            const isSinglelineText   = type === TextInputType.text && !multiline;

            if (
                isEnterKey &&
                (
                    isNotMultilineText ||
                    isSinglelineText
                )
            ) {
                onHitEnter(event);
            }

            onKeyDown(event);
        }

        return (
            <DebounceInput
                autoFocus={autoFocus}
                className={className}
                element={element}
                debounceTimeout={500}
                disabled={disabled}
                minLength={minLength}
                onClick={onClick}
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={valueChanged}
                onKeyDown={onKeyDownEvent}
                placeholder={placeholderText}
                value={valueToUse}
                type={type}
                min={min}
                max={max}
                tabIndex={tabIndex}
                inputRef={inputRef}
            />
        );
    }

    return (
        <InputWrapper
            size={size}
            textAlignment={textAlignment}
            postIconType={postIconType}
            preIconType={preIconType}
        >
            {renderInputModeText()}
        </InputWrapper>
    );
};
TextInput.propTypes = propTypes;

export default TextInput;
