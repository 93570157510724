//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Trans } from 'react-i18next';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';
import IconType  from '@stateless/atomic/Icon/IconType';
import Text      from '@stateless/atomic/Text';
import TextColor from '@stateless/atomic/Text/TextColor';
import TextSize  from '@stateless/atomic/Text/TextSize';

import styles from './styles.module.scss';

const propTypes = {
    iconType: PropTypes.iconType,
    show:     PropTypes.bool,
    text:     PropTypes.string,
};

const IconTip = ({
    text = '',
    iconType = null,
    show = true,
}) => {
    function renderText() {
        return (
            <Text
                size={TextSize.small}
                color={TextColor.white}
            >
                <Trans>
                    {text}
                </Trans>
            </Text>
        );
    }

    if (!show) {
        return null;
    }

    return (
        <div className={styles.iconTipWrapper}>
            <div className={styles.iconTipTriangle}>
                <Icon iconType={IconType.triangle} />
            </div>
            <div className={styles.iconTip}>
                <div className={styles.iconTipIcon}>
                    <Icon iconType={iconType} />
                </div>
                <div className={styles.textWrapper}>
                    {renderText()}
                </div>
            </div>
        </div>
    );
};

IconTip.propTypes = propTypes;

export default IconTip;
