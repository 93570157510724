//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    assistant:        0,
    whenWhere:        1,
    keyword:          2,
    headquarter:      3,
    initialSituation: 4,
    firstAction:      5,
    personsImpacted:  6,
    ownUnits:         7,
    ownVehicles:      8,
    externalUnits:    9,
    units:            10,
    specificFeatures: 11,
});
