//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes       from '@components/PropTypes';
import Translator      from '@helper/Translator';
import InfoTextOverlay from '@stateless/composed/InfoTextOverlay';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const SuccessfullyTerminationOverlay = ({
    open = false,
    onCloseClick = _.noop,
}) => {
    const translator = new Translator('components.successfullyTerminationOverlay');

    return (
        <InfoTextOverlay
            open={open}
            onCloseClick={onCloseClick}
            text={translator.t('confirmText')}
            title={translator.t('confirmTitle')}
        />
    );
};

SuccessfullyTerminationOverlay.propTypes = propTypes;

export default SuccessfullyTerminationOverlay;
