//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';
import { Trans }  from 'react-i18next';

import DefaultTypes         from '@components/DefaultTypes';
import PropTypes            from '@components/PropTypes';
import Icon                 from '@stateless/atomic/Icon';
import DashboardButtonFlow  from '@stateless/composed/DashboardButton/DashboardButtonFlow';
import DashboardButtonTheme from '@stateless/composed/DashboardButton/DashboardButtonTheme';

import styles from './styles.module.scss';

const propTypes = {
    buttonHoverText: PropTypes.string,
    disabled:        PropTypes.bool,
    flow:            PropTypes.oneOfObjectValues(DashboardButtonFlow),
    icons:           PropTypes.arrayOf(PropTypes.iconType),
    onClick:         PropTypes.func,
    subTextKey:      PropTypes.string,
    theme:           PropTypes.oneOfObjectValues(DashboardButtonTheme),
    titleKey:        PropTypes.string,
};

const DashboardButton = ({
    buttonHoverText = '',
    disabled = false,
    icons = [],
    titleKey = '',
    subTextKey = '',
    theme = null,
    flow = null,
    onClick = DefaultTypes.func,
}) => {
    function renderIcon(icon) {
        return (
            <div
                key={_.uniqueId('dashboard-button-icon-')}
                className={classNames(
                    styles.dashboardButtonIcon,
                    {
                        [styles.multipleIcons]: _.size(icons) > 1,
                    },
                )}
            >
                <Icon
                    iconType={icon}
                />
            </div>
        );
    }

    return (
        <button
            className={classNames(
                styles.dashboardButton,
                {
                    [styles.dashboardButtonRow]:        flow === DashboardButtonFlow.row,
                    [styles.dashboardButtonRed]:        theme === DashboardButtonTheme.red,
                    [styles.dashboardButtonGreen]:      theme === DashboardButtonTheme.green,
                    [styles.dashboardButtonBlueBorder]: theme === DashboardButtonTheme.blueBorder,
                    [styles.dashboardButtonDisabled]:   disabled,
                },
            )}
            onClick={onClick}
            disabled={disabled}
            title={buttonHoverText}
        >
            <div className={styles.dashboardButtonIcons}>
                {_.map(icons, renderIcon)}
            </div>
            <div className={styles.dashboardButtonTextWrapper}>
                <div className={styles.dashboardButtonTitle}>
                    <Trans
                        i18nKey={titleKey}
                    />
                </div>
                <div className={styles.dashboardButtonSubText}>
                    <Trans
                        i18nKey={subTextKey}
                    />
                </div>
            </div>
        </button>
    );
};

DashboardButton.propTypes = propTypes;

export default DashboardButton;
