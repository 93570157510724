//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { createRef } from 'react';
import { useEffect } from 'react';

import DefaultTypes           from '@components/DefaultTypes';
import PropTypes              from '@components/PropTypes';
import NumberFormat           from '@helper/NumberFormat';
import TextInputStyle         from '@stateless/atomic/InputWrapper/TextInputSize';
import TextInputTextAlignment from '@stateless/atomic/InputWrapper/TextInputTextAlignment';
import TextInput              from '@stateless/atomic/TextInput';
import TextInputType          from '@stateless/atomic/TextInput/TextInputType';

import styles from './styles.module.scss';

const propTypes = {
    onChange: PropTypes.func,
    value:    PropTypes.time,
};

const TimePickerControl = ({
    onChange = DefaultTypes.func,
    value = null,
}) => {
    const [selectedHour, setSelectedHour]     = useState(Number(value.hours));
    const [selectedMinute, setSelectedMinute] = useState(Number(value.minutes));
    const timeInputReference                  = createRef();

    useEffect(() => {
        setSelectedHour(Number(value.hours));
        setSelectedMinute(Number(value.minutes));
    }, [value]);

    function parseTimePart(timeValue, min, max) {
        return Math.min(Math.max(Number(timeValue), min), max);
    }

    function handleInputBlur() {
        const timeString = timeInputReference.current.value;

        if (!timeString) {
            timeInputReference.current.value = `${NumberFormat.addLeadingZero(selectedHour)}:${NumberFormat.addLeadingZero(selectedMinute)}`;

            return;
        }

        const [
            hour,
            minute,
        ] = timeString.split(':');

        onChange({
            hours:   parseTimePart(hour, 0, 23),
            minutes: parseTimePart(minute, 0, 59),
        });
    }

    return (
        <div className={styles.timePickerControl}>
            <TextInput
                inputRef={timeInputReference}
                onBlur={handleInputBlur}
                value={`${NumberFormat.addLeadingZero(selectedHour)}:${NumberFormat.addLeadingZero(selectedMinute)}`}
                type={TextInputType.time}
                size={TextInputStyle.bold}
                textAlignment={TextInputTextAlignment.center}
            />
        </div>
    );
};

TimePickerControl.propTypes = propTypes;

export default TimePickerControl;
