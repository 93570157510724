//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import ReactHtmlParser from 'react-html-parser';

import PropTypes      from '@components/PropTypes';
import StringHelper   from '@helper/String';
import EntityListItem from '@stateless/composed/EntityListItem';

import styles from './styles.module.scss';

const propTypes = {
    isFailed:       PropTypes.bool,
    isLoading:      PropTypes.bool,
    onDeleteClick:  PropTypes.func,
    onEditClick:    PropTypes.func,
    onReloadClick:  PropTypes.func,
    styleOverrides: PropTypes.shape({
        header:      PropTypes.string,
        title:       PropTypes.string,
        content:     PropTypes.string,
        buttonGroup: PropTypes.string,
    }),
    subtitle:       PropTypes.string,
    text:           PropTypes.string,
    title:          PropTypes.string,
};

const TextListItem = ({
    onDeleteClick = null,
    onEditClick = null,
    onReloadClick = null,
    text = '',
    title = '',
    subtitle = '',
    styleOverrides = {},
    isLoading = false,
    isFailed = false,
}) => {
    const overrides    = {
        header:      styles.header,
        title:       styles.title,
        content:     styles.content,
        buttonGroup: styles.buttonGroup,
        ...styleOverrides,
    };
    const renderText   = () => {
        if (text) {
            return (
                <p>
                    {ReactHtmlParser(StringHelper.replaceAllNewLinesWithBreak(text))}
                </p>
            );
        }

        return null;
    };
    const composedText = (
        <>
            <p className={styles.subtitle}>
                {subtitle}
            </p>
            {renderText()}
        </>
    );

    return (
        <EntityListItem
            title={title}
            text={composedText}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            onReloadClick={onReloadClick}
            styleOverrides={overrides}
            isLoading={isLoading}
            isFailed={isFailed}
        />
    );
};

TextListItem.propTypes = propTypes;

export default TextListItem;
