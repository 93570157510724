//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import Overlay               from '@constants/Overlay';
import Pages                 from '@constants/Pages';
import Routes                from '@constants/Routes';
import ProfileHelper         from '@helper/Profile';
import Url                   from '@helper/Url';
import withScrolled          from '@hoc/ScrollListener';
import { OverlayActions }    from '@slices/overlay';
import { useUser }           from '@slices/user';
import Link                  from '@stateless/atomic/Link';
import Logo                  from '@stateless/atomic/Logo';
import LandingpageFooter     from '@stateless/composed/LandingpageFooter';
import SideMenuButton        from '@stateless/composed/SideMenuButton';
import UserProfilePreview    from '@stateless/composed/UserProfilePreview';
import { selectUserProfile } from '@store/selectors/user';

import styles from './styles.module.scss';

const propTypes = {};

const SideMenu = () => {
    const dispatch         = useDispatch();
    const userActions      = useUser(dispatch);
    const userProfile      = useSelector(selectUserProfile);
    const name             = ProfileHelper.getFullName(userProfile);
    const avatar           = _.get(userProfile, 'avatar.path');
    const organisationName = _.get(userProfile, 'organisation.name');

    function navigateToExternal(to) {
        return () => {
            Url.openInNewTab(to);
        };
    }

    function openOverlay(overlay) {
        return () => {
            dispatch(OverlayActions.openOverlay({
                overlay,
            }));
        };
    }

    function logout() {
        return () => userActions.logout();
    }

    function renderSideMenus() {
        return (
            <div className={styles.sideMenuButtons}>
                <SideMenuButton
                    title={I18n.t('components.sideMenu.settingsButton')}
                    onClick={openOverlay(Overlay.userSettings)}
                />
                <SideMenuButton
                    title={I18n.t('components.sideMenu.profileButton')}
                    onClick={openOverlay(Overlay.profileSettings)}
                />
                <SideMenuButton
                    title={I18n.t('components.sideMenu.unitListButton')}
                    onClick={openOverlay(Overlay.editOwnUnits)}
                />
                <SideMenuButton
                    title={I18n.t('components.sideMenu.vehicleListButton')}
                    onClick={openOverlay(Overlay.editVehicles)}
                />
                <SideMenuButton
                    title={I18n.t('components.sideMenu.externalTaskForcesButton')}
                    onClick={openOverlay(Overlay.editExternalUnits)}
                />
                <SideMenuButton
                    title={I18n.t('components.sideMenu.particularitiesButton')}
                    onClick={openOverlay(Overlay.editParticularities)}
                />
                <SideMenuButton
                    title={I18n.t('components.sideMenu.helpButton')}
                    onClick={navigateToExternal(Pages.help)}
                />
                <SideMenuButton
                    title={I18n.t('components.sideMenu.logoutButton')}
                    onClick={logout()}
                />
            </div>
        );
    }

    return (
        <div className={styles.sideMenu}>
            <div className={styles.sideMenuLogo}>
                <Link to={Routes.home}>
                    <Logo
                        showFullLogo={false}
                    />
                </Link>
            </div>
            <UserProfilePreview
                name={name}
                image={Url.backendImage(avatar)}
                subtitle={organisationName}
            />
            {renderSideMenus()}
            <LandingpageFooter />
        </div>
    );
};

SideMenu.propTypes = propTypes;

export default withScrolled(SideMenu);
