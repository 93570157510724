//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory }  from 'react-router';

import PropTypes                           from '@components/PropTypes';
import StatelessUserSettingsOverlay        from '@components/stateless/composed/UserSettingsOverlay';
import SettingSections                     from '@connected/UserSettingsOverlay/SettingSections';
import Api                                 from '@constants/Api';
import Routes                              from '@constants/Routes';
import Environment                         from '@helper/Environment';
import { AlertBoxActions }                 from '@slices/alertBox';
import { OverlayActions }                  from '@slices/overlay';
import { UserActions }                     from '@slices/user';
import IconType                            from '@stateless/atomic/Icon/IconType';
import { selectOrganization }              from '@store/selectors/user';
import selectToken                         from '@store/selectors/user';
import { selectEmailNotificationsEnabled } from '@store/selectors/user';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const UserSettingsOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch                                = useDispatch();
    const history                                 = useHistory();
    const organization                            = useSelector(selectOrganization);
    const emailNotificationsEnabled               = useSelector(selectEmailNotificationsEnabled);
    const userToken                               = useSelector(selectToken);
    const [oldPassword, setOldPassword]           = useState(null);
    const [password, setPassword]                 = useState(null);
    const [passwordCheck, setPasswordCheck]       = useState(null);
    const [passwordMismatch, setPasswordMismatch] = useState(null);
    const [organizationName, setOrganizationName] = useState(_.get(organization, ['name']));
    const [organizationOpen, setOrganizationOpen] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [passwordOpen, setPasswordOpen]         = useState(false);
    const baseTranslationPath                     = 'components.userSettingsOverlay.';

    useEffect(() => {
        if (!open) {
            setOldPassword(null);
            setPassword(null);
            setPasswordCheck(null);
        }
    }, [open]);

    function doPasswordsMatch() {
        return (
            !password ||
            password === '' ||
            password === passwordCheck
        );
    }

    function checkPasswordMatch() {
        const passwordsMatching = doPasswordsMatch();

        setPasswordMismatch(!passwordsMatching);

        return passwordsMatching;
    }

    useEffect(() => {
        checkPasswordMatch();
    }, [password, passwordCheck]);

    useEffect(() => {
        setOrganizationName(_.get(organization, ['name']));
    }, [organization]);

    function onOldPasswordChanged(event) {
        setOldPassword(event.target.value);
    }

    function onPasswordChanged(event) {
        setPassword(event.target.value);
    }

    function onPasswordCheckChanged(event) {
        setPasswordCheck(event.target.value);
    }

    function onPasswordUpdateClicked() {
        dispatch(AlertBoxActions.clearAlerts());

        if (!passwordMismatch) {
            dispatch(AlertBoxActions.clearAlerts());
            dispatch(UserActions.updatePassword({
                password,
                oldPassword,
            }));

            setOldPassword(null);
            setPassword(null);
            setPasswordCheck(null);

            return;
        }

        dispatch(AlertBoxActions.showErrorAlertTranslated({
            textKey:  `${baseTranslationPath}passwordMismatch`,
            iconLeft: IconType.danger,
        }));
    }

    function onLoginWooCommerceClicked() {
        if (Environment.isProduction()) {
            const backendUrl = Api.getBackendUrl();
            const url        = `${backendUrl}/connector?token=${userToken}`;

            window.open(url, '_blank', 'noopener,noreferrer');
        }
    }

    function organizationNameChanged(event) {
        setOrganizationName(event.target.value);
    }

    function onOrganizationUpdateClicked() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(UserActions.updateOrganization({
            organizationName,
        }));
    }

    function saveNotificationSettingsClicked() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(UserActions.updateNotificationSettings());
    }

    function onSectionChange(section) {
        return (visible) => {
            if (!visible) {
                setNotificationOpen(false);
                setOrganizationOpen(false);
                setPasswordOpen(false);

                return;
            }

            setNotificationOpen(section === SettingSections.notifications);
            setOrganizationOpen(section === SettingSections.organization);
            setPasswordOpen(section === SettingSections.password);
        };
    }

    function onEmailNotificationsEnabledClicked(event) {
        dispatch(UserActions.setEmailNotificationsEnabled({
            emailNotificationsEnabled: !emailNotificationsEnabled,
        }));
    }

    function onNavigateToInvoicesAndPlansClicked() {
        dispatch(OverlayActions.closeOverlay());
        history.push(Routes.invoicesAndPlans);
    }

    return (
        <StatelessUserSettingsOverlay
            onCloseClick={onCloseClick}
            open={open}
            onOldPasswordChanged={onOldPasswordChanged}
            onPasswordChanged={onPasswordChanged}
            onPasswordCheckChanged={onPasswordCheckChanged}
            passwordMismatch={passwordMismatch}
            updatePasswordClicked={onPasswordUpdateClicked}
            loginWooCommerceClicked={onLoginWooCommerceClicked}
            navigateToInvoicesAndPlansClicked={onNavigateToInvoicesAndPlansClicked}
            organizationName={organizationName}
            organizationNameChanged={organizationNameChanged}
            emailNotificationsEnabled={emailNotificationsEnabled}
            updateOrganizationClicked={onOrganizationUpdateClicked}
            onOrganizationOpen={onSectionChange(SettingSections.organization)}
            onPasswordOpen={onSectionChange(SettingSections.password)}
            notificationsOpen={notificationOpen}
            onEmailNotificationsEnabledClick={onEmailNotificationsEnabledClicked}
            onNotificationsOpen={onSectionChange(SettingSections.notifications)}
            organizationOpen={organizationOpen}
            saveNotificationSettingsClicked={saveNotificationSettingsClicked}
            passwordOpen={passwordOpen}
            password={password}
            passwordCheck={passwordCheck}
            oldPassword={oldPassword}
        />
    );
};

UserSettingsOverlay.propTypes = propTypes;

export default UserSettingsOverlay;
