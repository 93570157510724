//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes       from '@components/PropTypes';
import SelectionHelper from '@helper/SelectionHelper';
import Link            from '@stateless/atomic/Link';
import ColorTheme      from '@stateless/composed/TextLink/ColorTheme';

import styles from './styles.module.scss';

const propTypes = {
    children:   PropTypes.children,
    colorTheme: PropTypes.oneOfObjectKeys(ColorTheme),
    target:     PropTypes.string,
    to:         PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};

const TextLink = ({
    colorTheme = ColorTheme.white,
    children = null,
    target = null,
    to = null,
}) => {
    return (
        <Link
            className={classNames(
                styles.link,
                SelectionHelper.get(
                    colorTheme,
                    {
                        [ColorTheme.blue]:  styles.blueTheme,
                        [ColorTheme.white]: styles.whiteTheme,
                    },
                ),
            )}
            target={target}
            to={to}
        >
            {children}
        </Link>
    );
};

TextLink.propTypes = propTypes;

export default TextLink;
