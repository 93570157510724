//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

// eslint-disable-next-line rulesdir/format-import-linter
import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css';

import { Calendar } from '@amir04lm26/react-modern-calendar-date-picker';
import classNames   from 'classnames';
import I18n         from 'i18next';

import DefaultTypes  from '@components/DefaultTypes';
import PropTypes     from '@components/PropTypes';
import useAutoFocus  from '@components/hooks/useAutoFocus';
import KeyboardEvent from '@helper/KeyboardEvent';

import styles from './styles.module.scss';

const propTypes = {
    date:     PropTypes.shape({
        year:  PropTypes.number,
        month: PropTypes.number,
        day:   PropTypes.number,
    }),
    onChange: PropTypes.func,
    onClose:  PropTypes.func,
};

const now = new Date();

const nowDate = {
    year:  now.getFullYear(),
    month: now.getMonth() + 1,
    day:   now.getDate(),
};

const german = {
    months:             [
        I18n.t('components.datePicker.months.january'),
        I18n.t('components.datePicker.months.february'),
        I18n.t('components.datePicker.months.march'),
        I18n.t('components.datePicker.months.april'),
        I18n.t('components.datePicker.months.may'),
        I18n.t('components.datePicker.months.june'),
        I18n.t('components.datePicker.months.july'),
        I18n.t('components.datePicker.months.august'),
        I18n.t('components.datePicker.months.september'),
        I18n.t('components.datePicker.months.october'),
        I18n.t('components.datePicker.months.november'),
        I18n.t('components.datePicker.months.december'),
    ],
    weekDays:           [
        {
            name:  I18n.t('components.datePicker.weekDays.monday'),
            short: I18n.t('components.datePicker.weekDays.mondayShort'),
        },
        {
            name:  I18n.t('components.datePicker.weekDays.tuesday'),
            short: I18n.t('components.datePicker.weekDays.tuesdayShort'),
        },
        {
            name:  I18n.t('components.datePicker.weekDays.wednesday'),
            short: I18n.t('components.datePicker.weekDays.wednesdayShort'),
        },
        {
            name:  I18n.t('components.datePicker.weekDays.thursday'),
            short: I18n.t('components.datePicker.weekDays.thursdayShort'),
        },
        {
            name:  I18n.t('components.datePicker.weekDays.friday'),
            short: I18n.t('components.datePicker.weekDays.fridayShort'),
        },
        {
            name:      I18n.t('components.datePicker.weekDays.saturday'),
            short:     I18n.t('components.datePicker.weekDays.saturdayShort'),
            isWeekend: true,
        },
        {
            name:      I18n.t('components.datePicker.weekDays.sunday'),
            short:     I18n.t('components.datePicker.weekDays.sundayShort'),
            isWeekend: true,
        },
    ],
    weekStartingIndex:  6,
    nextMonth:          I18n.t('nextMonth'),
    previousMonth:      I18n.t('previousMonth'),
    openMonthSelector:  I18n.t('openMonths'),
    openYearSelector:   I18n.t('openYears'),
    closeMonthSelector: I18n.t('closeMonths'),
    closeYearSelector:  I18n.t('closeYears'),
    defaultPlaceholder: `${I18n.t('select')}...`,
    from:               I18n.t('from'),
    to:                 I18n.t('to'),
    digitSeparator:     ',',
    yearLetterSkip:     0,
    isRtl:              false,

    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },

    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },

    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },

    transformDigit(digit) {
        return digit;
    },
};

const DatePicker = ({
    date = nowDate,
    onChange = DefaultTypes.func,
    onClose = DefaultTypes.func,
}) => {
    const containerReference            = useAutoFocus(null);
    const [selectedDay, setSelectedDay] = useState(date);

    function onSelectButtonClicked() {
        onChange(selectedDay);
        onClose();
    }

    function onResetButtonClicked() {
        onChange(null);
        onClose();
    }

    function renderFooter() {
        return (
            <div
                className={classNames(styles.buttonGroup)}
            >
                <button
                    type={'button'}
                    onClick={onResetButtonClicked}
                    className={styles.button}
                >
                    {I18n.t('components.datePicker.cancel')}
                </button>
                <button
                    type={'button'}
                    onClick={onSelectButtonClicked}
                    className={classNames(styles.button, styles.buttonSecondary)}
                >
                    {I18n.t('components.datePicker.selectDate')}
                </button>
            </div>
        );
    }

    function onKeyDown(event) {
        if (KeyboardEvent.isEscape(event)) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    return (
        <div
            ref={containerReference}
            className={styles.container}
            onKeyDown={onKeyDown}
            tabIndex={0}
        >
            <Calendar
                calendarClassName={styles.calendar}
                calendarTodayClassName={styles.calendarToday}
                value={selectedDay}
                onChange={setSelectedDay}
                shouldHighlightWeekends
                maximumDate={nowDate}
                renderFooter={renderFooter}
                locale={german}
            />
        </div>
    );
};

DatePicker.propTypes = propTypes;

export default DatePicker;
