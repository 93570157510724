//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import _ from 'lodash';

import DefaultTypes    from '@components/DefaultTypes';
import PropTypes       from '@components/PropTypes';
import AlertBoxManager from '@connected/AlertBoxManager';
import Overlay         from '@stateless/atomic/Overlay';
import SimpleButton    from '@stateless/atomic/SimpleButton';
import TextInput       from '@stateless/atomic/TextInput';

import styles from './styles.module.scss';

const propTypes = {
    noSubtitle:          PropTypes.bool,
    onCloseClick:        PropTypes.func,
    onSaveClick:         PropTypes.func,
    open:                PropTypes.bool,
    overlayButtonText:   PropTypes.string,
    overlayTitle:        PropTypes.string,
    setSubtitle:         PropTypes.func,
    setTitle:            PropTypes.func,
    setTitleKey:         PropTypes.string,
    subtitle:            PropTypes.string,
    subtitlePlaceholder: PropTypes.string,
    title:               PropTypes.string,
    titlePlaceholder:    PropTypes.string,
    unitInEdit:          PropTypes.object,
};

const EditUnitOverlay = ({
    noSubtitle = false,
    onCloseClick = DefaultTypes.func,
    onSaveClick = DefaultTypes.func,
    unitInEdit = null,
    open = false,
    overlayTitle = '',
    setTitleKey = 'name',
    overlayButtonText = '',
    title = '',
    subtitle = '',
    setTitle = DefaultTypes.func,
    setSubtitle = DefaultTypes.func,
    titlePlaceholder = '',
    subtitlePlaceholder = '',
}) => {
    const canSave = title && title.length;

    useEffect(() => {
        setTitle(_.get(unitInEdit, setTitleKey));
        setSubtitle(_.get(unitInEdit, 'description'));
    }, [unitInEdit]);

    useEffect(() => {
        if (!open) {
            setTitle('');
            setSubtitle('');
        }
    }, [open]);

    function onTitleChanged(event) {
        setTitle(event.target.value);
    }

    function onSubtitleChanged(event) {
        setSubtitle(event.target.value);
    }

    function renderSubtitle() {
        if (!noSubtitle) {
            return (
                <TextInput
                    placeholderText={subtitlePlaceholder}
                    value={subtitle}
                    onChange={onSubtitleChanged}
                />
            );
        }

        return null;
    }

    return (
        <Overlay
            onCloseClick={onCloseClick}
            open={open}
            mobileTransparent={true}
            title={overlayTitle}
            small={true}
        >
            <div className={styles.editUnitOverlayContent}>
                <TextInput
                    placeholderText={titlePlaceholder}
                    value={title}
                    onChange={onTitleChanged}
                />
                {renderSubtitle()}
                <div className={styles.editUnitAlertBoxWrapper}>
                    <AlertBoxManager />
                </div>
                <div className={styles.submitButtonWrapper}>
                    <SimpleButton
                        text={overlayButtonText}
                        onClick={onSaveClick}
                        disabled={!canSave}
                    />
                </div>
            </div>
        </Overlay>
    );
};

EditUnitOverlay.propTypes = propTypes;

export default EditUnitOverlay;
