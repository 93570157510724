//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

const basePath = 'createPressArticle';

export const selectStep = (state) => {
    return _.get(state, [basePath, 'step'], 0);
};

export const selectIri = (state) => {
    return _.get(state, [basePath, 'iri'], null);
};

export const selectArticleStatus = (state) => {
    return _.get(state, [basePath, 'pressArticleStatus', 'articleStatus'], null);
};

export const selectCreatedWithAssistant = (state) => {
    return _.get(state, [basePath, 'createdWithAssistant'], false);
};

export const selectMissionDetail = (state) => {
    return _.get(state, [basePath, 'missionDetail'], null);
};

export const selectedUnitIds = (state) => {
    return _.map(_.get(state, [basePath, 'units'], []), 'id');
};

export const selectedVehicleIds = (state) => {
    return _.map(_.get(state, [basePath, 'vehicles'], []), 'id');
};

export const selectedExternalEmergencyServiceIds = (state) => {
    return _.map(_.get(state, [basePath, 'externalEmergencyServices'], []), 'id');
};

export const selectSelectedParticularities = (state) => {
    return _.map(_.get(state, [basePath, 'particularities'], []), 'id');
};

export default selectStep;
