//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    className: PropTypes.string,
    text:      PropTypes.string,
};

const Subtitle = ({
    className = '',
    text = '',
}) => {
    return (
        <div className={classNames([styles.subtitle, className])}>
            {text}
        </div>
    );
};

Subtitle.propTypes = propTypes;

export default Subtitle;
