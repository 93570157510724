//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _       from 'lodash';
import { put } from 'redux-saga/effects';

import ArticleStatus               from '@constants/ArticleStatus';
import Overlay                     from '@constants/Overlay';
import PressArticleStatusHelper    from '@helper/PressArticleStatusHelper';
import SagaStateHelper             from '@helper/SagaStateHelper';
import { EditPressArticleActions } from '@slices/editPressArticle';
import { OverlayActions }          from '@slices/overlay';

function* createPressArticle(action) {
    const pressArticle    = yield SagaStateHelper.selectFromState('createPressArticle');
    const defaultStatus   = PressArticleStatusHelper.getStatus(pressArticle) ?? ArticleStatus.IN_EDIT;
    const defaultAttempts = _.get(pressArticle, 'pressArticleStatus.attempts', 0);
    const targetStatus    = _.get(action, 'payload.targetStatus', defaultStatus);
    const targetAttempts  = _.get(action, 'payload.targetAttempts', defaultAttempts);

    yield put(EditPressArticleActions.editPressArticle({
        pressArticle: {
            ...pressArticle,
            pressArticleStatus: {
                articleStatus: targetStatus,
                attempts:      targetAttempts,
            },
        },
        open:         false,
    }));
    yield put(EditPressArticleActions.save());
}

function* openEmptyCreatePressArticle() {
    yield put(OverlayActions.closeOverlay({
        force: true,
    }));
    yield put(OverlayActions.openOverlay({
        overlay: Overlay.createPressArticle,
    }));
}

function* setStep(action) {
    const pressArticle = yield SagaStateHelper.selectFromState('createPressArticle');
    const step         = _.get(action, 'payload.step', 0);

    if (
        !_.isEmpty(pressArticle.iri) &&
        pressArticle.createdWithAssistant &&
        step === 0
    ) {
        yield put(OverlayActions.closeOverlay({}));
    }
}

export default {
    createPressArticle,
    openEmptyCreatePressArticle,
    setStep,
};
