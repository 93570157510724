//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// eslint-disable-next-line rulesdir/format-import-linter -- eslint wants to format this import
import 'rc-slider/assets/index.css';

import React        from 'react';
import { useState } from 'react';

import I18n   from 'i18next';
import _      from 'lodash';
import Slider from 'rc-slider';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';
import Cast         from '@helper/Cast';
import SliderTheme  from '@stateless/atomic/SliderWrapper/SliderTheme';
import Spacer       from '@stateless/atomic/Spacer';
import TextInput    from '@stateless/atomic/TextInput';
import colors       from '@styles/colors.module.scss';

import styles from './styles.module.scss';

const propTypes = {
    max:      PropTypes.number,
    min:      PropTypes.number,
    onChange: PropTypes.func,
    textKey:  PropTypes.string,
    theme:    PropTypes.sliderTheme,
    value:    PropTypes.number,
};

const SliderWrapper = ({
    max = 100,
    min = 0,
    onChange = DefaultTypes.func,
    textKey = null,
    theme = SliderTheme.black,
    value = 0,
}) => {
    const themeMapping                        = {
        [SliderTheme.black]: colors.black,
        [SliderTheme.red]:   colors.monzaRed,
    };
    const color                               = _.get(themeMapping, theme);
    const trackStyle                          = {
        backgroundColor: color,
        height:          8,
    };
    const handleStyle                         = {
        borderSize:      4,
        height:          25,
        width:           25,
        marginTop:       -9,
        backgroundColor: colors.white,
        border:          `4px solid ${color}`,
        boxShadow:       'none',
        opacity:         1,
    };
    const railStyle                           = {
        backgroundColor: color,
        height:          8,
    };
    const [sliderValue, setSliderValue]       = useState(value);
    const [showInputField, setShowInputField] = useState(value >= max);
    const text                                = I18n.t(
        textKey,
        {
            count: sliderValue,
        },
    );

    function sliderValueChanged(newValue) {
        setSliderValue(newValue);
        onChange(newValue);
        setShowInputField(newValue >= max);
    }

    function textInputValueChanged(event) {
        sliderValueChanged(Cast.int(event.target.value));
    }

    function renderValue() {
        if (showInputField) {
            return (
                <TextInput
                    value={value}
                    onChange={textInputValueChanged}
                />
            );
        }

        return sliderValue;
    }

    return (
        <div className={styles.slider}>
            <Slider
                onChange={sliderValueChanged}
                value={sliderValue}
                trackStyle={trackStyle}
                handleStyle={handleStyle}
                railStyle={railStyle}
                min={min}
                max={max}
            />
            <Spacer height={20} />
            <div className={styles.sliderTextWrapper}>
                <span className={styles.sliderValue}>
                    {renderValue()}
                </span>
                {text}
            </div>
        </div>
    );
};

SliderWrapper.propTypes = propTypes;

export default SliderWrapper;
