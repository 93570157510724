//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Trans } from 'react-i18next';

import Firefighter from '@assets/images/firefighter.svg';
import PropTypes   from '@components/PropTypes';
import Icon        from '@stateless/atomic/Icon';
import IconType    from '@stateless/atomic/Icon/IconType';
import Text        from '@stateless/atomic/Text';
import TextColor   from '@stateless/atomic/Text/TextColor';
import TextSize    from '@stateless/atomic/Text/TextSize';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.children,
    text:     PropTypes.string,
};

const AssistantTip = ({
    children = null,
    text = '',
}) => {
    function renderText() {
        if (children) {
            return (
                <Text
                    size={TextSize.small}
                    color={TextColor.white}
                >
                    {children}
                </Text>
            );
        }

        if (text) {
            return (
                <Text
                    size={TextSize.small}
                    color={TextColor.white}
                >
                    <Trans>
                        {text}
                    </Trans>
                </Text>
            );
        }

        return null;
    }

    return (
        <div className={styles.assistantTipWrapper}>
            <div className={styles.assistantTipTriangle}>
                <Icon iconType={IconType.triangle} />
            </div>
            <div className={styles.assistantTip}>
                <div className={styles.fireFighterImage}>
                    <Firefighter />
                </div>
                <div className={styles.textWrapper}>
                    {renderText()}
                </div>
            </div>
        </div>
    );
};

AssistantTip.propTypes = propTypes;

export default AssistantTip;
