//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Trans } from 'react-i18next';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';
import IconType  from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    currentStep: PropTypes.number,
    steps:       PropTypes.number,
};

const StepIndicatorToolTip = ({
    currentStep = 0,
    steps = 0,
}) => {
    return (
        <div className={styles.stepIndicatorToolTip}>
            <div className={styles.triangle}>
                <Icon
                    iconType={IconType.triangle}
                />
            </div>
            <div className={styles.stepIndicatorToolTipText}>
                <span>
                    <Trans
                        i18nKey={'components.stepIndicatorToolTip.text'}
                        values={{
                            currentStep,
                            steps,
                        }}
                    />
                </span>
            </div>
        </div>
    );
};

StepIndicatorToolTip.propTypes = propTypes;

export default StepIndicatorToolTip;
