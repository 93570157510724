//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { useState }    from 'react';
import { useEffect }   from 'react';
import { useRef }      from 'react';
import { useMemo }     from 'react';
import { useCallback } from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';
import _          from 'lodash';
import PropTypes  from 'prop-types';
import { Trans }  from 'react-i18next';

import DefaultTypes                    from '@components/DefaultTypes';
import BasePropTypes                   from '@components/PropTypes';
import AlertBoxManager                 from '@connected/AlertBoxManager';
import ArticleStatus                   from '@constants/ArticleStatus';
import AssistantType                   from '@constants/AssistantType';
import MissionDetailField              from '@constants/MissionDetailField';
import PressArticleOverlayPages        from '@constants/PressArticleOverlayPages';
import PressArticleOverlayPagesWithAds from '@constants/PressArticleOverlayPagesWithAds';
import AdBanner                        from '@stateless/atomic/AdBanner';
import AdBannerSlot                    from '@stateless/atomic/AdBanner/AdBannerSlot';
import AssistantTip                    from '@stateless/atomic/AssistantTip';
import Headline                        from '@stateless/atomic/Headline';
import HeadlineType                    from '@stateless/atomic/Headline/HeadlineType';
import IconType                        from '@stateless/atomic/Icon/IconType';
import MultipleChoiceSelector          from '@stateless/atomic/MultipleChoiceSelector';
import Overlay                         from '@stateless/atomic/Overlay';
import SliderWrapper                   from '@stateless/atomic/SliderWrapper';
import SliderTheme                     from '@stateless/atomic/SliderWrapper/SliderTheme';
import StepIndicator                   from '@stateless/atomic/StepIndicator';
import TextInput                       from '@stateless/atomic/TextInput';
import AssistantSelection              from '@stateless/composed/AssistantSelection';
import DashboardButton                 from '@stateless/composed/DashboardButton';
import DashboardButtonFlow             from '@stateless/composed/DashboardButton/DashboardButtonFlow';
import DashboardButtonTheme            from '@stateless/composed/DashboardButton/DashboardButtonTheme';
import DateTimePicker                  from '@stateless/composed/DateTimePicker';
import PagingButton                    from '@stateless/composed/PagingButton';
import PagingButtonType                from '@stateless/composed/PagingButton/PagingButtonType';

import styles from './styles.module.scss';

const propTypes = {
    createdWithAssistant:              PropTypes.bool,
    enableAds:                         PropTypes.bool,
    externalEmergencyServices:         PropTypes.array,
    generatePressArticle:              PropTypes.func,
    iri:                               PropTypes.string,
    missionDetail:                     PropTypes.object,
    onCloseClick:                      PropTypes.func,
    onEditExternalUnitsOverlayClick:   PropTypes.func,
    onEditOwnUnitsOverlayClick:        PropTypes.func,
    onEditParticularitiesOverlayClick: PropTypes.func,
    onEditVehiclesOverlayClicked:      PropTypes.func,
    open:                              PropTypes.bool,
    particularities:                   PropTypes.array,
    savePressArticle:                  PropTypes.func,
    status:                            BasePropTypes.oneOfObjectKeys(ArticleStatus),
    step:                              PropTypes.number,
    units:                             PropTypes.array,
    updateCreatedWithAssistant:        PropTypes.func,
    updateExternalEmergencyServices:   PropTypes.func,
    updateMissionDetail:               PropTypes.func,
    updateParticularities:             PropTypes.func,
    updateStep:                        PropTypes.func,
    updateUnits:                       PropTypes.func,
    updateVehicles:                    PropTypes.func,
    vehicles:                          PropTypes.array,
};

const PressArticleOverlay = ({
    createdWithAssistant = true,
    externalEmergencyServices = [],
    particularities = [],
    generatePressArticle = DefaultTypes.func,
    savePressArticle = DefaultTypes.func,
    missionDetail = {},
    enableAds = false,
    onCloseClick = DefaultTypes.func,
    onEditExternalUnitsOverlayClick = DefaultTypes.func,
    onEditParticularitiesOverlayClick = DefaultTypes.func,
    onEditOwnUnitsOverlayClick = DefaultTypes.func,
    onEditVehiclesOverlayClicked = DefaultTypes.func,
    open = false,
    status = null,
    step = 0,
    units = [],
    updateCreatedWithAssistant = DefaultTypes.func,
    updateExternalEmergencyServices = DefaultTypes.func,
    updateParticularities = DefaultTypes.func,
    updateMissionDetail = DefaultTypes.func,
    updateStep = DefaultTypes.func,
    updateUnits = DefaultTypes.func,
    updateVehicles = DefaultTypes.func,
    iri = null,
    vehicles = [],
}) => {
    const pages                         = enableAds ?
        PressArticleOverlayPagesWithAds :
        PressArticleOverlayPages;
    const pagesCount                    = Object.keys(pages).length - 1;
    const [canContinue, setCanContinue] = useState(false);
    const missionStart                  = _.get(missionDetail, MissionDetailField.missionStartDateTime) ?? new Date().toString();
    const missionStartDateTime          = new Date(missionStart);
    const personsDead                   = _.get(missionDetail, MissionDetailField.personsDead, 0);
    const personsSaved                  = _.get(missionDetail, MissionDetailField.personsSaved, 0);
    const numberOfEmergencyServices     = _.get(missionDetail, MissionDetailField.numberOfEmergencyServices, 0);
    const pageContentReference          = useRef();
    const [time, setTime]               = useState(0);
    const [pageDelay, setPageDelay]     = useState(0);
    const delayDisabled                 = useMemo(() => {
        return time < pageDelay;
    }, [time, pageDelay]);
    let timerRef;

    function refreshTimer() {
        clearInterval(timerRef);
        setTime(0);
        setPageDelay(0);
    }

    useEffect(() => {
        if (open) {
            timerRef = setInterval(() => {
                setTime((prevTime) => prevTime + 0.01);
            }, 10);
        } else {
            refreshTimer();
        }

        return () => {
            refreshTimer();
        };
    }, [open]);

    function focusOverlay() {
        pageContentReference.current.click();
    }

    function scrollToTop() {
        pageContentReference.current.parentElement.scroll(0, 0);
    }

    useEffect(() => {
        if (
            !createdWithAssistant &&
            step === PressArticleOverlayPages.whenWhere
        ) {
            scrollToTop();
        }
    }, [step]);

    useEffect(() => {
        if (open) {
            focusOverlay();
        }
    }, [open]);

    if (
        step &&
        !canContinue
    ) {
        setCanContinue(true);
    }

    function onAssistantSelected(value) {
        updateCreatedWithAssistant(value === AssistantType.withAssistant);
        setCanContinue(true);
    }

    function onChangeMissionDetail(field, textInput = false) {
        return (value) => {
            const targetValue = _.get(value, 'target.value');
            const valueToUse  = (
                textInput ?
                    targetValue :
                    value
            );

            updateMissionDetail({
                ...missionDetail,
                [field]: valueToUse,
            });
        };
    }

    function onPageChange(newIndex) {
        return () => {
            setTime(0);
            updateStep(newIndex);
            focusOverlay();
        };
    }

    function onStepClicked(index) {
        return () => updateStep(index);
    }

    function renderDefaultPage(title, children, delayInSeconds = 0) {
        if (delayInSeconds !== pageDelay) {
            setPageDelay(delayInSeconds);
        }

        return (
            <div className={styles.page}>
                <StepIndicator
                    steps={pagesCount}
                    currentStep={step}
                    onClick={onStepClicked}
                />
                <div className={styles.pageContent}>
                    <Headline
                        title={title}
                        type={HeadlineType.headline3}
                    />
                    {children}
                </div>
            </div>
        );
    }

    function renderNoAssistantPage(title, children) {
        return (
            <div className={styles.pageContent}>
                <Headline
                    title={title}
                    type={HeadlineType.headline3}
                />
                {children}
            </div>
        );
    }

    function renderAssistantTip(key) {
        if (key) {
            return (
                <AssistantTip
                    text={I18n.t(key)}
                />
            );
        }

        return null;
    }

    function renderTextInput(
        placeholderKey,
        field,
        assistantTipKey  = null,
        multiline        = false,
        withoutAssistant = false,
        buttonText       = null,
        onButtonClick    = null,
        showButton       = false,
    ) {
        const value                 = _.get(missionDetail, field);
        const help                  = !withoutAssistant ?
            renderAssistantTip(assistantTipKey) :
            null;
        const renderTextInputButton = () => {
            if (!showButton) {
                return null;
            }
            return (
                <DashboardButton
                    icons={[IconType.fileAdd]}
                    titleKey={buttonText}
                    flow={DashboardButtonFlow.row}
                    theme={DashboardButtonTheme.blueBorder}
                    onClick={onButtonClick}
                />
            );
        };

        return (
            <>
                <TextInput
                    autoFocus
                    placeholderText={I18n.t(placeholderKey)}
                    value={value}
                    onChange={onChangeMissionDetail(field, true)}
                    multiline={multiline}
                    tabIndex={0}
                    onHitEnter={onPageChange(step + 1)}
                />
                {renderTextInputButton()}
                {help}
            </>
        );
    }

    function renderPageAssistant() {
        const assistantType = createdWithAssistant ?
            AssistantType.withAssistant :
            AssistantType.withoutAssistant;

        return (
            <div
                className={classNames(
                    styles.page,
                    styles.pageCentered,
                )}
            >
                <AssistantSelection
                    onAssistantSelected={onAssistantSelected}
                    selectedAssistantType={assistantType}
                />
            </div>
        );
    }

    function renderWhenHelp() {
        return (
            <AssistantTip text={I18n.t('components.pressArticleOverlay.page2.when.help')} />
        );
    }

    function renderPageWhenWhere(withoutAssistant = false) {
        const help = !withoutAssistant ?
            renderWhenHelp() :
            null;

        return [
            I18n.t('components.pressArticleOverlay.page2.when.title'),
            <>
                <div className={styles.pageInnerContent}>
                    <DateTimePicker
                        date={missionStartDateTime}
                        onChange={onChangeMissionDetail(MissionDetailField.missionStartDateTime)}
                    />
                    {help}
                </div>
                <div className={styles.pageInnerContent}>
                    <Headline
                        title={I18n.t('components.pressArticleOverlay.page2.where.title')}
                        type={HeadlineType.headline3}
                    />
                    {renderTextInput(
                        'components.pressArticleOverlay.page2.where.placeholder',
                        MissionDetailField.missionLocation,
                        'components.pressArticleOverlay.page2.where.help',
                        false,
                        withoutAssistant,
                    )}
                </div>
            </>,
        ];
    }

    function renderKeywordHelp() {
        return (
            <AssistantTip>
                <Trans i18nKey={'components.pressArticleOverlay.page3.help'} />
            </AssistantTip>
        );
    }

    function renderPageKeyword(withoutAssistant = false) {
        const help = !withoutAssistant ?
            renderKeywordHelp() :
            null;

        return [
            I18n.t('components.pressArticleOverlay.page3.title'),
            <div
                className={styles.pageInnerContent}
                key={'page-keyword'}
            >
                {renderTextInput(
                    'components.pressArticleOverlay.page3.placeholder',
                    MissionDetailField.alertKeyword,
                )}
                {help}
            </div>,
        ];
    }

    function renderPageHeadquarter(withoutAssistant = false) {
        return [
            I18n.t('components.pressArticleOverlay.page4.title'),
            renderTextInput(
                'components.pressArticleOverlay.page4.placeholder',
                MissionDetailField.controlCenter,
                'components.pressArticleOverlay.page4.help',
                false,
                withoutAssistant,
            ),
        ];
    }

    function renderPageInitialSituation(withoutAssistant = false) {
        return [
            I18n.t('components.pressArticleOverlay.page5.title'),
            renderTextInput(
                'components.pressArticleOverlay.page5.placeholder',
                MissionDetailField.situationUponArrival,
                'components.pressArticleOverlay.page5.help',
                true,
                withoutAssistant,
            ),
        ];
    }

    function renderPageFirstAction(withoutAssistant = false) {
        return [
            I18n.t('components.pressArticleOverlay.page6.title'),
            renderTextInput(
                'components.pressArticleOverlay.page6.placeholder',
                MissionDetailField.activitiesOnSite,
                'components.pressArticleOverlay.page6.help',
                true,
                withoutAssistant,
            ),
        ];
    }

    function renderPagePersonsImpacted(withoutAssistant = false) {
        const help = !withoutAssistant ?
            renderAssistantTip('components.pressArticleOverlay.page7.help') :
            null;

        return [
            I18n.t('components.pressArticleOverlay.page7.title'),
            <>
                <SliderWrapper
                    theme={SliderTheme.red}
                    value={personsSaved}
                    textKey={'personsRescued'}
                    onChange={onChangeMissionDetail(MissionDetailField.personsSaved)}
                    min={0}
                    max={10}
                />
                <SliderWrapper
                    value={personsDead}
                    textKey={'personsRecoveredDead'}
                    onChange={onChangeMissionDetail(MissionDetailField.personsDead)}
                    min={0}
                    max={10}
                />
                {help}
            </>,
        ];
    }

    function onUnitChanged(selectedUnits) {
        updateUnits(selectedUnits);
    }

    function renderPageOwnUnits(withoutAssistant = false) {
        const help = !withoutAssistant ?
            renderAssistantTip('components.pressArticleOverlay.page8.help') :
            null;

        return [
            I18n.t('components.pressArticleOverlay.page8.title'),
            <>
                <MultipleChoiceSelector
                    values={units}
                    floatingList={true}
                    onSelectionChange={onUnitChanged}
                />
                <DashboardButton
                    icons={[IconType.fileAdd]}
                    titleKey={I18n.t('components.pressArticleOverlay.addOwnUnits')}
                    flow={DashboardButtonFlow.row}
                    theme={DashboardButtonTheme.blueBorder}
                    onClick={onEditOwnUnitsOverlayClick}
                />
                {help}
            </>,
        ];
    }

    function onVehicleChanged(selectedVehicles) {
        updateVehicles(selectedVehicles);
    }

    function onExternalEmergencyServiceChanged(selectedExternalEmergencyServices) {
        updateExternalEmergencyServices(selectedExternalEmergencyServices);
    }

    function onParticularitiesChanged(selectedParticularities) {
        updateParticularities(selectedParticularities);
    }

    function renderPageOwnVehicles(withoutAssistant = false) {
        const help = !withoutAssistant ?
            renderAssistantTip('components.pressArticleOverlay.page9.help') :
            null;

        return [
            I18n.t('components.pressArticleOverlay.page9.title'),
            <>
                <MultipleChoiceSelector
                    values={vehicles}
                    floatingList={true}
                    onSelectionChange={onVehicleChanged}
                />
                <DashboardButton
                    icons={[IconType.fileAdd]}
                    titleKey={I18n.t('components.pressArticleOverlay.addOwnVehicles')}
                    flow={DashboardButtonFlow.row}
                    theme={DashboardButtonTheme.blueBorder}
                    onClick={onEditVehiclesOverlayClicked}
                />
                {help}
            </>,
        ];
    }

    function renderAd(slot) {
        return [
            null,
            <AdBanner
                key={slot}
                slot={slot}
            />,
            5,
        ];
    }

    function renderPageExternalUnits(withoutAssistant = false) {
        const help = !withoutAssistant ?
            renderAssistantTip('components.pressArticleOverlay.page10.help') :
            null;

        return [
            I18n.t('components.pressArticleOverlay.page10.title'),
            <>
                <MultipleChoiceSelector
                    values={externalEmergencyServices}
                    onSelectionChange={onExternalEmergencyServiceChanged}
                    floatingList={true}
                />
                <DashboardButton
                    icons={[IconType.fileAdd]}
                    titleKey={I18n.t('components.pressArticleOverlay.addExternalUnits')}
                    flow={DashboardButtonFlow.row}
                    theme={DashboardButtonTheme.blueBorder}
                    onClick={onEditExternalUnitsOverlayClick}
                />
                {help}
            </>,
        ];
    }

    function renderPageUnits(withoutAssistant = false) {
        const help = !withoutAssistant ?
            renderAssistantTip('components.pressArticleOverlay.page11.help') :
            null;

        return [
            I18n.t('components.pressArticleOverlay.page11.title'),
            <>
                <SliderWrapper
                    theme={SliderTheme.red}
                    value={numberOfEmergencyServices}
                    textKey={'emergencyPersonnelCount'}
                    onChange={onChangeMissionDetail(MissionDetailField.numberOfEmergencyServices)}
                    min={0}
                    max={250}
                />
                {help}
            </>,
        ];
    }

    function renderPageSpecificFeatures(withoutAssistant = false) {
        return [
            I18n.t('components.pressArticleOverlay.page12.title'),
            <>
                <MultipleChoiceSelector
                    values={particularities}
                    onSelectionChange={onParticularitiesChanged}
                    floatingList={true}
                />
                {renderTextInput(
                    'components.pressArticleOverlay.page12.placeholder',
                    MissionDetailField.particularities,
                    'components.pressArticleOverlay.page12.help',
                    true,
                    withoutAssistant,
                    I18n.t('components.pressArticleOverlay.addParticularities'),
                    onEditParticularitiesOverlayClick,
                    true,
                )}
            </>,
        ];
    }

    function renderPage() {
        if (createdWithAssistant) {
            switch (step) {
                // @formatter:off
                case pages.assistant:        return renderPageAssistant();
                case pages.whenWhere:        return renderDefaultPage(...renderPageWhenWhere());
                case pages.keyword:          return renderDefaultPage(...renderPageKeyword());
                case pages.headquarter:      return renderDefaultPage(...renderPageHeadquarter());
                case pages.initialSituation: return renderDefaultPage(...renderPageInitialSituation());
                case pages.firstAction:      return renderDefaultPage(...renderPageFirstAction());
                case pages.personsImpacted:  return renderDefaultPage(...renderPagePersonsImpacted());
                case pages.ownUnits:         return renderDefaultPage(...renderPageOwnUnits());
                case pages.ownVehicles:      return renderDefaultPage(...renderPageOwnVehicles());
                case pages.externalUnits:    return renderDefaultPage(...renderPageExternalUnits());
                case pages.units:            return renderDefaultPage(...renderPageUnits());
                case pages.specificFeatures: return renderDefaultPage(...renderPageSpecificFeatures());
                case pages.step3Ad:          return renderDefaultPage(...renderAd(AdBannerSlot.STEP_3_BANNER));
                case pages.step7Ad:          return renderDefaultPage(...renderAd(AdBannerSlot.STEP_3_BANNER));
                default:                                        return null;
                // @formatter:on
            }
        }

        switch (step) {
            // @formatter:off
            case PressArticleOverlayPages.assistant: return renderPageAssistant();
            case PressArticleOverlayPages.whenWhere: return [
                renderNoAssistantPage(...renderPageWhenWhere(true)),
                renderNoAssistantPage(...renderPageKeyword(true)),
                renderNoAssistantPage(...renderPageHeadquarter(true)),
                renderNoAssistantPage(...renderPageInitialSituation(true)),
                renderNoAssistantPage(...renderPageFirstAction(true)),
                renderNoAssistantPage(...renderPagePersonsImpacted(true)),
                renderNoAssistantPage(...renderPageOwnUnits(true)),
                renderNoAssistantPage(...renderPageOwnVehicles(true)),
                renderNoAssistantPage(...renderPageExternalUnits(true)),
                renderNoAssistantPage(...renderPageUnits(true)),
                renderNoAssistantPage(...renderPageSpecificFeatures(true)),
            ];
            default:                                 return null;
            // @formatter:on
        }
    }

    function finishSetupClicked() {
        if (
            iri &&
            status !== ArticleStatus.IN_EDIT
        ) {
            savePressArticle();
            return;
        }
        generatePressArticle();
    }

    function renderContinueButton() {
        if (
            step >= pagesCount ||
            (
                !createdWithAssistant &&
                step >= 1
            )
        ) {
            const finishButtonText = (
                (
                    iri &&
                    status !== ArticleStatus.IN_EDIT
                ) ?
                    I18n.t('components.pressArticleOverlay.finishButtonTextEdit') :
                    I18n.t('components.pressArticleOverlay.finishButtonText')
            );

            return (
                <PagingButton
                    onClick={finishSetupClicked}
                    title={finishButtonText}
                    disabled={delayDisabled}
                />
            );
        }

        const disabled = delayDisabled || !canContinue;

        return (
            <PagingButton
                onClick={onPageChange(step + 1)}
                disabled={disabled}
            />
        );
    }

    function renderNavigationButtons() {
        const disabled = delayDisabled || step === 0;

        return (
            <div className={styles.pressArticleOverlayContentButtons}>
                <PagingButton
                    type={PagingButtonType.backward}
                    onClick={onPageChange(step - 1)}
                    disabled={disabled}
                />
                {renderContinueButton()}
            </div>
        );
    }

    const renderDelayProgressBar = useCallback(() => {
        if (pageDelay === 0) {
            return null;
        }

        let widthInPercent = (
            time / pageDelay
        ) * 100;

        if (time >= pageDelay) {
            widthInPercent = 100;
        }

        const style = {
            width: `${widthInPercent}%`,
        };

        return (
            <div className={styles.delayProgressBar}>
                <div
                    className={styles.progressBar}
                    style={style}
                />
            </div>
        );
    }, [time, pageDelay]);

    return (
        <Overlay
            title={I18n.t('components.pressArticleOverlay.title')}
            onCloseClick={onCloseClick}
            open={open}
        >
            <div
                className={styles.pressArticleOverlayContentContainer}
                ref={pageContentReference}
            >
                {renderPage()}
            </div>
            <div className={styles.alertBoxManagerWrapper}>
                <AlertBoxManager />
            </div>
            <div className={styles.overlayFooter}>
                {renderDelayProgressBar()}
                {renderNavigationButtons()}
            </div>
        </Overlay>
    );
};

PressArticleOverlay.propTypes = propTypes;

export default PressArticleOverlay;
