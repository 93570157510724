//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import PropTypes                       from '@components/PropTypes';
import StatelessProfileSettingsOverlay from '@components/stateless/composed/ProfileSettingsOverlay';
import Avatar                          from '@helper/Avatar';
import ImageStorage                    from '@helper/ImageStorage';
import { AlertBoxActions }             from '@slices/alertBox';
import { UserActions }                 from '@slices/user';
import { selectUserProfile }           from '@store/selectors/user';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const ProfileSettingsOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch                      = useDispatch();
    const user                          = useSelector(selectUserProfile);
    const [avatarState, setAvatarState] = useState(null);
    const [firstname, setFirstname]     = useState(user?.firstname);
    const [lastname, setLastname]       = useState(user?.name);
    const [email, setEmail]             = useState(user?.email);
    const onLoadingFilesComplete        = async (files) => {
        const file        = files[0];
        const storageFile = await ImageStorage.loadImage(file.fileId);

        dispatch(UserActions.updateAvatar({
            avatar: storageFile.image,
        }));
    };
    const onFirstnameChanged            = (event) => {
        setFirstname(event.target.value);
    };
    const onLastnameChanged             = (event) => {
        setLastname(event.target.value);
    };
    const onEmailChanged                = (event) => {
        setEmail(event.target.value);
    };
    const onSaveClicked                 = () => {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(UserActions.updateUser({
            firstname,
            name:   lastname,
            avatar: avatarState,
            email,
        }));
    };

    useEffect(() => {
        const avatarUrl = Avatar.getAvatar(user);

        setAvatarState(avatarUrl);
        setFirstname(user?.firstname);
        setLastname(user?.name);
        setEmail(user?.email);
    }, [user]);

    return (
        <StatelessProfileSettingsOverlay
            onCloseClick={onCloseClick}
            open={open}
            avatar={avatarState}
            onLoadingFilesComplete={onLoadingFilesComplete}
            firstname={firstname}
            onFirstnameChanged={onFirstnameChanged}
            lastname={lastname}
            onLastnameChanged={onLastnameChanged}
            email={email}
            onEmailChanged={onEmailChanged}
            onSaveClicked={onSaveClicked}
        />
    );
};

ProfileSettingsOverlay.propTypes = propTypes;

export default ProfileSettingsOverlay;
