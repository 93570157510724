//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes               from '@components/PropTypes';
import { VehiclesActions }     from '@slices/vehicles';
import StatelessConfirmOverlay from '@stateless/composed/ConfirmOverlay';
import { selectVehicleInEdit } from '@store/selectors/vehicles';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const DeleteVehicleOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch            = useDispatch();
    const vehicleInEdit       = useSelector(selectVehicleInEdit);
    const baseTranslationPath = 'components.editUnitsOverlay.';

    function onConfirmClick() {
        dispatch(VehiclesActions.deleteVehicle(vehicleInEdit));
    }

    function onDenyClick() {
        dispatch(VehiclesActions.setVehicleInEdit(null));
        onCloseClick();
    }

    return (
        <StatelessConfirmOverlay
            onCloseClick={onCloseClick}
            open={open}
            confirmText={I18n.t(`${baseTranslationPath}deleteVehicleText`)}
            buttonConfirmText={I18n.t(`${baseTranslationPath}deleteVehicleConfirmButtonText`)}
            buttonDenyText={I18n.t(`${baseTranslationPath}deleteVehicleDenyButtonText`)}
            onConfirmClick={onConfirmClick}
            onDenyClick={onDenyClick}
        />
    );
};

DeleteVehicleOverlay.propTypes = propTypes;

export default DeleteVehicleOverlay;
