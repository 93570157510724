//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import I18n from 'i18next';

import DefaultTypes             from '@components/DefaultTypes';
import PropTypes                from '@components/PropTypes';
import AssistantType            from '@constants/AssistantType';
import AssistantSelectionButton from '@stateless/atomic/AssistantSelectionButton';

import styles from './styles.module.scss';

const propTypes = {
    onAssistantSelected:   PropTypes.func,
    selectedAssistantType: PropTypes.orNull(PropTypes.assistantType),
};

const AssistantSelection = ({
    selectedAssistantType = null,
    onAssistantSelected = DefaultTypes.func,
}) => {
    const [selectedAssistant, setSelectedAssistant] = React.useState(selectedAssistantType);
    const componentTranslationPath                  = 'components.assistantSelection.';

    useEffect(() => {
        setSelectedAssistant(selectedAssistantType);
    }, [selectedAssistantType]);

    function assistantSelected(assistantType) {
        return () => {
            setSelectedAssistant(assistantType);
            onAssistantSelected(assistantType);
        };
    }

    function renderAssistantSelectionButton(assistantType) {
        const isActive   = selectedAssistant === assistantType;
        const isInactive = (
            selectedAssistant !== null &&
            !isActive
        );

        return (
            <AssistantSelectionButton
                title={I18n.t(`${componentTranslationPath}${assistantType}`)}
                active={isActive}
                inactive={isInactive}
                buttonClicked={assistantSelected(assistantType)}
            />
        );
    }

    return (
        <div className={styles.assistantSelection}>
            {renderAssistantSelectionButton(AssistantType.withAssistant)}
            {renderAssistantSelectionButton(AssistantType.withoutAssistant)}
        </div>
    );
};

AssistantSelection.propTypes = propTypes;

export default AssistantSelection;
