//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import PropTypes       from 'prop-types';
import { useSelector } from 'react-redux';

import DefaultTypes          from '@components/DefaultTypes';
import OverlayConstants      from '@constants/Overlay';
import Pages                 from '@constants/Pages';
import ProfileHelper         from '@helper/Profile';
import Url                   from '@helper/Url';
import IconType              from '@stateless/atomic/Icon/IconType';
import Overlay               from '@stateless/atomic/Overlay';
import ColorButton           from '@stateless/composed/ColorButton';
import ColorButtonColorTheme from '@stateless/composed/ColorButton/ColorButtonColorTheme';
import ColorButtonFontTheme  from '@stateless/composed/ColorButton/ColorButtonFontTheme';
import UserProfilePreview    from '@stateless/composed/UserProfilePreview';
import { selectUserProfile } from '@store/selectors/user';

import styles from './styles.module.scss';

const propTypes = {
    logoutClicked:      PropTypes.func,
    navigateClicked:    PropTypes.func,
    onCloseClick:       PropTypes.func,
    open:               PropTypes.bool,
    openOverlayClicked: PropTypes.func,
};

const MobileMenuOverlay = ({
    onCloseClick = DefaultTypes.func,
    open = false,
    navigateClicked = DefaultTypes.func,
    openOverlayClicked = DefaultTypes.func,
    logoutClicked = DefaultTypes.func,
}) => {
    const userProfile      = useSelector(selectUserProfile);
    const name             = ProfileHelper.getFullName(userProfile);
    const avatar           = _.get(userProfile, 'avatar.path');
    const organisationName = _.get(userProfile, 'organisation.name');

    return (
        <Overlay
            onCloseClick={onCloseClick}
            open={open}
            blue={true}
        >
            <div className={styles.mobileMenuOverlay}>
                <UserProfilePreview
                    name={name}
                    image={Url.backendImage(avatar)}
                    subtitle={organisationName}
                />
                <ColorButton
                    text={I18n.t('components.sideMenu.settingsButton')}
                    colorTheme={ColorButtonColorTheme.white}
                    fontTheme={ColorButtonFontTheme.large}
                    iconTypeLeft={IconType.settings}
                    onClick={openOverlayClicked(OverlayConstants.userSettings)}
                />
                <ColorButton
                    text={I18n.t('components.sideMenu.profileButton')}
                    colorTheme={ColorButtonColorTheme.white}
                    fontTheme={ColorButtonFontTheme.large}
                    iconTypeLeft={IconType.profile}
                    onClick={openOverlayClicked(OverlayConstants.profileSettings)}
                />
                <ColorButton
                    text={I18n.t('components.sideMenu.vehicleListButton')}
                    colorTheme={ColorButtonColorTheme.white}
                    fontTheme={ColorButtonFontTheme.large}
                    iconTypeLeft={IconType.firetruck}
                    onClick={openOverlayClicked(OverlayConstants.editVehicles)}
                />
                <ColorButton
                    text={I18n.t('components.sideMenu.unitListButton')}
                    colorTheme={ColorButtonColorTheme.white}
                    fontTheme={ColorButtonFontTheme.large}
                    iconTypeLeft={IconType.units}
                    onClick={openOverlayClicked(OverlayConstants.editOwnUnits)}
                />
                <ColorButton
                    text={I18n.t('components.sideMenu.externalTaskForcesButton')}
                    colorTheme={ColorButtonColorTheme.white}
                    fontTheme={ColorButtonFontTheme.large}
                    iconTypeLeft={IconType.alarm}
                    onClick={openOverlayClicked(OverlayConstants.editExternalUnits)}
                />
                <ColorButton
                    text={I18n.t('components.sideMenu.particularitiesButton')}
                    colorTheme={ColorButtonColorTheme.white}
                    fontTheme={ColorButtonFontTheme.large}
                    iconTypeLeft={IconType.dialog}
                    onClick={openOverlayClicked(OverlayConstants.editParticularities)}
                />
                <ColorButton
                    text={I18n.t('components.sideMenu.helpButton')}
                    colorTheme={ColorButtonColorTheme.white}
                    fontTheme={ColorButtonFontTheme.large}
                    iconTypeLeft={IconType.questionmark}
                    onClick={navigateClicked(Pages.help)}
                />
                <ColorButton
                    text={I18n.t('components.sideMenu.logoutButton')}
                    colorTheme={ColorButtonColorTheme.white}
                    fontTheme={ColorButtonFontTheme.large}
                    iconTypeLeft={IconType.exit}
                    onClick={logoutClicked()}
                />
            </div>
        </Overlay>
    );
};

MobileMenuOverlay.propTypes = propTypes;

export default MobileMenuOverlay;
