//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';
import AlertBox     from '@stateless/atomic/AlertBox';

import styles from './styles.module.scss';

const propTypes = {
    alertBoxes:        PropTypes.array,
    hideButtonPressed: PropTypes.func,
};

const AlertBoxWrapper = ({
    alertBoxes = [],
    hideButtonPressed = DefaultTypes.func,
}) => {
    function renderAlertBox(data, index) {
        if (data) {
            return (
                <AlertBox
                    onCloseButtonClick={hideButtonPressed(index)}
                    key={`alert-${index}`}
                    text={data.text}
                    type={data.type}
                    iconLeft={data.iconLeft}
                    iconRight={data.iconRight}
                />
            );
        }

        return null;
    }

    return (
        <div className={styles.alertBoxWrapper}>
            {alertBoxes.map(renderAlertBox)}
        </div>
    );
};

AlertBoxWrapper.propTypes = propTypes;

export default AlertBoxWrapper;
