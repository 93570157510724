//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                    from '@components/PropTypes';
import { ExternalUnitsActions }     from '@slices/externalUnits';
import StatelessConfirmOverlay      from '@stateless/composed/ConfirmOverlay';
import { selectExternalUnitInEdit } from '@store/selectors/externalUnits';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const DeleteExternalUnitOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch            = useDispatch();
    const unitInEdit          = useSelector(selectExternalUnitInEdit);
    const baseTranslationPath = 'components.editExternalUnitsOverlay.';

    function onConfirmClick() {
        dispatch(ExternalUnitsActions.deleteUnit(unitInEdit));
    }

    function onDenyClick() {
        dispatch(ExternalUnitsActions.setExternalUnitInEdit(null));
        onCloseClick();
    }

    return (
        <StatelessConfirmOverlay
            onCloseClick={onCloseClick}
            open={open}
            confirmText={I18n.t(`${baseTranslationPath}deleteExternalUnitText`)}
            buttonConfirmText={I18n.t(`${baseTranslationPath}deleteExternalUnitConfirmButtonText`)}
            buttonDenyText={I18n.t(`${baseTranslationPath}deleteExternalUnitDenyButtonText`)}
            onConfirmClick={onConfirmClick}
            onDenyClick={onDenyClick}
        />
    );
};

DeleteExternalUnitOverlay.propTypes = propTypes;

export default DeleteExternalUnitOverlay;
