//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { initDB }       from 'react-indexed-db';
import { useIndexedDB } from 'react-indexed-db';

import DefaultTypes from '@components/DefaultTypes';

import packageJson from '../../package.json';

const StoreName = 'image';

const DBConfig = {
    name:             packageJson.name,
    version:          1,
    objectStoresMeta: [
        {
            store:       StoreName,
            storeConfig: {
                keyPath:       'id',
                autoIncrement: true,
            },
            storeSchema: [
                {
                    name:    'image',
                    keypath: 'id',
                    options: {
                        unique: false,
                    },
                },
            ],
        },
    ],
};

/**
 * This class can be used to store images in the browsers local storage by bypassing redux.
 * This may be required if you store a huge amount of images since the base64 data will be too big to get stored in redux.
 */
class ImageStorage {
    static useIndexedDB = {};

    static saveImage(image) {
        return this.useIndexedDB.add({
            image,
        });
    }

    static loadImage(id) {
        return this.useIndexedDB.getByID(id);
    }

    static deleteImage(id) {
        return this.useIndexedDB.deleteRecord(id);
    }

    static initializeStore() {
        initDB(DBConfig);

        this.useIndexedDB = useIndexedDB(StoreName);

        console.log('ImageStorage: this.useIndexedDB', this.useIndexedDB);
    }

    static clear() {
        return this.useIndexedDB.clear();
    }

    static dumpStore() {
        return this.useIndexedDB.getAll();
    }

    static loadImagesFromStoreByIds(imageIds, callback = DefaultTypes.func, ...parameters) {
        const images = [];

        if (imageIds.length === 0) {
            callback(images);
        }

        imageIds.forEach((imageId) => {
            ImageStorage.loadImage(imageId).then((imageData) => {
                if (imageData) {
                    images.push(imageData);
                }

                if (images.length === imageIds.length) {
                    callback(images, ...parameters);
                }
            }).catch((error) => {
                console.log('ImageUpload: loadImage ', error);
            });
        });
    }
}

export default ImageStorage;
