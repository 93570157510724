//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';
import _               from 'lodash';

import DateTime from '@helper/DateTime';

const initialState = Object.freeze({
    step:                      0,
    createdWithAssistant:      false,
    missionDetail:             {
        missionStartDateTime:      DateTime.getNowAsFormattedDateString(),
        missionEndDateTime:        null,
        missionLocation:           null,
        alertKeyword:              null,
        controlCenter:             null,
        situationUponArrival:      null,
        activitiesOnSite:          null,
        personsSaved:              0,
        personsDead:               0,
        numberOfEmergencyServices: 0,
        particularities:           null,
    },
    units:                     [],
    vehicles:                  [],
    externalEmergencyServices: [],
    particularities:           [],
});

const createPressArticleSlice = createSlice({
    name:     'createPressArticle',
    initialState,
    reducers: {
        setStep:                      (state, action) => {
            return update(state, {
                step: {
                    $set: _.get(action, 'payload.step', 0),
                },
            });
        },
        openEmptyCreatePressArticle:  (state) => {
            return update(state, {
                $set: {
                    ...initialState,
                },
            });
        },
        loadCreatePressArticle:       (state, action) => {
            return update(state, {
                $set: {
                    ..._.get(action, 'payload.pressArticle', initialState),
                    step: 1,
                },
            });
        },
        setCreatedWithAssistant:      (state, action) => {
            return update(state, {
                createdWithAssistant: {
                    $set: _.get(action, 'payload.createdWithAssistant', null),
                },
            });
        },
        setMissionDetail:             (state, action) => {
            return update(state, {
                missionDetail: {
                    $set: _.get(action, 'payload.missionDetail', initialState.missionDetail),
                },
            });
        },
        setUnits:                     (state, action) => {
            return update(state, {
                units: {
                    $set: _.get(action, 'payload.units', initialState.units),
                },
            });
        },
        addUnit:                      (state, action) => {
            return update(state, {
                units: {
                    $push: _.get(action, 'payload.units', []),
                },
            });
        },
        addParticularity:             (state, action) => {
            return update(state, {
                particularities: {
                    $push: _.get(action, 'payload.particularity', []),
                },
            });
        },
        setVehicles:                  (state, action) => {
            return update(state, {
                vehicles: {
                    $set: _.get(action, 'payload.vehicles', initialState.vehicles),
                },
            });
        },
        addVehicle:                   (state, action) => {
            return update(state, {
                vehicles: {
                    $push: _.get(action, 'payload.vehicles', []),
                },
            });
        },
        setExternalEmergencyServices: (state, action) => {
            return update(state, {
                externalEmergencyServices: {
                    $set: _.get(action, 'payload.externalEmergencyServices', initialState.externalEmergencyServices),
                },
            });
        },
        setParticularities:           (state, action) => {
            return update(state, {
                particularities: {
                    $set: _.get(action, 'payload.particularities', initialState.particularities),
                },
            });
        },
        addExternalEmergencyService:  (state, action) => {
            return update(state, {
                externalEmergencyServices: {
                    $push: _.get(action, 'payload.externalEmergencyServices', []),
                },
            });
        },
        createPressArticle:           (state, action) => {
        },
    },
});

export const CreatePressArticleActions = createPressArticleSlice.actions;

export const CreatePressArticleReducer = createPressArticleSlice.reducer;

export default createPressArticleSlice;
