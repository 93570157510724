//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import TextLink           from '@stateless/composed/TextLink';
import TextLinkColorTheme from '@stateless/composed/TextLink/ColorTheme';

import styles from './styles.module.scss';

const propTypes = {};

const LandingpageFooter = () => {
    return (
        <ul className={styles.landingpageFooter}>
            <li>
                <TextLink
                    colorTheme={TextLinkColorTheme.blue}
                    target={'_blank'}
                    to={I18n.t('components.sideMenu.imprintLink')}
                >
                    {I18n.t('components.sideMenu.imprint')}
                </TextLink>
            </li>
            <li>
                <TextLink
                    colorTheme={TextLinkColorTheme.blue}
                    target={'_blank'}
                    to={I18n.t('components.sideMenu.privacyLink')}
                >
                    {I18n.t('components.sideMenu.privacy')}
                </TextLink>
            </li>
        </ul>
    );
};

LandingpageFooter.propTypes = propTypes;

export default LandingpageFooter;
