//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import I18n            from 'i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import AlertBoxManager   from '@connected/AlertBoxManager';
import { useAlertBox }   from '@slices/alertBox';
import { useUser }       from '@slices/user';
import Logo              from '@stateless/atomic/Logo';
import SimpleButton      from '@stateless/atomic/SimpleButton';
import LandingpageFooter from '@stateless/composed/LandingpageFooter';

import styles from './styles.module.scss';

const LostPassword = () => {
    const [passwordRepeat, setPasswordRepeat] = useState(null);
    const [token, setToken]                   = useState(null);
    const password                            = useSelector((state) => state.user.password);
    const dispatch                            = useDispatch();
    const userActions                         = useUser(dispatch);
    const alertBoxActions                     = useAlertBox(dispatch);

    useEffect(() => {
        const searchParameters = new URLSearchParams(window.location.search);

        setToken(searchParameters.get('password-token'));
    }, []);

    function onFormSubmit(event) {
        event.preventDefault();
        console.log('LoginScreen: loginFormSubmitted');
        alertBoxActions.clearAlerts();

        if (
            passwordRepeat &&
            password === passwordRepeat
        ) {
            userActions.setNewPassword({
                token,
            });
        } else {
            alertBoxActions.showErrorAlertTranslated({
                textKey: 'lostPasswordScreenPasswordMismatch',
            });
        }
    }

    function passwordChanged(event) {
        userActions.setPassword({
            password: event.target.value,
        });
    }

    function passwordRepeatChanged(event) {
        setPasswordRepeat(event.target.value);
    }

    return (
        <div className={styles.lostPasswordScreen}>
            <div className={styles.lostPasswordScreenContent}>
                <div className={styles.lostPasswordScreenForm}>
                    <Logo showFullLogo={true} />
                    <p>
                        {I18n.t('lostPasswordScreenText')}
                    </p>
                    <div className={styles.alertBoxWrapper}>
                        <AlertBoxManager />
                    </div>
                    <form onSubmit={onFormSubmit}>
                        <label>
                            <span>
                                {I18n.t('password')}
                            </span>
                            <input
                                type={'password'}
                                onChange={passwordChanged}
                                placeholder={I18n.t('passwordPlaceholder')}
                            />
                        </label>
                        <label>
                            <span>
                                {I18n.t('passwordRepeat')}
                            </span>
                            <input
                                type={'password'}
                                onChange={passwordRepeatChanged}
                                placeholder={I18n.t('passwordRepeatPlaceholder')}
                            />
                        </label>
                        <SimpleButton text={I18n.t('lostPassword')} />
                    </form>
                    <LandingpageFooter />
                </div>
            </div>
            <div
                className={styles.lostPasswordScreenStyle}
                aria-hidden={true}
            >
            </div>
        </div>
    );
};

export default LostPassword;
