//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const english = Object.freeze({
    and:                     'and',
    more:                    'More',
    no:                      'No',
    oclock:                  'o\'clock',
    or:                      'or',
    pageTitle:               'Firewriter',
    versionNumberDateFormat: 'DD.MM.YYYY HH:mm:ss',
    yes:                     'Yes',

    unknown:    'Unknown',
    components: {
        datePicker:           {
            cancel:     'Cancel',
            selectDate: 'Select date',
            selectTime: 'Select time',
        },
        yourPlan:             {
            words:  'Words',
            plans:  {
                standard: 'Standard',
                premium:  'FireWriter<br>Premium',
            },
            header: 'Your <strong>Plan</strong>',
        },
        stepIndicatorToolTip: {
            text: 'Step <strong>{{currentStep}}</strong> of {{steps}}',
        },
    },
});

export default english;
