//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                  from 'lodash';
import { put }            from 'redux-saga/effects';

import { configurations } from '@store/miscellaneous/smartUpdater';

function* reloadAllData() {
    for (const dataType in configurations) {
        const configuration   = configurations[dataType];
        const { fetchAction } = configuration;

        yield put(fetchAction({
            force: true,
        }));
    }
}

function* reloadData(action) {
    const target          = _.get(action, 'payload.target');
    const configuration   = configurations[target];
    const { fetchAction } = configuration;

    yield put(fetchAction({
        force: true,
    }));
}

export default {
    reloadAllData,
    reloadData,
};
