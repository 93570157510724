//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiUrls from '@constants/ApiUrls';

export default (api) => {
    return {
        fetchPressArticles:   (parameters) => {
            return api.get(
                ApiUrls.API_ARTICLE_URL,
                parameters,
            );
        },
        fetchPressArticle:    (iri) => {
            return api.get(ApiUrls.API_SINGLE_ARTICLE_URL(iri));
        },
        createPressArticle:   (pressArticle) => {
            return api.post(
                ApiUrls.API_ARTICLE_URL,
                pressArticle,
            );
        },
        updatePressArticle:   (iri, pressArticle) => {
            return api.put(
                iri,
                pressArticle,
            );
        },
        generatePressArticle: (iri, pressArticle) => {
            return api.put(
                ApiUrls.API_ARTICLE_GENERATE_URL(iri),
                pressArticle,
            );
        },
        deletePressArticle:   (iri) => {
            return api.delete(iri);
        },
    };
};
