//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes       from '@components/PropTypes';
import SelectionHelper from '@helper/SelectionHelper';
import Button          from '@stateless/atomic/Button';
import Icon            from '@stateless/atomic/Icon';
import IconType        from '@stateless/atomic/Icon/IconType';

import AlertBoxType from './AlertBoxType';
import styles       from './styles.module.scss';

const propTypes = {
    iconLeft:           PropTypes.iconType,
    iconRight:          PropTypes.iconType,
    onCloseButtonClick: PropTypes.func,
    text:               PropTypes.string,
    type:               PropTypes.oneOfObjectValues(AlertBoxType),
};

const AlertBox = ({
    onCloseButtonClick = null,
    text = null,
    type = null,
    iconLeft = null,
    iconRight = null,
}) => {
    function renderCloseButton() {
        if (onCloseButtonClick) {
            return (
                <span className={styles.alertBoxCloseButtonWrapper}>
                    <Button
                        iconLeft={IconType.cross}
                        onClick={onCloseButtonClick}
                    />
                </span>
            );
        }

        return null;
    }

    function renderIconLeft() {
        if (iconLeft) {
            return (
                <span className={styles.alertBoxIconLeft}>
                    <Icon
                        iconType={iconLeft}
                    />
                </span>
            );
        }

        return null;
    }

    function renderIconRight() {
        if (iconRight) {
            return (
                <span className={styles.alertBoxIconRight}>
                    <Icon
                        iconType={iconRight}
                    />
                </span>
            );
        }

        return null;
    }

    return (
        <div
            className={classNames(
                styles.alertBox,
                SelectionHelper.get(
                    type,
                    {
                        [AlertBoxType.error]:   styles.alertBoxError,
                        [AlertBoxType.success]: styles.alertBoxSuccess,
                    },
                ),
            )}
        >
            {renderIconLeft()}
            <div className={styles.alertBoxMessage}>
                {text}
            </div>
            {renderIconRight()}
            {renderCloseButton()}
        </div>
    );
};

AlertBox.propTypes = propTypes;

export default AlertBox;
