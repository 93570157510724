//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';
import { useRef }   from 'react';

import classNames from 'classnames';

import DefaultTypes           from '@components/DefaultTypes';
import PropTypes              from '@components/PropTypes';
import Icon                   from '@stateless/atomic/Icon';
import IconType               from '@stateless/atomic/Icon/IconType';
import TextInputStyle         from '@stateless/atomic/InputWrapper/TextInputSize';
import TextInputTextAlignment from '@stateless/atomic/InputWrapper/TextInputTextAlignment';
import TextInput              from '@stateless/atomic/TextInput';
import TextInputType          from '@stateless/atomic/TextInput/TextInputType';
import DatePicker             from '@stateless/composed/DatePicker';

import styles from './styles.module.scss';

const propTypes = {
    date:     PropTypes.date,
    onChange: PropTypes.func,
    value:    PropTypes.object,
};

const now = new Date();

const DatePickerControl = ({
    date = new Date(),
    onChange = DefaultTypes.func,
    value = {
        year:  now.getFullYear(),
        month: now.getMonth(),
        day:   now.getDate(),
    },
}) => {
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const dateInputRef                        = useRef();
    const openDatePickerClicked               = () => {
        setDatePickerOpen(true);
    };
    const closeClicked                        = () => {
        setDatePickerOpen(false);
    };

    function handleChange(option) {
        onChange(option);
    }

    function formatDateForDateInput(inputDate) {
        const year  = inputDate.toLocaleString(
            'default',
            {
                year: 'numeric',
            },
        );
        const month = inputDate.toLocaleString(
            'default',
            {
                month: '2-digit',
            },
        );
        const day   = inputDate.toLocaleString(
            'default',
            {
                day: '2-digit',
            },
        );

        return `${year}-${month}-${day}`;
    }

    const handleClick = (event) => {
        // needed because of the safari native popup that appears on date input click
        event.preventDefault();
    };

    function handleInputBlur() {
        const dateString = dateInputRef.current.value;

        if (!dateString) {
            dateInputRef.current.value = formatDateForDateInput(date);

            return;
        }

        const [year, month, day] = dateString.split('-');
        const options            = {
            day:   parseInt(day, 10),
            month: parseInt(month, 10),
            year:  parseInt(year, 10),
        };

        handleChange(options);
    }

    let overlayComponent = null;

    if (datePickerOpen) {
        overlayComponent = (
            <div
                className={classNames(
                    styles.overlayContainer,
                    {
                        [styles.overlayContainerOpen]: datePickerOpen,
                    },
                )}
            >
                <div className={styles.overlayContainerInner}>
                    <DatePicker
                        date={value}
                        onChange={handleChange}
                        onClose={closeClicked}
                    />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={styles.datePickerControl}>
                <TextInput
                    value={formatDateForDateInput(date)}
                    onBlur={handleInputBlur}
                    onClick={handleClick}
                    type={TextInputType.date}
                    inputRef={dateInputRef}
                    size={TextInputStyle.bold}
                    textAlignment={TextInputTextAlignment.center}
                />
                <div
                    className={styles.indicatorContainer}
                    onClick={openDatePickerClicked}
                >
                    <div className={styles.wrappedIcon}>
                        <Icon iconType={IconType.chevronRight} />
                    </div>
                </div>
            </div>
            {overlayComponent}
        </>
    );
};

DatePickerControl.propTypes = propTypes;

export default DatePickerControl;
