//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

const userPath = [
    'user',
];

const userProfilePath = [
    ...userPath,
    'userProfile',
];

const userNamePath = [
    ...userPath,
    'name',
];

const userOrganisationPath = [
    ...userProfilePath,
    'organization',
];

const userCustomerPlanPath = [
    ...userOrganisationPath,
    'customerPlan',
];

const userPasswordPath = [
    ...userPath,
    'password',
];

const userFirstnamePath = [
    ...userPath,
    'firstname',
];

const userLastnamePath = [
    ...userPath,
    'lastname',
];

const userConfirmPasswordPath = [
    ...userPath,
    'confirmPassword',
];

export const selectToken = (state) => {
    return _.get(state, [...userPath, 'token']);
};

export const selectEmail = (state) => {
    return _.get(state, [...userPath, 'email']);
};

export const selectStayLoggedIn = (state) => {
    return _.get(state, [...userPath, 'stayLoggedIn']);
};

export const selectPlan        = (state) => {
    return _.get(state, [
        ...userCustomerPlanPath,
        'plan',
    ]);
};
export const selectUserProfile = (state) => {
    return _.get(state, userProfilePath);
};

export const selectOrganization = (state) => {
    return _.get(state, userOrganisationPath);
};

export const selectEmailNotificationsEnabled = (state) => {
    return _.get(state, [...userProfilePath, 'emailNotificationsEnabled']);
};

export const selectCustomerPlan = (state) => {
    return _.get(state, userCustomerPlanPath);
};

export const selectPassword = (state) => {
    return _.get(state, userPasswordPath);
};

export const selectFirstname = (state) => {
    return _.get(state, userFirstnamePath);
};

export const selectLastname = (state) => {
    return _.get(state, userLastnamePath);
};

export const selectConfirmPassword = (state) => {
    return _.get(state, userConfirmPasswordPath);
};

export const selectName = (state) => {
    return _.get(state, userNamePath);
};

export default selectToken;
