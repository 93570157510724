import React from 'react';

import classNames from 'classnames';

import PropTypes     from '@components/PropTypes';
import KeyboardEvent from '@helper/KeyboardEvent';
import Icon          from '@stateless/atomic/Icon';
import IconType      from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    isSelected: PropTypes.bool,
    label:      PropTypes.string,
    onClick:    PropTypes.func,
};

const WrappedIcon = () => {
    return (
        <div className={styles.wrappedIcon}>
            <Icon iconType={IconType.ok} />
        </div>
    );
};

const Value = ({
    label,
    isSelected,
    onClick,
}) => {
    const chevron = isSelected
        ? <WrappedIcon />
        : '';

    function onKeyDown(event) {
        if (KeyboardEvent.isEnterKey(event)) {
            onClick();
        }
    }

    return (
        <div
            className={classNames(styles.value, {
                [styles.isSelected]: isSelected,
            })}
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex={0}
        >
            {chevron} {label}
        </div>
    );
};

Value.propTypes = propTypes;

export default Value;
