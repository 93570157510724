//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import ArticleStatus from '@constants/ArticleStatus';

const getStatus = (pressArticle) => {
    return _.get(pressArticle, 'pressArticleStatus.articleStatus', null);
};

const isInEdit = (pressArticle) => {
    return getStatus(pressArticle) === ArticleStatus.IN_EDIT;
};

const isInGenerate = (pressArticle) => {
    return getStatus(pressArticle) === ArticleStatus.GENERATION_PENDING;
};

const isGenerated = (pressArticle) => {
    return getStatus(pressArticle) === ArticleStatus.GENERATED;
};

const isGenerationPending = (pressArticle) => {
    return getStatus(pressArticle) === ArticleStatus.GENERATION_PENDING;
};

const isTextGenerationPending = (pressArticle) => {
    return getStatus(pressArticle) === ArticleStatus.TEXT_GENERATION_PENDING;
};

const isHeadlineGenerationPending = (pressArticle) => {
    return getStatus(pressArticle) === ArticleStatus.HEADLINE_GENERATION_PENDING;
};

const isGenerationFailed = (pressArticle) => {
    return getStatus(pressArticle) === ArticleStatus.GENERATION_FAILED;
};

const isTextGenerationFailed = (pressArticle) => {
    return getStatus(pressArticle) === ArticleStatus.TEXT_GENERATION_FAILED;
};

const isHeadlineGenerationFailed = (pressArticle) => {
    return getStatus(pressArticle) === ArticleStatus.HEADLINE_GENERATION_FAILED;
};

const isAnyGenerationPending = (pressArticle) => {
    return (
        isTextGenerationPending(pressArticle) ||
        isHeadlineGenerationPending(pressArticle) ||
        isGenerationPending(pressArticle)
    );
};

const getPendingStatusFromFailedStatus = (pressArticle) => {
    if (isTextGenerationFailed(pressArticle)) {
        return ArticleStatus.TEXT_GENERATION_PENDING;
    }

    if (isHeadlineGenerationFailed(pressArticle)) {
        return ArticleStatus.HEADLINE_GENERATION_PENDING;
    }

    if (isGenerationFailed(pressArticle)) {
        return ArticleStatus.GENERATION_PENDING;
    }

    return null;
};

export default {
    getStatus,
    isInEdit,
    isInGenerate,
    isGenerated,
    isGenerationPending,
    isGenerationFailed,
    isTextGenerationPending,
    isTextGenerationFailed,
    isHeadlineGenerationPending,
    isHeadlineGenerationFailed,
    isAnyGenerationPending,
    getPendingStatusFromFailedStatus,
};
