//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                 from '@components/PropTypes';
import OverlayConstants          from '@constants/Overlay';
import { OverlayActions }        from '@slices/overlay';
import { UnitsActions }          from '@slices/units';
import StatelessEditUnitsOverlay from '@stateless/composed/EditUnitsOverlay';
import VehicleListItem           from '@stateless/composed/VehicleListItem';
import selectUnits               from '@store/selectors/units';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const EditOwnUnitsOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch      = useDispatch();
    const units         = useSelector(selectUnits);
    const numberOfUnits = units.length;
    const showHint      = units.length === 0;

    function setUnitInEdit(unit) {
        dispatch(UnitsActions.setUnitInEdit(unit));
    }

    function onAddOwnUnitClicked() {
        dispatch(OverlayActions.openOverlay({
            overlay: OverlayConstants.editOwnUnit,
        }));
    }

    function onEditUnitClicked(unit) {
        return () => {
            dispatch(UnitsActions.setUnitInEdit(unit));
            onAddOwnUnitClicked();
        };
    }

    function onDeleteUnitClicked(id) {
        return () => {
            dispatch(UnitsActions.setUnitInEdit({
                id,
            }));
            dispatch(OverlayActions.openOverlay({
                overlay: OverlayConstants.deleteOwnUnit,
            }));
        };
    }

    function renderUnits() {
        return units.map((unit) => {
            const { id, name, description } = unit;

            return (
                <VehicleListItem
                    key={`own-unit-${id}`}
                    title={name}
                    onDeleteClick={onDeleteUnitClicked(id)}
                    onEditClick={onEditUnitClicked({
                        id,
                        name,
                        description,
                    })}
                />
            );
        });
    }

    return (
        <StatelessEditUnitsOverlay
            onCloseClick={onCloseClick}
            open={open}
            numberOfUnits={numberOfUnits}
            renderUnits={renderUnits}
            onAddUnitClicked={onAddOwnUnitClicked}
            setUnitInEdit={setUnitInEdit}
            buttonText={I18n.t('components.editOwnUnitOverlay.addOwnUnit')}
            showHint={showHint}
            hintText={I18n.t('components.editOwnUnitOverlay.addOwnUnitHint')}
        />
    );
};

EditOwnUnitsOverlay.propTypes = propTypes;

export default EditOwnUnitsOverlay;
