//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes from '@components/PropTypes';
import Overlay   from '@stateless/atomic/Overlay';

import styles from './styles.module.scss';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
    text:         PropTypes.string,
    title:        PropTypes.string,
};

const InfoTextOverlay = ({
    open = false,
    onCloseClick = _.noop,
    text = null,
    title = null,
}) => {
    return (
        <Overlay
            open={open}
            onCloseClick={onCloseClick}
            title={title}
            small={true}
        >
            <div className={styles.infoTextOverlayContainer}>
                <p>
                    {text}
                </p>
            </div>
        </Overlay>
    );
};

InfoTextOverlay.propTypes = propTypes;

export default InfoTextOverlay;
