//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    activateTestAccount: '/activate-test-account',
    registerTestAccount: '/register-test-account',
    register:            '/registrierung',
    externalTaskForces:  '/external-task-forces',
    home:                '/',
    login:               '/login',
    lostPassword:        '/lost-password',
    resetPassword:       '/reset-password',
    invoicesAndPlans:    '/invoices-plans',
};
