//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';
import IconType  from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    active:  PropTypes.bool,
    subText: PropTypes.string,
    text:    PropTypes.string,
};

const PlanFeature = ({
    active = false,
    text = '',
    subText = '',
}) => {
    const iconType = active
        ? IconType.check
        : IconType.cross;

    return (
        <div className={styles.planFeature}>
            <div
                className={classNames(styles.featureStatus, {
                    [styles.active]: active,
                })}
            >
                <Icon iconType={iconType} />
            </div>
            <div className={styles.featureText}>
                <div>
                    {text}
                </div>
                <div>
                    {subText}
                </div>
            </div>
        </div>
    );
};

PlanFeature.propTypes = propTypes;

export default PlanFeature;
