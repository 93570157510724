//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                        from '@components/PropTypes';
import ArticleStatus                    from '@constants/ArticleStatus';
import { AlertBoxActions }              from '@slices/alertBox';
import { CreatePressArticleActions }    from '@slices/createPressArticle';
import { EditPressArticleActions }      from '@slices/editPressArticle';
import StatelessEditPressArticleOverlay from '@stateless/composed/EditPressArticleOverlay';
import selectEditPressArticle           from '@store/selectors/editPressArticle';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const EditPressArticleOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch         = useDispatch();
    const pressArticle     = useSelector(selectEditPressArticle);
    const headline         = _.get(pressArticle, 'articleHeadline');
    const text             = _.get(pressArticle, 'articleText');
    const headlineTagEnd   = '</strong></h1>';
    const headlineTagStart = '<h1><strong>';
    const fullText         = `${headlineTagStart}${headline}${headlineTagEnd}${text}`;

    function onTextChanged(htmlText) {
        if (
            _.includes(htmlText, headlineTagStart) &&
            _.includes(htmlText, headlineTagEnd)
        ) {
            const changedHeadline = htmlText.split(headlineTagStart)[1].split(headlineTagEnd)[0];
            const changedText     = htmlText.split(headlineTagEnd)[1];

            dispatch(AlertBoxActions.clearAlerts());
            dispatch(EditPressArticleActions.setChangedText({
                text:     changedText,
                headline: changedHeadline,
            }));
        }
    }

    function onUpdateClicked() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(EditPressArticleActions.save());
    }

    function onDeleteClicked() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(EditPressArticleActions.delete());
    }

    function onGenerateHeadlineClicked() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(CreatePressArticleActions.loadCreatePressArticle({
            pressArticle,
        }));
        dispatch(CreatePressArticleActions.createPressArticle({
            targetStatus: ArticleStatus.HEADLINE_GENERATION_PENDING,
        }));
    }

    function onEditClicked() {
        dispatch(EditPressArticleActions.openCreateDialogToEditData());
    }

    return (
        <StatelessEditPressArticleOverlay
            onCloseClick={onCloseClick}
            onTextChange={onTextChanged}
            open={open}
            onUpdateClick={onUpdateClicked}
            onDeleteClick={onDeleteClicked}
            onGenerateHeadlineClick={onGenerateHeadlineClicked}
            onEditClick={onEditClicked}
            text={fullText}
        />
    );
};

EditPressArticleOverlay.propTypes = propTypes;

export default EditPressArticleOverlay;
