//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import DefaultTypes  from '@components/DefaultTypes';
import PropTypes     from '@components/PropTypes';
import StringHelper  from '@helper/String';
import Headline      from '@stateless/atomic/Headline';
import HeadlineColor from '@stateless/atomic/Headline/HeadlineColor';
import Icon          from '@stateless/atomic/Icon';
import IconType      from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    iconType: PropTypes.iconType,
    onClick:  PropTypes.func,
    title:    PropTypes.string,
};

const SideMenuButton = ({
    iconType = IconType.menuArrow,
    onClick = DefaultTypes.func,
    title = '',
}) => {
    return (
        <div
            className={styles.sideMenuButton}
            onClick={onClick}
        >
            <div className={styles.sideMenuButtonIcon}>
                <Icon
                    iconType={iconType}
                />
            </div>
            <Headline
                title={StringHelper.slashLineBreak(title)}
                color={HeadlineColor.white}
            />
        </div>
    );
};

SideMenuButton.propTypes = propTypes;

export default SideMenuButton;
