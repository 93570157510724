export default Object.freeze({
    IN_EDIT:                     'IN_EDIT',
    IN_GENERATE:                 'IN_GENERATE',
    GENERATION_PENDING:          'GENERATION_PENDING',
    TEXT_GENERATION_PENDING:     'TEXT_GENERATION_PENDING',
    HEADLINE_GENERATION_PENDING: 'HEADLINE_GENERATION_PENDING',
    TEXT_GENERATION_FAILED:      'TEXT_GENERATION_FAILED',
    HEADLINE_GENERATION_FAILED:  'HEADLINE_GENERATION_FAILED',
    GENERATION_FAILED:           'GENERATION_FAILED',
    GENERATED:                   'GENERATED',
});
