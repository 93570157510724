//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    createPressArticle:         'createPressArticle',
    editExternalUnits:          'editExternalUnits',
    editOwnUnits:               'editOwnUnits',
    deleteOwnUnit:              'deleteOwnUnit',
    deleteExternalUnit:         'deleteExternalUnit',
    editParticularities:        'editParticularities',
    editParticularity:          'editParticularity',
    deleteParticularity:        'deleteParticularity',
    regeneratePressArticle:     'regeneratePressArticle',
    editVehicles:               'editVehicles',
    deleteDraft:                'deleteDraft',
    deleteVehicle:              'deleteVehicle',
    editOwnUnit:                'editOwnUnit',
    editPressArticle:           'editPressArticle',
    confirmExit:                'confirmExit',
    saveDraft:                  'saveDraft',
    mobileMenu:                 'mobileMenu',
    profileSettings:            'profileSettings',
    userSettings:               'userSettings',
    editVehicle:                'editVehicle',
    editExternalUnit:           'editExternalUnit',
    confirmPaidPlanTermination: 'confirmPaidPlanTermination',
    successfullyTermination:    'successfullyTermination',
});
