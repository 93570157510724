//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes              from '@components/PropTypes';
import Icon                   from '@stateless/atomic/Icon';
import TextInputStyle         from '@stateless/atomic/InputWrapper/TextInputSize';
import TextInputTextAlignment from '@stateless/atomic/InputWrapper/TextInputTextAlignment';

import styles from './styles.module.scss';

const propTypes = {
    children:      PropTypes.children,
    postIconType:  PropTypes.iconType,
    preIconType:   PropTypes.iconType,
    size:          PropTypes.oneOfObjectValues(TextInputStyle),
    textAlignment: PropTypes.oneOfObjectValues(TextInputTextAlignment),
};

const InputWrapper = ({
    children = null,
    postIconType = null,
    preIconType = null,
    size = TextInputStyle.default,
    textAlignment = TextInputTextAlignment.left,
}) => {
    function renderIcon(icon, className) {
        if (icon) {
            return (
                <div className={className}>
                    <Icon
                        iconType={preIconType}
                    />
                </div>
            );
        }

        return null;
    }

    return (
        <div
            className={classNames(
                styles.textInputWrapper,
                {
                    [styles.textInputWrapperSmall]:               size === TextInputStyle.small,
                    [styles.textInputWrapperTextAlignmentLeft]:   textAlignment === TextInputTextAlignment.left,
                    [styles.textInputWrapperTextAlignmentRight]:  textAlignment === TextInputTextAlignment.right,
                    [styles.textInputWrapperTextAlignmentCenter]: textAlignment === TextInputTextAlignment.center,
                },
            )}
        >
            {renderIcon(preIconType, styles.preIconWrapper)}
            {children}
            {renderIcon(postIconType, styles.postIconWrapper)}
        </div>
    );
};

InputWrapper.propTypes = propTypes;

export default InputWrapper;
