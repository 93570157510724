//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Adsense }     from '@ctrl/react-adsense';
import classNames      from 'classnames';
import _               from 'lodash';
import { useSelector } from 'react-redux';

import PropTypes              from '@components/PropTypes';
import OrganizationType       from '@constants/OrganizationType';
import Environment            from '@helper/Environment';
import AdBannerSlot           from '@stateless/atomic/AdBanner/AdBannerSlot';
import { selectOrganization } from '@store/selectors/user';

import styles from './styles.module.scss';

const propTypes = {
    slot: PropTypes.oneOfObjectKeys(AdBannerSlot),
};

const AdBanner = ({
    slot = null,
}) => {
    const organization      = useSelector(selectOrganization);
    const type              = _.get(organization, 'type');
    const client            = 'ca-pub-5955506694308109';
    const smallSlots        = [
        AdBannerSlot.LIST_BANNER,
        AdBannerSlot.ARCHIVE_BANNER,
    ];
    const bigSlots          = [
        AdBannerSlot.STEP_3_BANNER,
        AdBannerSlot.STEP_7_BANNER,
    ];
    const invalidParameters = (
        type !== OrganizationType.FREE ||
        !slot
    );

    function getInlineStyle() {
        const style = {
            display: 'block',
            height:  '100%',
        };

        if (Environment.isDevelopment()) {
            style.border = '1px solid red';
        }

        return style;
    }

    function renderBanner() {
        if (invalidParameters) {
            return null;
        }

        const style = getInlineStyle();

        return (
            <div
                className={classNames(
                    styles.adBanner,
                    {
                        [styles.small]:  smallSlots.includes(slot),
                        [styles.big]:    bigSlots.includes(slot),
                        [styles.medium]: slot === AdBannerSlot.HOME_RIGHT_BANNER,
                    },
                )}
            >
                <Adsense
                    style={style}
                    client={client}
                    slot={slot}
                    format="fluid"
                />
            </div>
        );
    }

    return renderBanner();
};

AdBanner.propTypes = propTypes;

export default AdBanner;
