//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    leftItem:          PropTypes.object,
    onSelectionChange: PropTypes.func,
    rightItem:         PropTypes.object,
    selectedValue:     PropTypes.string,
};

const TextSwitch = ({
    onSelectionChange = _.noop,
    selectedValue = '',
    leftItem = {},
    rightItem = {},
}) => {
    const onItemClicked = (value) => () => {
        onSelectionChange(value);
    };

    function renderLabel(label) {
        if (!label) {
            return null;
        }

        return (
            <div className={styles.itemLabel}>
                <span>
                    {label}
                </span>
            </div>
        );
    }

    function renderItems() {
        const items = [
            leftItem,
            rightItem,
        ];

        return _.map(items, (item) => {
            return (
                <div
                    className={classNames(
                        styles.switchItem,
                        {
                            [styles.active]: selectedValue === item.value,
                        },
                    )}
                    onClick={onItemClicked(item.value)}
                >
                    {item.text}
                    {renderLabel(item.label)}
                </div>
            );
        });
    }

    return (
        <div className={styles.textSwitch}>
            {renderItems()}
        </div>
    );
};

TextSwitch.propTypes = propTypes;

export default TextSwitch;
