//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes    from '@components/PropTypes';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';
import IconType     from '@stateless/atomic/Icon/IconType';
import IconButton   from '@stateless/composed/IconButton';

import styles from './styles.module.scss';

const propTypes = {
    onDeleteClick: PropTypes.func,
    onEditClick:   PropTypes.func,
    subtitle:      PropTypes.string,
    title:         PropTypes.string,
};

const VehicleListItem = ({
    onDeleteClick = null,
    onEditClick = null,
    subtitle = '',
    title = '',
}) => {
    return (
        <div className={styles.vehicleListItem}>
            <div className={styles.vehicleListItemText}>
                <Headline
                    type={HeadlineType.headline3}
                    title={title}
                />
                <p>
                    {subtitle}
                </p>
            </div>
            <div className={styles.vehicleListItemButtons}>
                <IconButton
                    onClick={onEditClick}
                    iconType={IconType.editMany}
                />
                <IconButton
                    onClick={onDeleteClick}
                    iconType={IconType.delete}
                />
            </div>
        </div>
    );
};

VehicleListItem.propTypes = propTypes;

export default VehicleListItem;
