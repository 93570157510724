//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import UrlHelper from '@helper/Url';

class AvatarHelper {
    static getAvatar(user) {
        const avatar         = user?.avatar;
        const uploadedAvatar = user?.uploadedAvatar;

        if (uploadedAvatar) {
            return uploadedAvatar;
        }

        if (avatar?.path) {
            return UrlHelper.backendImage(avatar?.path);
        }

        return null;
    }
}

export default AvatarHelper;
