//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React        from 'react';
import { useState } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                    from '@components/PropTypes';
import { AlertBoxActions }          from '@slices/alertBox';
import { ExternalUnitsActions }     from '@slices/externalUnits';
import StatelessEditUnitOverlay     from '@stateless/composed/EditUnitOverlay';
import { selectExternalUnitInEdit } from '@store/selectors/externalUnits';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const EditOwnUnitOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch                = useDispatch();
    const externalUnitInEdit      = useSelector(selectExternalUnitInEdit);
    const [title, setTitle]       = useState(_.get(externalUnitInEdit, 'name'));
    const [subtitle, setSubtitle] = useState(_.get(externalUnitInEdit, 'description'));

    function onSaveClick() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(ExternalUnitsActions.updateUnit({
            ...externalUnitInEdit,
            name:        title,
            description: subtitle,
        }));
    }

    function getOverlayTitle() {
        return externalUnitInEdit ?
            I18n.t('components.editExternalUnitsOverlay.editExternalUnitTitle') :
            I18n.t('components.editExternalUnitsOverlay.addExternalUnitTitle');
    }

    function getOverlayButtonText() {
        return externalUnitInEdit ?
            I18n.t('components.editExternalUnitsOverlay.editExternalUnitButton') :
            I18n.t('components.editExternalUnitsOverlay.addExternalUnitButton');
    }

    return (
        <StatelessEditUnitOverlay
            onCloseClick={onCloseClick}
            open={open}
            title={title}
            subtitle={subtitle}
            titlePlaceholder={I18n.t('components.editExternalUnitsOverlay.titlePlaceholder')}
            setTitle={setTitle}
            setSubtitle={setSubtitle}
            unitInEdit={externalUnitInEdit}
            noSubtitle={true}
            onSaveClick={onSaveClick}
            overlayButtonText={getOverlayButtonText()}
            overlayTitle={getOverlayTitle()}
        />
    );
};

EditOwnUnitOverlay.propTypes = propTypes;

export default EditOwnUnitOverlay;
