//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';
import _    from 'lodash';

class ProfileHelper {
    static getFullName(user) {
        const lastname  = _.get(user, 'name');
        const firstname = _.get(user, 'firstname');

        if (
            _.isEmpty(firstname) &&
            _.isEmpty(lastname)
        ) {
            return I18n.t('unknown');
        }

        if (_.isEmpty(firstname)) {
            return lastname;
        }

        if (_.isEmpty(lastname)) {
            return firstname;
        }

        return `${firstname} ${lastname}`;
    }

    static getLastName(user) {
        const lastname = _.get(user, 'name');

        if (_.isEmpty(lastname)) {
            return this.getFullName(user);
        }

        return lastname;
    }

    static getFirstName(user) {
        const firstname = _.get(user, 'firstname');

        if (_.isEmpty(firstname)) {
            return this.getFullName(user);
        }

        return firstname;
    }
}

export default ProfileHelper;
