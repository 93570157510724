//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { convertFromHTML } from 'draft-convert';
import { convertToHTML }   from 'draft-convert';
import I18n                from 'i18next';
import PropTypes           from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch }     from 'react-redux';

import DefaultTypes          from '@components/DefaultTypes';
import AlertBoxManager       from '@connected/AlertBoxManager';
import OverlayConstants      from '@constants/Overlay';
import Notification          from '@helper/Notification';
import StringHelper          from '@helper/String';
import UserAgentHelper       from '@helper/UserAgentHelper';
import { OverlayActions }    from '@slices/overlay';
import IconType              from '@stateless/atomic/Icon/IconType';
import Overlay               from '@stateless/atomic/Overlay';
import TextEditor            from '@stateless/atomic/TextEditor';
import ColorButton           from '@stateless/composed/ColorButton';
import ColorButtonColorTheme from '@stateless/composed/ColorButton/ColorButtonColorTheme';
import ColorButtonFontTheme  from '@stateless/composed/ColorButton/ColorButtonFontTheme';

import styles from './styles.module.scss';

const propTypes = {
    onCloseClick:            PropTypes.func,
    onDeleteClick:           PropTypes.func,
    onEditClick:             PropTypes.func,
    onGenerateHeadlineClick: PropTypes.func,
    onTextChange:            PropTypes.func,
    onUpdateClick:           PropTypes.func,
    open:                    PropTypes.bool,
    text:                    PropTypes.string,
};

const EditPressArticleOverlay = ({
    onCloseClick = DefaultTypes.func,
    onDeleteClick = DefaultTypes.func,
    onGenerateHeadlineClick = DefaultTypes.func,
    onTextChange = DefaultTypes.func,
    onUpdateClick = DefaultTypes.func,
    onEditClick = DefaultTypes.func,
    open = false,
    text = '',
}) => {
    const baseTranslationPath       = 'components.editPressArticleOverlay.';
    const dispatch                  = useDispatch();
    const useraAgentIsAndroidChrome = (
        UserAgentHelper.isAndroid() &&
        UserAgentHelper.isChrome()
    );

    function onCopyTextClicked() {
        console.log('onCopyTextClicked: ', navigator.userAgent);
        Notification.success('textCopied');
    }

    function onRegenerateClick() {
        dispatch(OverlayActions.openOverlay({
            overlay: OverlayConstants.regeneratePressArticle,
        }));
    }

    function adjustCopyTextRemoveHtml() {
        const removedHtmlText = StringHelper.removeHtmlTags(text);

        return removedHtmlText;
    }

    function adjustCopyTextParseHtml() {
        const replacedNewLinesText = StringHelper.replaceAllNewLinesWithBreak(text);
        const convertedText        = convertFromHTML(replacedNewLinesText);

        return convertToHTML(convertedText);
    }

    function adjustCopyText() {
        const adjustedText = (
            useraAgentIsAndroidChrome ?
                adjustCopyTextRemoveHtml() :
                adjustCopyTextParseHtml()
        );

        return adjustedText;
    }

    const format = (
        useraAgentIsAndroidChrome ?
            'text/plain' :
            'text/html'
    );

    return (
        <Overlay
            onCloseClick={onCloseClick}
            open={open}
        >
            <div className={styles.editPressArticleOverlay}>
                <div className={styles.buttonRow}>
                    <CopyToClipboard
                        text={adjustCopyText()}
                        onCopy={onCopyTextClicked}
                        options={{
                            format,
                        }}
                    >
                        <ColorButton
                            text={I18n.t(`${baseTranslationPath}copyText`)}
                            colorTheme={ColorButtonColorTheme.white}
                            fontTheme={ColorButtonFontTheme.small}
                            iconTypeLeft={IconType.documents}
                        />
                    </CopyToClipboard>
                    <ColorButton
                        text={I18n.t(`${baseTranslationPath}generateHeadline`)}
                        colorTheme={ColorButtonColorTheme.white}
                        fontTheme={ColorButtonFontTheme.small}
                        iconTypeLeft={IconType.news}
                        onClick={onGenerateHeadlineClick}
                    />
                    <ColorButton
                        text={I18n.t(`${baseTranslationPath}regenerateText`)}
                        colorTheme={ColorButtonColorTheme.white}
                        fontTheme={ColorButtonFontTheme.small}
                        iconTypeLeft={IconType.news}
                        onClick={onRegenerateClick}
                    />
                    <ColorButton
                        text={I18n.t(`${baseTranslationPath}editData`)}
                        colorTheme={ColorButtonColorTheme.white}
                        fontTheme={ColorButtonFontTheme.small}
                        iconTypeLeft={IconType.editMany}
                        onClick={onEditClick}
                    />
                </div>
                <div className={styles.editPressArticleOverlayContentContainer}>
                    <TextEditor
                        onChange={onTextChange}
                        text={StringHelper.replaceAllNewLinesWithBreak(text)}
                        open={open}
                    />
                    <div className={styles.alertBoxManagerWrapper}>
                        <AlertBoxManager />
                    </div>
                    <div className={styles.buttonRow}>
                        <ColorButton
                            text={I18n.t(`${baseTranslationPath}delete`)}
                            colorTheme={ColorButtonColorTheme.red}
                            fontTheme={ColorButtonFontTheme.small}
                            iconTypeLeft={IconType.delete}
                            onClick={onDeleteClick}
                        />
                        <ColorButton
                            text={I18n.t(`${baseTranslationPath}update`)}
                            colorTheme={ColorButtonColorTheme.green}
                            fontTheme={ColorButtonFontTheme.small}
                            iconTypeLeft={IconType.sync}
                            onClick={onUpdateClick}
                        />
                    </div>
                </div>
            </div>
        </Overlay>
    );
};

EditPressArticleOverlay.propTypes = propTypes;

export default EditPressArticleOverlay;
