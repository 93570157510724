//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                               from '@components/PropTypes';
import ArticleStatus                           from '@constants/ArticleStatus';
import OrganizationType                        from '@constants/OrganizationType';
import Overlay                                 from '@constants/Overlay';
import { AlertBoxActions }                     from '@slices/alertBox';
import { CreatePressArticleActions }           from '@slices/createPressArticle';
import { OverlayActions }                      from '@slices/overlay';
import { ParticularitiesActions }              from '@slices/particularities';
import StatelessPressArticleOverlay            from '@stateless/composed/PressArticleOverlay';
import selectStep                              from '@store/selectors/createPressArticle';
import { selectMissionDetail }                 from '@store/selectors/createPressArticle';
import { selectedUnitIds }                     from '@store/selectors/createPressArticle';
import { selectedExternalEmergencyServiceIds } from '@store/selectors/createPressArticle';
import { selectCreatedWithAssistant }          from '@store/selectors/createPressArticle';
import { selectIri }                           from '@store/selectors/createPressArticle';
import { selectedVehicleIds }                  from '@store/selectors/createPressArticle';
import { selectSelectedParticularities }       from '@store/selectors/createPressArticle';
import { selectArticleStatus }                 from '@store/selectors/createPressArticle';
import selectExternalEmergency                 from '@store/selectors/externalUnits';
import selectParticularities                   from '@store/selectors/particularities';
import selectUnits                             from '@store/selectors/units';
import { selectOrganization }                  from '@store/selectors/user';
import selectVehicles                          from '@store/selectors/vehicles';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const PressArticleOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch                              = useDispatch();
    const iri                                   = useSelector(selectIri);
    const status                                = useSelector(selectArticleStatus);
    const step                                  = useSelector(selectStep);
    const createdWithAssistant                  = useSelector(selectCreatedWithAssistant);
    const missionDetail                         = useSelector(selectMissionDetail);
    const selectedUnits                         = useSelector(selectedUnitIds);
    const particularities                       = useSelector(selectParticularities);
    const selectedVehicles                      = useSelector(selectedVehicleIds);
    const selectedExternalEmergency             = useSelector(selectedExternalEmergencyServiceIds);
    const selectedParticularities               = useSelector(selectSelectedParticularities);
    const units                                 = useSelector(selectUnits);
    const vehicles                              = useSelector(selectVehicles);
    const organization                          = useSelector(selectOrganization);
    const externalEmergencyServices             = useSelector(selectExternalEmergency);
    const organizationType                      = _.get(organization, 'type', null);
    const unitsWithSelected                     = units.map((unit) => (
        {
            ...unit,
            selected: selectedUnits.includes(unit.id),
        }
    ));
    const vehiclesWithSelected                  = vehicles.map((vehicle) => (
        {
            ...vehicle,
            selected: selectedVehicles.includes(vehicle.id),
        }
    ));
    const externalEmergencyServicesWithSelected = externalEmergencyServices.map((externalEmergencyService) => (
        {
            ...externalEmergencyService,
            selected: selectedExternalEmergency.includes(externalEmergencyService.id),
        }
    ));
    const particularitiesWithSelected           = particularities.map((particularity) => (
        {
            ...particularity,
            selected: selectedParticularities.includes(particularity.id),
        }
    ));

    function onUpdateStep(newStep) {
        dispatch(CreatePressArticleActions.setStep({
            step: newStep,
        }));
    }

    function onUpdateCreatedWithAssistant(newCreatedWithAssistant) {
        dispatch(CreatePressArticleActions.setCreatedWithAssistant({
            createdWithAssistant: newCreatedWithAssistant,
        }));
    }

    function onUpdateMissionDetail(newMissionDetail) {
        dispatch(CreatePressArticleActions.setMissionDetail({
            missionDetail: newMissionDetail,
        }));
    }

    function onUpdateUnits(newUnits) {
        dispatch(CreatePressArticleActions.setUnits({
            units: newUnits,
        }));
    }

    function onUpdateVehicles(newVehicles) {
        dispatch(CreatePressArticleActions.setVehicles({
            vehicles: newVehicles,
        }));
    }

    function onUpdateExternalEmergencyServices(newExternalEmergencyServices) {
        dispatch(CreatePressArticleActions.setExternalEmergencyServices({
            externalEmergencyServices: newExternalEmergencyServices,
        }));
    }

    function onUpdateParticularities(newParticularities) {
        dispatch(CreatePressArticleActions.setParticularities({
            particularities: newParticularities,
        }));
    }

    function onGeneratePressArticle() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(CreatePressArticleActions.createPressArticle({
            targetStatus: ArticleStatus.GENERATION_PENDING,
        }));
    }

    function onSavePressArticle() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(CreatePressArticleActions.createPressArticle());
    }

    function onEditOwnUnitsOverlayClicked() {
        dispatch(OverlayActions.openOverlay({
            overlay: Overlay.editOwnUnit,
        }));
    }

    function onEditVehiclesOverlayClicked() {
        dispatch(OverlayActions.openOverlay({
            overlay: Overlay.editVehicle,
        }));
    }

    function onEditExternalUnitsOverlayClicked() {
        dispatch(OverlayActions.openOverlay({
            overlay: Overlay.editExternalUnit,
        }));
    }

    function onEditParticularitiesOverlayClicked() {
        const missionDetailParticularity = _.get(missionDetail, 'particularities');

        dispatch(ParticularitiesActions.setParticularityInEdit({
            phrase: missionDetailParticularity,
        }));
        dispatch(OverlayActions.openOverlay({
            overlay: Overlay.editParticularity,
        }));
    }

    return (
        <StatelessPressArticleOverlay
            step={step}
            open={open}
            generatePressArticle={onGeneratePressArticle}
            savePressArticle={onSavePressArticle}
            updateStep={onUpdateStep}
            createdWithAssistant={createdWithAssistant}
            particularities={particularitiesWithSelected}
            updateParticularities={onUpdateParticularities}
            updateCreatedWithAssistant={onUpdateCreatedWithAssistant}
            updateMissionDetail={onUpdateMissionDetail}
            updateUnits={onUpdateUnits}
            updateVehicles={onUpdateVehicles}
            enableAds={organizationType === OrganizationType.FREE}
            updateExternalEmergencyServices={onUpdateExternalEmergencyServices}
            missionDetail={missionDetail}
            units={unitsWithSelected}
            vehicles={vehiclesWithSelected}
            externalEmergencyServices={externalEmergencyServicesWithSelected}
            iri={iri}
            status={status}
            onEditOwnUnitsOverlayClick={onEditOwnUnitsOverlayClicked}
            onEditVehiclesOverlayClicked={onEditVehiclesOverlayClicked}
            onEditExternalUnitsOverlayClick={onEditExternalUnitsOverlayClicked}
            onEditParticularitiesOverlayClick={onEditParticularitiesOverlayClicked}
            onCloseClick={onCloseClick}
        />
    );
};

PressArticleOverlay.propTypes = propTypes;

export default PressArticleOverlay;
