//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';
import IconType  from '@stateless/atomic/Icon/IconType';
import Link      from '@stateless/atomic/Link';

import styles from './styles.module.scss';

const propTypes = {
    iconType: PropTypes.oneOfObjectValues(IconType).isRequired,
    onClick:  PropTypes.func,
    size:     PropTypes.number,
    to:       PropTypes.string,
};

const IconButton = ({
    iconType,
    onClick,
    size = 32,
    to,
}) => {
    const sizeStyle   = {
        height: `${size}px`,
        width:  `${size}px`,
    };
    const iconClasses = classNames(
        styles.icon,
        sizeStyle,
        {
            [styles.clickable]: to || onClick,
        },
    );
    const icon        = (
        <Icon
            className={iconClasses}
            iconType={iconType}
            onClick={onClick}
        />
    );
    const wrappedIcon = (
        <div
            className={styles.wrapper}
            style={sizeStyle}
        >
            {icon}
        </div>
    );

    if (to) {
        return (
            <Link to={to}>
                {wrappedIcon}
            </Link>
        );
    }

    return wrappedIcon;
};

IconButton.propTypes = propTypes;

export default IconButton;
