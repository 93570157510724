//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Trans } from 'react-i18next';

import PropTypes  from '@components/PropTypes';
import PlanUnit   from '@constants/PlanUnit';
import Plans      from '@constants/Plans';
import PlanCircle from '@stateless/atomic/PlanCircle';
import Spacer     from '@stateless/atomic/Spacer';
import Colors     from '@styles/colors.scss';

import styles from './styles.module.scss';

const propTypes = {
    colorMap: PropTypes.object,
    current:  PropTypes.number,
    maximum:  PropTypes.number,
    minimum:  PropTypes.number,
    plan:     PropTypes.string,
    planUnit: PropTypes.oneOfObjectValues(PlanUnit),
};

const PlanStatus = ({
    minimum = 0,
    maximum = 100,
    current = 100,
    colorMap = {
        0:   Colors.colorPlanRed,
        50:  Colors.colorPlanYellow,
        100: Colors.colorPlanGreen,
    },
    plan = Plans.standard,
    planUnit = PlanUnit.WORDS,
}) => {
    function calculatePercentage() {
        // @formatter:off
        // eslint-disable-next-line no-mixed-operators -- This is a calculation
        const tempPercentage = 100 * (current - minimum) / (maximum - minimum);

        if (tempPercentage > 100) {
            return 100;
        }

        if (tempPercentage < 0) {
            return 0;
        }

        return tempPercentage;
    }

    function getInCircleText() {
        let textKey = 'components.yourPlan.';

        switch (planUnit) {
            case PlanUnit.ARTICLES:
                textKey = `${textKey}articles`;

                break;
            case PlanUnit.WORDS:
            default:
                textKey = `${textKey}words`;

                break;
        }

        return (
            <Trans i18nKey={textKey} />
        );
    }

    let color = Colors.colorBlack;

    for (const key in colorMap) {
        if (calculatePercentage() >= key) {
            color = colorMap[key];
        }
    }

    return (
        <div className={styles.planStatus}>
            <div className={styles.header}>
                <Trans i18nKey={'components.yourPlan.header'} />
            </div>
            <Spacer height={20} />
            <div className={styles.wordsLeftContainer}>
                <PlanCircle
                    color={color}
                    percentage={calculatePercentage()}
                    size={200}
                />
                <div className={styles.wordsLeftInner}>
                    <div className={styles.percentage}>
                        {current.toLocaleString()}
                    </div>
                    {getInCircleText()}
                </div>
            </div>
            <Spacer height={30} />
            <div className={styles.currentPlan}>
                {plan}
            </div>
        </div>
    );
};

PlanStatus.propTypes = propTypes;

export default PlanStatus;
