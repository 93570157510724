//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React        from 'react';
import { useState } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                from '@components/PropTypes';
import { AlertBoxActions }      from '@slices/alertBox';
import { UnitsActions }         from '@slices/units';
import StatelessEditUnitOverlay from '@stateless/composed/EditUnitOverlay';
import { selectUnitInEdit }     from '@store/selectors/units';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const EditOwnUnitOverlay = ({
    onCloseClick = _.noop,
    open = false,
}) => {
    const dispatch                = useDispatch();
    const unitInEdit              = useSelector(selectUnitInEdit);
    const [title, setTitle]       = useState(_.get(unitInEdit, 'name'));
    const [subtitle, setSubtitle] = useState(_.get(unitInEdit, 'description'));

    function onSaveClick() {
        dispatch(AlertBoxActions.clearAlerts());
        dispatch(UnitsActions.updateUnit({
            ...unitInEdit,
            name:        title,
            description: subtitle,
        }));
    }

    function getOverlayTitle() {
        return unitInEdit ?
            I18n.t('components.editUnitsOverlay.editUnitTitle') :
            I18n.t('components.editUnitsOverlay.addUnitTitle');
    }

    function getOverlayButtonText() {
        return unitInEdit ?
            I18n.t('components.editUnitsOverlay.editUnitButton') :
            I18n.t('components.editUnitsOverlay.addUnitButton');
    }

    return (
        <StatelessEditUnitOverlay
            onCloseClick={onCloseClick}
            open={open}
            title={title}
            subtitle={subtitle}
            unitInEdit={unitInEdit}
            titlePlaceholder={I18n.t('components.editUnitsOverlay.titlePlaceholder')}
            setTitle={setTitle}
            setSubtitle={setSubtitle}
            noSubtitle={true}
            onSaveClick={onSaveClick}
            overlayButtonText={getOverlayButtonText()}
            overlayTitle={getOverlayTitle()}
        />
    );
};

EditOwnUnitOverlay.propTypes = propTypes;

export default EditOwnUnitOverlay;
