//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { StatusCodes } from 'http-status-codes';
import { REHYDRATE }   from 'redux-persist';

import { Api }         from '@api/index';
import Routes          from '@constants/Routes';
import { UserActions } from '@slices/user';
import selectPathname  from '@store/selectors/router';

function apiMiddleware() {
    return ({ dispatch, getState }) => (next) => (action) => {
        if (
            action.key === 'root' &&
            action.type === REHYDRATE
        ) {
            const checkForInvalidTokenMonitor = (response) => {
                const path = selectPathname(getState());

                if (
                    response.status === StatusCodes.UNAUTHORIZED &&
                    path !== Routes.login
                ) {
                    dispatch(UserActions.logout());
                }
            };

            Api.addMonitor(checkForInvalidTokenMonitor);
        }

        return next(action);
    };
}

export default apiMiddleware;
