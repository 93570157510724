//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import Firefighter  from '@assets/images/firefighter.svg';
import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';

import styles from './styles.module.scss';

const propTypes = {
    active:        PropTypes.bool,
    buttonClicked: PropTypes.func,
    inactive:      PropTypes.bool,
    title:         PropTypes.string,
};

const AssistantSelectionButton = ({
    active = false,
    buttonClicked = DefaultTypes.func,
    inactive = false,
    title = '',
}) => {
    return (
        <button
            className={classNames(
                styles.assistantSelectionButton,
                {
                    [styles.inactive]: inactive,
                    [styles.active]:   active,
                },
            )}
            onClick={buttonClicked}
            autoFocus
        >
            <div className={styles.assistantSelectionButtonImage}>
                <Firefighter />
            </div>
            <div className={styles.assistantSelectionButtonText}>
                <Headline
                    type={HeadlineType.headline2}
                    title={title}
                />
            </div>
        </button>
    );
};

AssistantSelectionButton.propTypes = propTypes;

export default AssistantSelectionButton;
