//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import ConfirmExitOverlay                from '@connected/ConfirmExitOverlay';
import ConfirmPaidPlanTerminationOverlay from '@connected/ConfirmPaidPlanTerminationOverlay';
import DeleteDraftOverlay                from '@connected/DeleteDraftOverlay';
import DeleteExternalUnitOverlay         from '@connected/DeleteExternalUnitOverlay';
import DeleteOwnUnitOverlay              from '@connected/DeleteOwnUnitOverlay';
import DeleteParticularityOverlay        from '@connected/DeleteParticularityOverlay';
import DeleteVehicleOverlay              from '@connected/DeleteVehicleOverlay';
import EditExternalUnitOverlay           from '@connected/EditExternalUnitOverlay';
import EditExternalUnitsOverlay          from '@connected/EditExternalUnitsOverlay';
import EditOwnUnitOverlay                from '@connected/EditOwnUnitOverlay';
import EditOwnUnitsOverlay               from '@connected/EditOwnUnitsOverlay';
import EditParticularitiesOverlay        from '@connected/EditParticularitiesOverlay';
import EditParticularityOverlay          from '@connected/EditParticularityOverlay';
import EditPressArticleOverlay           from '@connected/EditPressArticleOverlay';
import EditVehicleOverlay                from '@connected/EditVehicleOverlay';
import EditVehiclesOverlay               from '@connected/EditVehiclesOverlay';
import MobileMenuOverlay                 from '@connected/MobileMenuOverlay';
import PressArticleOverlay               from '@connected/PressArticleOverlay';
import ProfileSettingsOverlay            from '@connected/ProfileSettingsOverlay';
import RegeneratePressArticleOverlay     from '@connected/RegeneratePressArticleOverlay';
import SaveDraftOverlay                  from '@connected/SaveDraftOverlay';
import SuccessfullyTerminationOverlay    from '@connected/SuccessfullyTerminationOverlay';
import UserSettingsOverlay               from '@connected/UserSettingsOverlay';
import Overlay                           from '@constants/Overlay';
import KeyboardEvent                     from '@helper/KeyboardEvent';
import { AlertBoxActions }               from '@slices/alertBox';
import { OverlayActions }                from '@slices/overlay';
import selectOverlay                     from '@store/selectors/overlay';

const OverlayManger = () => {
    const dispatch       = useDispatch();
    const currentOverlay = useSelector(selectOverlay);
    const overlayMapping = {
        // eslint-disable react/react-in-jsx-scope
        [Overlay.createPressArticle]:         <PressArticleOverlay saveDraftOnExit={true} />,
        [Overlay.editExternalUnits]:          <EditExternalUnitsOverlay />,
        [Overlay.editOwnUnits]:               <EditOwnUnitsOverlay />,
        [Overlay.deleteOwnUnit]:              <DeleteOwnUnitOverlay />,
        [Overlay.deleteVehicle]:              <DeleteVehicleOverlay />,
        [Overlay.deleteExternalUnit]:         <DeleteExternalUnitOverlay />,
        [Overlay.regeneratePressArticle]:     <RegeneratePressArticleOverlay />,
        [Overlay.editVehicle]:                <EditVehicleOverlay />,
        [Overlay.editOwnUnit]:                <EditOwnUnitOverlay />,
        [Overlay.editVehicles]:               <EditVehiclesOverlay />,
        [Overlay.deleteDraft]:                <DeleteDraftOverlay />,
        [Overlay.editPressArticle]:           <EditPressArticleOverlay />,
        [Overlay.mobileMenu]:                 <MobileMenuOverlay />,
        [Overlay.profileSettings]:            <ProfileSettingsOverlay />,
        [Overlay.userSettings]:               <UserSettingsOverlay />,
        [Overlay.editExternalUnit]:           <EditExternalUnitOverlay />,
        [Overlay.confirmExit]:                <ConfirmExitOverlay />,
        [Overlay.saveDraft]:                  <SaveDraftOverlay />,
        [Overlay.editParticularities]:        <EditParticularitiesOverlay />,
        [Overlay.editParticularity]:          <EditParticularityOverlay />,
        [Overlay.deleteParticularity]:        <DeleteParticularityOverlay />,
        [Overlay.confirmPaidPlanTermination]: <ConfirmPaidPlanTerminationOverlay />,
        [Overlay.successfullyTermination]:    <SuccessfullyTerminationOverlay />,
        // eslint-enable react/react-in-jsx-scope
    };

    function renderOverlay(key) {
        const open                  = key === currentOverlay;
        const overlayMappingElement = overlayMapping[key];

        function onCloseClicked() {
            if (!overlayMappingElement.props.saveDraftOnExit) {
                dispatch(OverlayActions.closeOverlay());
                dispatch(AlertBoxActions.alertBoxLocationChange());
            } else {
                dispatch(OverlayActions.openOverlay({
                    overlay: Overlay.saveDraft,
                }));
            }
        }

        function onConfirmCloseClicked() {
            dispatch(OverlayActions.closeOverlay({
                count: 2,
            }));
        }

        function onKeyPress(event) {
            if (KeyboardEvent.isEscape(event)) {
                onCloseClicked();
            }
        }

        const clonedElement = React.cloneElement(
            overlayMappingElement,
            {
                key,
                open,
                onCloseClick:        onCloseClicked,
                onConfirmCloseClick: onConfirmCloseClicked,
            },
        );

        return (
            <div onKeyDown={onKeyPress}>
                {clonedElement}
            </div>
        );
    }

    return _.map(Object.keys(Overlay), renderOverlay);
};

export default OverlayManger;
