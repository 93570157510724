class UserAgentHelper {
    static userAgent = navigator.userAgent.toLowerCase();

    static isChrome() {
        return this.userAgent.indexOf('chrome') > -1;
    }

    static isAndroid() {
        return this.userAgent.indexOf('android') > -1;
    }
}

export default UserAgentHelper;
