//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import DefaultTypes     from '@components/DefaultTypes';
import PropTypes        from '@components/PropTypes';
import Icon             from '@stateless/atomic/Icon';
import IconType         from '@stateless/atomic/Icon/IconType';
import PagingButtonType from '@stateless/composed/PagingButton/PagingButtonType';

import styles from './styles.module.scss';

const propTypes = {
    disabled: PropTypes.bool,
    onClick:  PropTypes.func,
    title:    PropTypes.string,
    type:     PropTypes.oneOfObjectValues(PagingButtonType),
};

const PagingButton = ({
    disabled = false,
    onClick = DefaultTypes.func,
    title = null,
    type = PagingButtonType.forward,
}) => {
    function getBackgroundStyle() {
        switch (type) {
            case PagingButtonType.forward:
                return styles.backgroundForward;

            case PagingButtonType.backward:
                return styles.backgroundBackward;

            case PagingButtonType.quicksave:
                return styles.backgroundQuicksave;
        }

        return null;
    }

    function renderTitle(targetType) {
        if (
            !title ||
            targetType !== type
        ) {
            return null;
        }

        return (
            <div className={styles.pagingButtonTitle}>
                {title}
            </div>
        );
    }

    function renderArrows() {
        if (type !== PagingButtonType.quicksave) {
            return (
                <div className={styles.pagingButtonIcon}>
                    <Icon
                        iconType={IconType.doubleArrowRight}
                    />
                </div>
            );
        }

        return null;
    }

    const backgroundColorClass = getBackgroundStyle();

    return (
        <button
            className={classNames(
                styles.pagingButton,
                backgroundColorClass,
                {
                    [styles.pagingButtonWithText]: title,
                    [styles.pagingButtonDisabled]: disabled,
                },
            )}
            onClick={onClick}
            disabled={disabled}
        >
            {renderTitle(PagingButtonType.backward)}
            {renderTitle(PagingButtonType.forward)}
            {renderTitle(PagingButtonType.quicksave)}
            {renderArrows()}
        </button>
    );
};

PagingButton.propTypes = propTypes;

export default PagingButton;
