//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { connectRouter }      from 'connected-react-router';
import { combineReducers }    from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

import { AlertBoxReducer }              from '@slices/alertBox';
import { ConfigurationReducer }         from '@slices/configuration';
import { CreatePressArticleReducer }    from '@slices/createPressArticle';
import { EditPressArticleReducer }      from '@slices/editPressArticle';
import { ExternalUnitsReducer }         from '@slices/externalUnits';
import { LoadingReducer }               from '@slices/loading';
import { OverlayReducer }               from '@slices/overlay';
import { ParticularitiesReducer }       from '@slices/particularities';
import { PressArticleReducer }          from '@slices/pressArticle';
import { SmartUpdaterReducer }          from '@slices/smartUpdater';
import { StagingAuthenticationReducer } from '@slices/stagingAuthentication';
import { UnitsReducer }                 from '@slices/units';
import { UserReducer }                  from '@slices/user';
import { VehiclesReducer }              from '@slices/vehicles';
import { WindowReducer }                from '@slices/window';

export default (history) => withReduxStateSync(combineReducers({
    alertBox:              AlertBoxReducer,
    configuration:         ConfigurationReducer,
    createPressArticle:    CreatePressArticleReducer,
    editPressArticle:      EditPressArticleReducer,
    externalUnits:         ExternalUnitsReducer,
    loading:               LoadingReducer,
    overlay:               OverlayReducer,
    particularities:       ParticularitiesReducer,
    pressArticle:          PressArticleReducer,
    stagingAuthentication: StagingAuthenticationReducer,
    smartUpdater:          SmartUpdaterReducer,
    units:                 UnitsReducer,
    user:                  UserReducer,
    vehicles:              VehiclesReducer,
    window:                WindowReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
}));
